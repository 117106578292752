import React from "react";
import { useParams, useHistory } from "react-router";
import NewPassword from "../components/NewPassword";

import { SnackBar } from "../components/SnackBar";
import { errorMessageHandler } from "../helpers/messageHandler";
import mApi from "../services/api";

const NewPasswordContainer = () => {
  const [values, setValues] = React.useState({
    textMensaje: "",
    tipoAlert: "success",
    openSnack: false,
  });
  let { token } = useParams();
  let history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const closeSnack = () => {
    setValues({
      ...values,
      openSnack: false,
    });
  };
  const _submit = (password) => {
    setLoading(true);

    setLoading(true);
    const url = `/password_reset/${token}`;

    let data = JSON.stringify({
      password: password,
    });
    mApi({
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    })
      .then((respuesta) => {
        setLoading(false);

        var mensaje = respuesta;

        if (mensaje !== "") {
          setValues({
            ...values,
            textMensaje: mensaje.data.message,
            tipoAlert: mensaje.data.type,
            openSnack: true,
          });
        } else {
          errorMessageHandler(
            "Upss, ocurrio un error inesperado, intente luego."
          );
          setValues({
            ...values,
            textMensaje: "Upss, ocurrio un error inesperado, intente luego.",
            tipoAlert: "success",
            openSnack: true,
          });
        }
        setTimeout(() => {
          history.push("/");
        }, 5000);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        let message = errorMessageHandler(e);

        if (message.includes("403"))
          message =
            "Token no autorizado, reintente el proceso desde el principio.";
        setValues({
          ...values,
          textMensaje: message,
          tipoAlert: "error",

          openSnack: true,
        });

        setTimeout(() => {
          history.push("/");
        }, 5000);
      });
  };

  return (
    <>
      <SnackBar
        open={values.openSnack}
        textMensaje={values.textMensaje}
        tipoAlert={values.tipoAlert}
        close={closeSnack}
      />
      <NewPassword loading={loading} submitFunction={_submit} />
    </>
  );
};

export default NewPasswordContainer;
