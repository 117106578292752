import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router";
import CartaNewContainer from "./CartaNewContainer";
import { CssBaseline, Paper } from "@material-ui/core";
import UbicacionBar from "../components/UbicacionBar";
import { useStyles } from "../styles/classes";
import CartasAllContainer from "./CartasAllContainer";
const CartasContainer = () => {
  let classes = useStyles();
  return (
    <>
      <>
        <Switch>
          <Route exact path="/home/cartas">
            <CartasAllContainer />
          </Route>
          <Route path="/home/cartas/new">
            <CartaNewContainer />
          </Route>
          <Route
            exat
            path="/home/cartas/edit/:carta_id"
            component={CartaNewContainer}
          />
        </Switch>
      </>
    </>
  );
};

export default withRouter(CartasContainer);
