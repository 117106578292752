import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";

import MoreIcon from "@material-ui/icons/MoreVert";

import EditIcon from "@material-ui/icons/Edit";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { Menu, MenuItem } from "@material-ui/core";

import useStyles from "./styles";

import DisplayPrecio from "../DisplayPrecio";
import LazyLoadComponent from "../LazyLoadComponent";
import NoImage from "../NoImage";
import { useScreenSize } from "../../helpers/useScreenSize";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
  body: {
    maxHeight: "100px",
    fontSize: 14,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const RowTable = (props) => {
  const moneda = useSelector(
    (state) =>
      JSON.parse(state.user).negocios[0].moneda.split("(")[1].split(")")[0]
  );
  const classes = useStyles({});

  const [values, setValues] = React.useState({
    name: props.name,
    description: props.description,
    precio: props.precio,
    url_image: props.url_image,
    id: props.id,
    status: props.status,
    discount: props.discount,
  });

  React.useEffect(() => {
    setValues({
      ...values,
      name: props.name,
      description: props.description,
      precio: props.precio,
      url_image: props.url_image,
      id: props.id,
      discount: props.discount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setOpenMenu] = React.useState(false);
  const handleChangeStatus = (e) => {
    props.editarEstado({ ...values, id: values.id, estado: !values.status });
    setValues({ ...values, id: values.id, status: !values.status });
  };

  const _handleEdit = () => {
    props.editar(values);
    handleMenuClose();
  };
  const abrir_delete = () => {
    props.openAlert(values.id);
    handleMenuClose();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="menu-options"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      width="200px"
    >
      <MenuItem
        onClick={handleChangeStatus}
        title={values.status ? "Desactivar" : "Activar"}
        color="inherit"
      >
        <IconButton
          title={values.status ? "Desactivar" : "Activar"}
          color="inherit"
        >
          <input id={values.id} hidden></input>
          <Switch
            checked={values.status}
            onChange={handleChangeStatus}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </IconButton>
        {values.status ? "Activo" : "Inactivo"}
      </MenuItem>

      <MenuItem onClick={_handleEdit} title="Editar Carta">
        <EditIcon style={{ marginRight: 10 }} />
        Editar
      </MenuItem>
      {/* </Link> */}
      <MenuItem title="Eliminar Carta" onClick={abrir_delete}>
        <HighlightOffIcon style={{ marginRight: 10 }} />
        Eliminar
      </MenuItem>
    </Menu>
  );
  const { isDownBreakPoint } = useScreenSize();
  return (
    <>
      <StyledTableRow id={values.id} className={classes.row}>
        {!isDownBreakPoint && (
          <StyledTableCell align="center" onClick={_handleEdit}>
            {values.url_image ? (
              <LazyLoadComponent>
                <img
                  src={values.url_image}
                  alt={"Imagen de articulo " + values.name}
                  style={{ height: "50px", borderRadius: "5px" }}
                ></img>
              </LazyLoadComponent>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NoImage />
              </div>
            )}
          </StyledTableCell>
        )}
        <StyledTableCell align="center" onClick={_handleEdit}>
          {values.name}
        </StyledTableCell>
        {props.showTools && (
          <>
            <StyledTableCell
              onClick={_handleEdit}
              align="center"
              style={{ minWidth: "200px" }}
            >
              <DisplayPrecio {...values} moneda={moneda} />{" "}
            </StyledTableCell>
            <StyledTableCell
              onClick={_handleEdit}
              align="center"
              style={{ maxWidth: "300px" }}
            >
              {values.description}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell align="center">
          <div className={classes.tools_container}>
            <IconButton
              title={values.status ? "Desactivar" : "Activar"}
              color="inherit"
            >
              <input id={values.id} hidden></input>
              <Switch
                checked={values.status}
                onChange={handleChangeStatus}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </IconButton>

            <IconButton title="Editar Carta" onClick={_handleEdit}>
              <EditIcon />
            </IconButton>

            <IconButton title="Eliminar" onClick={abrir_delete}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <div className={classes.menu_icon}>
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={(e) => {
                setOpenMenu(true);
                setAnchorEl(e.currentTarget);
              }}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </StyledTableCell>
      </StyledTableRow>
      {renderMenu}
    </>
  );
};

export default RowTable;
