import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//browser router para usar varias rutas en la app
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./initializers/store";
import LogRocket from "logrocket";
import { disableReactDevTools, GlobalDebug } from "./utils";

if (process.env.REACT_APP_ENV === "production") {
  disableReactDevTools();
}
if (process.env.REACT_APP_ENV !== "local") {
  LogRocket.init("5a1tcc/smartcatalog");
}

(process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "STAGING") &&
  GlobalDebug(false);
ReactDOM.render(
  <BrowserRouter
    getUserConfirmation={(message, callback) => {
      const allowTransition = window.confirm(message);
      window.setTimeout(() => {
        callback(allowTransition);
      }, 1000);
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
