import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "60px",
    },
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.primary,
    height: "90%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  texbox: {
    backgroundColor: theme.palette.primary.texbox,
    width: "30%",
    minWidth: 150,
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 4,
    },
  },
  texboxL: {
    backgroundColor: theme.palette.primary.texbox,
    width: "100%",
  },
  containerDIv: {
    paddingBottom: 5,
    paddingTop: 5,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: 2,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  submit: {
    padding: theme.spacing(1, 2, 1),
    color: "white",
    margin: 5,
    //backgroundColor: theme.palette.primary.main
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  texboxDireccion: {
    width: "63.5%",
    minWidth: 150,
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  texboxMoneda: {
    width: "30%",
    minWidth: 150,
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textboxWhatsapp: {
    MozAppearance: "textfield",
  },
  button_previo: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    margin: 5,
    padding: theme.spacing(1, 2, 1),
    width: "100%",
  },
  buttons_container_top: {
    padding: 20,
    paddingBottom: 5,
    paddingTop: 5,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttons_container_bottom: {
    height: "60px",
    zIndex: "2",
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 10,
    backgroundColor: theme.palette.background.default,
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
}));
