import React from "react";

import PrintIcon from "@material-ui/icons/Print";

import DownloadIcon from "@material-ui/icons/Save";

import { ModalGenerico } from "../ModalGenerico";
import IconButton from "@material-ui/core/IconButton";
const ZoomQR = ({ open, close, qr, nameFile }) => {
  const _handleDownload = () => {
    const aelemnt = document.createElement("a");
    aelemnt.href = qr;
    aelemnt.download = nameFile;

    aelemnt.click();
  };
  const _handlePrint = () => {
    window.print();
  };

  return (
    <ModalGenerico openModal={open} title="Imprimir QR" closeModal={close}>
      <div
        id="imagen-qr"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img id="section-to-print" alt="QR" src={qr}></img>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {" "}
          <IconButton
            variant="filled"
            title="Descargar"
            onClick={_handleDownload}
          >
            <DownloadIcon />{" "}
          </IconButton>
          <IconButton color="primary" variant="filled" onClick={_handlePrint}>
            <PrintIcon></PrintIcon>
          </IconButton>
        </div>
      </div>
    </ModalGenerico>
  );
};

export default ZoomQR;
