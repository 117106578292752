import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import IngresarContainer from "./containers/IngresarContainer";
import RegistrarContainer from "./containers/RegistrarContainer";
import HomeAppContainer from "./containers/HomeAppContainer";
import ResendConfirm from "./containers/ResendConfirm";
import ResetPassword from "./containers/ResetPassword";
import NewPassword from "./containers/NewPasswordContainer";
const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={HomeAppContainer}>
          <Redirect from="/" to="/home" />
        </Route>
        <Route path="/login" component={IngresarContainer} />
        <Route path="/resend" component={ResendConfirm} />
        <Route path="/register" component={RegistrarContainer} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/new_password/:token" component={NewPassword} />
        <Route path="/home" component={HomeAppContainer} />
        <Route path="/home/negocio" component={HomeAppContainer} />
        <Route component={HomeAppContainer}>
          <Redirect from="/" to="/home" />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
