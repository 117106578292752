import React from "react";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

export const MensajeTypografico = ({
  texto,
  color = "grey",
  fontSize = "1.5rem",
}) => {
  const classes = useStyles();

  return (
    <div style={{ height: "100px",width: "100%"}}>
      <div className={classes.div_typografia}>
        <Typography
          variant="subtitle1"
          className={classes.span_tipografia}
          style={{ color: `${color}`, fontSize: `${fontSize}` }}
          gutterBottom
        >
          {texto}
        </Typography>
      </div>
    </div>
  );
};
