import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  containerDIv: {
    paddingBottom: 10,
    // [theme.breakpoints.down('md')]: {
    //     display: 'flex',
    // },
  },
  buttonClass: {
    padding: theme.spacing(1, 1, 1),
    color: "white",
    margin: 5,
    backgroundColor: "#35c0ed",
    //backgroundColor: theme.palette.primary.main
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    // height: '90%',
    margin: theme.spacing(1),
    marginBottom: "20px",
    paddingBottom: 10,
  },
  button_next: {
    marginLeft: 5,
  },
  button_previo: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    marginLeft: 5,
  },
  ul: {
    listStyle: "outside none none",
    minHeight: " 100px",
    background: " #e4f8ffb3",
    padding: "10px",
    maxHeight: "470px",
    scrollbarColor: "rgba(0, 0, 0, .5) rgba(0, 0, 0, 0)",
    scrollbarWidth: "thin",
    overflow: "hidden",
    overflowY: "scroll",
    WebkitOverflowScrolling: "auto",
  },
  CardHeader: {
    height: "60px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  div_loading: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "110%",
    width: "110%",
    left: "-5px",
    backgroundColor: "#252525e6",
    zIndex: 200,
    top: "5px",
  },
  lista_scroll: {
    overflow: "hidden",
    overflowY: "scroll",
    maxHeight: "60vh",
    scrollbarColor: "rgba(0, 0, 0, .5) rgba(0, 0, 0, 0)",
    scrollbarWidth: "thin",
    listStyle: "none",
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
  articulos_disponibles: {
    [theme.breakpoints.up("xs")]: {
      paddingRight: "50px",
    },
  },
  root: {
    paddingBottom: "100px",
    marginBottom: 30,
  },
  container_list: {
    paddingBottom: 65,
  },
}));
