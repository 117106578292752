import React, { useState, useEffect } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";

import Paper from "@material-ui/core/Paper";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import ListItemCarta from "./ListItemCarta";

import DialogAlert from "./DialogAlert";
import { MensajeTypografico } from "./MensajeTypografico";

import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useScreenSize } from "../helpers/useScreenSize";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {},
  containerDIv: {
    paddingBottom: 10,
    // [theme.breakpoints.down('md')]: {
    //     display: 'flex',
    // },
  },
  button: {
    padding: theme.spacing(1, 1, 1),
    // color: "white",
    margin: 5,
    // backgroundColor: ,
    //backgroundColor: theme.palette.primary.main
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,
    height: "90%",
    margin: theme.spacing(1),
    marginBottom: "15px",
  },
}));

function CartasAll(props) {
  const classes = useStyles();
  const [showTools, setShowTools] = React.useState(true);

  const [carta_id, setCartaID] = useState(null);
  const [open_alert, setOpen] = useState(false);
  const [Alignbutton, setAlign] = useState({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  });
  const aceptar_delete = () => {
    props.delete_carta(carta_id);

    setOpen(false);
  };
  const cerrar_modal = () => {
    setOpen(false);
    setCartaID(null);
  };
  const abrir_modal = (id) => {
    setOpen(true);
    setCartaID(id);
  };
  useEffect(() => {
    if (document.body.clientWidth < 800) setShowTools(false);
    else setShowTools(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.body.clientWidth]);
  useEffect(() => {
    if (props.Cartas.length > 0) {
      setAlign({
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
      });
    } else {
      setAlign({
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      });
    }
  }, [props.Cartas]);

  const { isDownBreakPoint } = useScreenSize({ breakPoint: 300 });
  return (
    <div className={classes.root}>
      <CssBaseline />

      <DialogAlert
        open={open_alert}
        aceptar={aceptar_delete}
        cerrar_modal={cerrar_modal}
        titulo="¡Cuidado!"
        texto="Tenga presente que esta accion es irreversible, ¿Desea eliminar permanentemente el catálogo?"
      />
      <Paper className={classes.paper} elevation={4} style={{ padding: 10 }}>
        <Grid
          container
          direction="column"
          justify="space-around"
          className={classes.containerDIv}
        >
          <div className={classes.containerDIv} style={Alignbutton}>
            <Link to="/home/cartas/new" className="list-item">
              <Button
                id="button_new_carta"
                type="button"
                variant="contained"
                onClick={props.clickNew}
                className={classes.button}
                color="primary"
              >
                <AddIcon />
                Nuevo catálogo
              </Button>
            </Link>
          </div>

          {props.Cartas.length > 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Nombre</StyledTableCell>
                    {!isDownBreakPoint && (
                      <StyledTableCell width="30%" align="center">
                        Descripcion
                      </StyledTableCell>
                    )}

                    {/* <StyledTableCell align="center">Actualizacion</StyledTableCell> */}
                    {showTools && (
                      <StyledTableCell align="center">
                        Articulos
                      </StyledTableCell>
                    )}
                    <StyledTableCell width="25%" align="center">
                      Tools
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.Cartas.map((row) => (
                    <ListItemCarta
                      showTools={showTools}
                      key={row.id}
                      editar={props.edit_carta}
                      edit_estado={props.edit_estado}
                      abrir_modal={abrir_modal}
                      nombre={row.name}
                      descripcion={row.descripcion}
                      creacion={row.fecha}
                      articulos={row.detallecarta.length}
                      estado={row.status}
                      id={row.id}
                      qr_url={row.qr_url}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {props.Cartas.length === 0 && (
            <MensajeTypografico
              texto={
                <span>
                  ¡Aqui puede crear las mejores cartas digitales <b>¡Vamos!</b>{" "}
                </span>
              }
            />
          )}
          {props.loading && (
            <div
              style={{
                padding: " 30px",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={40} />
            </div>
          )}
        </Grid>
      </Paper>
    </div>
  );
}

export default CartasAll;
