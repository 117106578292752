import React from "react";
import { useSelector, connect } from "react-redux";
import mApi from "../services/api";
import { useParams, withRouter, useHistory } from "react-router-dom";
import ModalCrearArticulo from "../components/ModalCrearArticulo";

import { clearToken, setUser, clearUser } from "../initializers/actions";
import { SnackBar } from "../components/SnackBar";

import { LoadingPage } from "../components/LoadingPage/LoadingPage";
import { errorMessageHandler } from "../helpers/messageHandler";

const ModalArticuloContainer = (props) => {
  const token = useSelector((store) => store.token);
  const user = useSelector((store) => store.user);
  const { articulo_id } = useParams();
  const serializedData = user;
  let negocios = JSON.parse(serializedData).negocios;
  let history = useHistory();
  let negocio_id = 0;
  if (typeof negocios[0].id != "undefined") {
    let Negocio = negocios[0];

    negocio_id = Negocio.id;
  }
  const [loadingArticulo, toogleLoadingArts] = React.useState(false);
  const [datosArticulo, setDataArticulo] = React.useState({
    id: props.articulo.id,
    name: props.articulo.name,
    description: props.articulo.description,
    precio: props.articulo.precio,
    url_image: props.articulo.url_image,
    status: props.articulo.status,
    discount: props.articulo.discount,
  });
  const [values, setValues] = React.useState({
    textMensaje: "Articulo Actualizado!",
    tipoAlert: "success",
    cargando: false,
    openSnack: false,
  });
  const _getDataArticulo = (articulo_id) => {
    toogleLoadingArts(true);
    const { user, token } = props;
    const negocios = JSON.parse(user)?.negocios;
    let method = "get";
    let url = `negocios/${negocios[0].id}/articulos/${articulo_id}`;
    setValues({ ...values, cargando: true });
    const config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "x-access-tokens": token,
        Accept: "*/*",
      },
    };
    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          const {
            data: { Articulo },
          } = response;

          setDataArticulo({ ...datosArticulo, ...Articulo });
        } else {
          history.push("/home/articulos/");
        }
        setValues({ ...values, cargando: false });
        toogleLoadingArts(false);
      })
      .catch((e) => {
        console.error(e);
        setValues({ ...values, cargando: false });
        toogleLoadingArts(false);
        errorMessageHandler(e);
        history.push("/home/articulos/");
      });
  };
  const handleClose = () => {
    setValues({
      ...values,

      openModal: false,
    });
    props.cancelarArticulo();
  };

  React.useEffect(() => {
    let render = true;
    if (articulo_id && !props.articulo.id && render) {
      _getDataArticulo(articulo_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const crearArticulo = (data) => {
    const { guardarArticulo } = props;

    setValues({ ...values, cargando: true });

    let data_form = new FormData(document.getElementById("form_art"));
    for (const attr in data) {
      data_form.append(attr, data[attr]);
    }
    data_form.append("estado", data.status);
    data_form.append("negocio_id", negocio_id);

    let method = "post";
    let url = "articulos";
    if (data.id && data.id !== null && data.id !== "") {
      method = "put";
      url = `negocios/${negocio_id}/articulos/${data.id}`;
    }

    var config = {
      method: method,
      url: url,
      withCredentials: false,

      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-tokens": token,
        Accept: "*/*",
      },
      data: data_form,
    };
    mApi(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          const art = response.data.Articulo;

          setValues({
            textMensaje: "¡Articulo Actualizado!",
            tipoAlert: "success",
            cargando: false,
            openSnack: true,
          });

          guardarArticulo({
            id: art.id,
            name: art.name,
            description: art.description,
            url_image: art.url_image,
            precio: art.precio,
            status: art.status,
            discount: art.discount,
          });
          setDataArticulo({ ...datosArticulo, ...art });
          handleClose();
        } else {
          setValues({
            ...values,

            textMensaje: `¡Error Actualizando!${response.data.Articulo}`,
            tipoAlert: "error",
            cargando: false,
            openSnack: true,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setValues({
          ...values,
          openSnack: true,
          textMensaje: errorMessageHandler(e),
          tipoAlert: "error",
          cargando: false,
        });
      });
  };
  const closeSnack = () => {
    setValues({
      ...values,
      openSnack: false,
    });
  };
  return (
    <>
      <SnackBar
        open={values.openSnack}
        textMensaje={values.textMensaje}
        tipoAlert={values.tipoAlert}
        close={closeSnack}
      />
      {loadingArticulo ? (
        <LoadingPage spineSize="50px" />
      ) : (
        <div style={{ position: "relative" }}>
          <ModalCrearArticulo
            openModal={props.open}
            onClose={handleClose}
            guardarArticulo={crearArticulo}
            cancelarArticulo={handleClose}
            articulo={datosArticulo}
            cargando={values.cargando}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalArticuloContainer)
);
