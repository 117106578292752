import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Container from "@material-ui/core/Container";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Alert } from "@material-ui/lab";

import { ThemeProvider } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { theme_menu } from "../../styles/classes";
import { useStyles } from "./styles";
import {} from "../../styles/login.css";

import mApi from "../../services/api";

import Copyright from "../Copyright.js";
import PasswordInput from "./PasswordInput";
import { errorMessageHandler } from "../../helpers/messageHandler";

function Login(props) {
  const classes = useStyles();
  const [showResendLink, setShowResendConfirmed] = React.useState(false);
  useEffect(() => {
    if (props.confirmed) {
      setValues({
        ...values,
        tipoMensaje: "success",
        mensajeAlert: "Email confirmado con Exito...",
      });

      var alert_env = document.getElementById("alert_envio");
      alert_env.style.display = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.confirmed]);

  const [values, setValues] = React.useState({
    password: "",
    email: "",
    showPassword: false,
    mensaje: "",
    disabled: false,
    apertura: false,
    mensajeAlert: "",
    tipoMensaje: "success",
    checked_theme: false,
  });

  const handleChange = (prop) => (event) => {
    let value = event.target.value;
    if (prop === "email") {
      value = event.target.value?.toLowerCase();
    }
    setValues({
      ...values,
      [prop]: value,
    });
  };

  const handleSubmit = () => {
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    var loading = document.getElementById("loading");
    var alert_env = document.getElementById("alert_envio");

    alert_env.style.display = "none";
    loading.style.display = "block";
    setValues({ ...values, disabled: true });
    var url = "login";
    mApi({
      method: "post",
      url: url,
      withCredentials: false,
      auth: {
        username: email,
        password: password,
      },
    })
      .then((respuesta) => {
        loading.style.display = "none";
        setValues({ ...values, disabled: false });
        var mensaje = respuesta;

        if (mensaje !== "") {
          if (mensaje.data.status) {
            setValues({
              ...values,
              tipoMensaje: "success",
              mensajeAlert: "¡Login Exitoso..Bienveni@!",
            });

            props.getToken(mensaje.data.token);
            alert_env.style.display = "";
          } else {
            let message = mensaje.data.message;

            if (!mensaje.data.confirmed) {
              setShowResendConfirmed(true);
            }
            setValues({
              ...values,
              tipoMensaje: "error",
              mensajeAlert: message,
            });
            alert_env.style.display = "";
          }
        } else {
          setValues({
            ...values,
            tipoMensaje: "error",
            mensajeAlert: "¡Upss! Ha ocudrrido un error.",
          });
          alert_env.style.display = "";
        }
      })
      .catch((e) => {
        console.error("error catch:" + e);
        loading.style.display = "none";
        setValues({ ...values, disabled: false }); //habilitar el boton

        setValues({
          ...values,
          tipoMensaje: "error",
          mensajeAlert: errorMessageHandler(e),
        });
        alert_env.style.display = "";
      });
  };

  return (
    <div style={{ height: "100vh", margin: "0 !important", padding: "0" }}>
      <ThemeProvider theme={theme_menu}>
        <CssBaseline />
        <LinearProgress
          id="loading"
          color="secondary"
          style={{ display: "none" }}
        />
        <Grid container spacing={0}>
          <Grid
            container
            className="divInfoApp_2"
            item
            xs={12}
            sm={12}
            md={6}
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Typography
              variant="h3"
              className="tituloCursivo_login"
              gutterBottom
            >
              Ingrese a{" "}
              <b
                style={{
                  fontFamily: "Audiowide",
                  padding: "0 10px",
                  paddingTop: "10px",
                  fontSize: "20px",
                  color: "#2D9CDB",
                }}
              >
                {" "}
                CATALOGY
              </b>{" "}
              y gestione todos sus productos.
            </Typography>
            <Typography
              variant="h5"
              className="subtituloCursivo_login"
              component="h5"
              style={{ color: "#686868" }}
              gutterBottom
            >
              cree, edite y elimine catálogos ilimitadamente.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
              component="main"
              maxWidth="xs"
            >
              <Alert
                severity={values.tipoMensaje}
                id="alert_envio"
                style={{ display: "none" }}
                variant="filled"
              >
                {values.mensajeAlert}
              </Alert>
              <div variant="h2" className={classes.logo}>
                <img
                  src="/logo_completo_white.png"
                  alt="logo Catalogy app de catálogos inteligentes"
                  style={{ height: "150px" }}
                ></img>
              </div>
              <div className={classes.paper_log}>
                <div className={classes.form} noValidate>
                  <ValidatorForm
                    onSubmit={handleSubmit}
                    onError={(errors) => console.log(errors)}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          fullWidth
                          id="email"
                          label="Correo Electronico"
                          name="email"
                          autoComplete="email"
                          onChange={handleChange("email")}
                          value={values.email}
                          validators={["required", "isEmail"]}
                          onError={(e) => {
                            console.error(e);
                          }}
                          errorMessages={[
                            "Este campo es requerido",
                            "Correo no es valido",
                          ]}
                        />
                        {showResendLink && (
                          <Link
                            href="/resend"
                            variant="subtitle1"
                            style={{ color: "red" }}
                          >
                            {"Renviar correo de confirmacion!"}
                          </Link>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Grid item>
                          <PasswordInput
                            fullWidth={true}
                            variant="outlined"
                            name="password"
                            id="password"
                            autoComplete="current-password"
                            value={values.password}
                            onChange={handleChange("password")}
                            validators={["required"]}
                            errorMessages={["Este campo es requerido"]}
                          />
                          <Link href="/reset_password" variant="subtitle1">
                            {"¿Olvidaste tu contraseña?"}
                          </Link>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          id="button_submit"
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={values.disabled}
                        >
                          Entrar
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container justify="flex-end">
                      <Grid item>
                        <Link href="/register" variant="body1">
                          {"Aun no tengo cuenta, quiero la mia!"}
                        </Link>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </div>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Container>
          </Grid>
          <Grid
            container
            className="divInfoApp_1 "
            item
            xs={12}
            sm={12}
            md={6}
            justify="center"
            alignItems="center"
            direction="column"
            style={{}}
          >
            <Typography variant="h3" className="tituloCursivo" gutterBottom>
              Con
              <b
                style={{
                  fontFamily: "Audiowide",
                  padding: "0 10px",
                  paddingTop: "10px",
                  color: "#2D9CDB",
                }}
              >
                {" "}
                CATALOGY
              </b>{" "}
              sus catálogos son mas inteligentes.
            </Typography>
            <Typography
              variant="h5"
              className="subtituloCursivo"
              component="h5"
              style={{ color: "#686868" }}
              gutterBottom
            >
              cree, edite y elimine catálogos ilimitadamente.
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Login;
