import React, { useState } from "react";
import { useSelector, connect } from "react-redux";

import mApi from "../services/api";
import { useParams, withRouter, useHistory } from "react-router-dom";
import { clearToken, setUser, clearUser } from "../initializers/actions";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@material-ui/core";
import CategoriasForm from "../components/CategoriasForm";
import { SnackBar } from "../components/SnackBar";
import { errorMessageHandler } from "../helpers/messageHandler";
import { useScreenSize } from "../helpers/useScreenSize";

const ModalCategoriaContainer = (props) => {
  const { isDownBreakPoint } = useScreenSize({ breakPoint: 600 });
  const [loading, setLoading] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    message: "",
    type: "",
  });
  const _guardarCategoria = (values) => {
    setLoading(true);

    let id = values.id;
    const serializedData = props.user;
    let negocios = JSON.parse(serializedData).negocios;

    let negocio_id = null;
    if (typeof negocios[0].id != "undefined") {
      let Negocio = negocios[0];

      negocio_id = Negocio.id;
    }
    let data_form = new FormData(document.getElementById("form_cat"));
    for (const attr in values) {
      data_form.append(attr, values[attr]);
    }
    data_form.append("estado", values.status);
    data_form.append("negocio_id", negocio_id);
    let method = "post";
    let url = `negocios/${negocio_id}/categorias`;
    if (values.id && values.id !== null && values.id !== "") {
      url = `negocios/${negocio_id}/categorias/${id}`;
      method = "put";
    }

    let config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": props.token,
        Accept: "*/*",
      },
      data: data_form,
    };

    mApi(config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let mensaje = "Categoria editada satisfactoriamente!";
          setSnackBar({
            open: true,
            type: "success",
            message: mensaje,
          });
          setLoading(false);
          props.submitCat(response.data.Categoria);
        } else {
          setSnackBar({
            open: true,
            type: "error",
            message:
              response.data?.message || "Error editando, intente mas tarde.",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setSnackBar({
          open: true,
          type: "error",
          message: errorMessageHandler(e),
        });
      });
  };

  const closeSnack = () => {
    setSnackBar({
      openSnack: false,
    });
  };
  return (
    <>
      <Dialog
        disableBackdropClick
        open={props.openModal}
        onClose={props.onClose}
        fullScreen={isDownBreakPoint}
      >
        <DialogTitle>Categoria</DialogTitle>
        <DialogContent>
          {" "}
          <CategoriasForm
            {...props.categoria}
            {...props}
            loading={loading}
            submit={_guardarCategoria}
          />{" "}
        </DialogContent>
      </Dialog>
      <SnackBar
        open={snackBar.open}
        textMensaje={snackBar.message}
        tipoAlert={snackBar.type}
        close={closeSnack}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalCategoriaContainer)
);
