import baseEnv from "./env.base";

const BASE_URL = `https://api.catalogyapp.com`;

const productionEnv = {
  ...baseEnv,
  production: true,
  APIURL: BASE_URL,
  HOME_URL: "catalogyapp.com",
  SENTRY_ENV: "production",
};

export default productionEnv;
