import baseEnv from "./env.base.js";
const { REACT_APP_API_NAME: apiName = "" } = process.env;
const {
  REACT_APP_LOCAL_BACKEND_API_PORT: apiPort = 5000,
  REACT_APP_USE_LOCAL_BACKEND: useLocalBackend = false,
} = process.env;

const BASE_URL = !useLocalBackend
  ? `https://api.catalogyapp.com/`
  : `http://localhost:${apiPort}/`;
const localEnv = {
  ...baseEnv,
  APIURL: apiName === "" ? BASE_URL : apiName,
};

export default localEnv;
