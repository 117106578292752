import React from "react";
import {
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  LinearProgress,
  ThemeProvider,
  Typography,
  Container,
  OutlinedInput,
  FormControl,
  InputAdornment,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import PasswordStrengthBar from "react-password-strength-bar";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { useStyles } from "./styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { theme_menu } from "../../styles/classes";
import {} from "../../styles/login.css";
import Copyright from "../Copyright";

const NewPassword = (props) => {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    password: "",
    confirm_pass: "",
    showPassword: false,
  });
  const { loading } = props;
  const _handleSubmit = () => {
    const { submitFunction } = props;

    setValues({ ...values, disabled: true });
    setValues({ password: "", confirm_pass: "", showPassword: "" });
    submitFunction(values.password);
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };
  //crear validacion personalizada para comparar los password. se usa ValidatorForm
  ValidatorForm.addValidationRule("isPassMatch", (value) => {
    if (value !== values.password) {
      return false;
    }
    return true;
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div style={{ height: "100vh", margin: "0 !important", padding: "0" }}>
        <ThemeProvider theme={theme_menu}>
          <CssBaseline />
          {loading && (
            <LinearProgress width="100%" id="loading" color="secondary" />
          )}
          <Grid container style={{ height: "100%" }}>
            <Grid
              container
              className={classes.bannerTop}
              item
              xs={12}
              sm={12}
              md={6}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Typography variant="h3" className="tituloCursivo" gutterBottom>
                Con{" "}
                <b
                  style={{
                    fontFamily: "Audiowide",
                    padding: "0 10px",
                    paddingTop: "10px",
                    fontSize: "20px",
                    color: "#2D9CDB",
                  }}
                >
                  {" "}
                  CATALOGY
                </b>{" "}
                siempre sus catálogos actualizados!
              </Typography>
              <Typography
                variant="h5"
                className="subtituloCursivo"
                component="h5"
                style={{ color: "#686868" }}
                gutterBottom
              >
                Sus clientes siempre veran la disponibilidad en tiempo real!
              </Typography>
            </Grid>

            <Grid item md={6} className={classes.reset_password_container}>
              <Container component="main" maxWidth="sm">
                <Grid
                  container
                  justify="center"
                  align="center"
                  className={classes.logoImage_container}
                >
                  <a variant="h4" href="/" className="tituloCursivo">
                    <img
                      src="/logo_completo_white.png"
                      alt="no"
                      style={{ height: "10rem" }}
                    ></img>
                  </a>
                </Grid>
                <Grid
                  container
                  justify="center"
                  align="center"
                  style={{ marginBottom: "20px" }}
                >
                  <Typography className={classes.typoTitle} gutterBottom>
                    Ingrese su nueva contraseña
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="center"
                  align="center"
                  style={{ marginBottom: "40px" }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitleMessage}
                  >
                    La dificultad de la contraseña es solo su responsabilidad.
                  </Typography>
                </Grid>
                <Grid>
                  <ValidatorForm
                    onSubmit={_handleSubmit}
                    onError={(errors) =>
                      console.error(errors[0].getErrorMessage())
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth={true}>
                          <InputLabel
                            style={{
                              backgroundColor: "white",
                              padding: "0px 5px",
                            }}
                            htmlFor="password"
                          >
                            Contraseña
                          </InputLabel>
                          <OutlinedInput
                            fullWidth={true}
                            variant="outlined"
                            required
                            name="password"
                            id="password"
                            autoComplete="current-password"
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <PasswordStrengthBar
                          scoreWords={[
                            "Muy debil",
                            "Debil",
                            "Insegura",
                            "Normal",
                            "Segura",
                          ]}
                          shortScoreWord={values.password && "muy corta"}
                          password={values.password}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          type={values.showPassword ? "text" : "password"}
                          required
                          fullWidth
                          id="pass_confirm"
                          label="Confirmar Contraseña"
                          name="pass_confirm"
                          onChange={handleChange("confirm_pass")}
                          validators={["isPassMatch", "required"]}
                          errorMessages={[
                            "Contraseñas no coinciden",
                            "Campo requerido",
                          ]}
                          value={values.confirm_pass}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          id="button_submit"
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress size={25} />
                          ) : (
                            "Cambiar contraseña"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </Grid>
                <Grid style={{ paddingTop: 40 }}>
                  <Copyright />
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    </>
  );
};

export default NewPassword;
