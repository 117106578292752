import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { clearToken, setUser, clearUser } from "../initializers/actions";
import { connect } from "react-redux";
import CartasAll from "../components/CartasAll";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import mApi from "../services/api";
import Ubicacionbar from "../components/UbicacionBar";
import { errorMessageHandler } from "../helpers/messageHandler";
class CartasAllContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      cartas: [],
      contentRender: "",
      new: props.NewCarta,
      cargando: true,
    };
  }

  _editar = (values) => {
    let id = values.id;
    this.props.history.push("/home/cartas/edit/" + id, { carta_id: id });
  };
  _editar_estado = (values) => {
    let id = values.id;

    const serializedData = this.props.user;
    let negocios = JSON.parse(serializedData).negocios;

    let negocio_id = 0;
    if (typeof negocios[0].id != "undefined") {
      let Negocio = negocios[0];

      negocio_id = Negocio.id;
    }

    let objData = {
      estado: values.estado,
    };

    this.setState({ cargando: true });

    let method = "put";

    let url = `negocios/${negocio_id}/cartas/${id}/estado`;

    let config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": this.props.token,
        Accept: "*/*",
      },
      data: objData,
    };

    mApi(config)
      .then((response) => {
        if (response.status === 201) {
          // alert("Carta Editada Exitosamente.");

          let mensaje = "¡Carta Editada Satisfactoriamente!";
          this.setState({
            alertOpen: true,
            tipoAlert: "success",
            textMensaje: mensaje,
          });
          this.setState({ cargando: false });
        } else {
          this.setState({
            alertOpen: true,
            tipoAlert: "error",
            textMensaje: "¡Error Editando.Favor intente de nuevo mas tarde!",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          alertOpen: true,
          tipoAlert: "error",
          textMensaje: errorMessageHandler(e),
        });
        this.setState({ cargando: false });
      });
  };
  _eliminar = (id) => {
    const serializedData = this.props.user;
    let negocios = JSON.parse(serializedData).negocios;

    let negocio_id = 0;
    if (typeof negocios[0].id != "undefined") {
      let Negocio = negocios[0];

      negocio_id = Negocio.id;
    }

    this.setState({ cargando: true });

    let method = "put";

    method = "delete";
    let url = `negocios/${negocio_id}/cartas/${id}`;

    let config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": this.props.token,
        Accept: "*/*",
      },
    };

    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          let mensaje = "¡Carta Eliminada Satisfactoriamente!";
          this.setState({
            alertOpen: true,
            tipoAlert: "success",
            textMensaje: mensaje,
          });
          this.setState({ cargando: false });
          this.cargar_cartas();
        } else {
          this.setState({
            alertOpen: true,
            tipoAlert: "error",
            textMensaje: "¡Error Eliminando.Favor intente de nuevo mas tarde!",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          alertOpen: true,
          tipoAlert: "error",
          textMensaje: errorMessageHandler(e),
        });
        this.setState({ cargando: false });
      });
  };
  _clickNew = () => {
    this.setState({ new: true });
  };
  _clickBack = () => {
    this.setState({ new: false });
  };
  componentDidMount() {
    const { user, token } = this.props;

    if (!token || !user || token === null || user === "") {
      this.props.history.push("/login");
      return;
    }

    const negocios = JSON.parse(user)?.negocios || [];

    if (!negocios.length) {
      this.props.history.push("/home");
      return;
    }

    this.cargar_cartas();
  }
  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  cargar_cartas = () => {
    const serializedData = this.props.user;
    let negocios = JSON.parse(serializedData).negocios;

    this.setState({ contentRender: this.renderListaCartas });

    if (typeof negocios[0].id != "undefined") {
      this.setState({ cargando: true });

      let url = `negocios/${negocios[0].id}/cartas`;

      mApi({
        method: "get",
        url: url,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": this.props.token,
        },
      })
        .then((response) => {
          let mensaje = response;

          if (mensaje !== "") {
            let listaCartas = mensaje.data.Cartas;
            this.setState({ cartas: listaCartas });
          } else {
          }
          this.setState({ cargando: false });
        })
        .catch((e) => {
          this.setState({ cargando: false });
          console.error(e);
          errorMessageHandler(e);
        });
    }
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.alertOpen = false;
    this.setState({ alertOpen: false });
  };

  render() {
    const { cargando } = this.state;
    return (
      <>
        {cargando ? <LinearProgress id="loading" color="secondary" /> : ""}

        <Ubicacionbar modulo="Catálogos" />
        <CartasAll
          Cartas={this.state.cartas}
          clickNew={this._clickNew}
          edit_carta={this._editar}
          edit_estado={this._editar_estado}
          delete_carta={this._eliminar}
          loading={cargando}
        />
        <Snackbar
          open={this.state.alertOpen}
          autoHideDuration={4000}
          onClose={this.handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.tipoAlert}
          >
            {this.state.textMensaje}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartasAllContainer)
);
