import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    color: "white",
    fontWeight: "bold",
    mixBlendMode: "normal;",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
  },
  pos: {
    marginBottom: 12,
    fontSize: 13,
    color: "white",
    fontWeight: "bold",
  },

  cards: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "30px",
    minWidth: "280px ",
    maxWidth: "350px",
    height: "130px",
    margin: "5px",
    marginLeft: "30px",

    contain: "object-fit",
    // -webkit-box-shadow: '8px 9px 8px 1px rgba(115,115,115,0.51)' ,
    // -moz-box-shadow: '8px 9px 8px 1px rgba(115,115,115,0.51)' ,
    //   boxShadow: "3px 8px 5px 1px rgba(24, 15, 15, 0.3) ",
    color: "white",
  },
  img: {
    zoom: 1,
    filter: "alpha(opacity=50)",
    opacity: 1,
    borderRadius: "50%",
    height: 30,
    width: 30,
  },
  texto_largo: {
    whiteSpace: " nowrap;",

    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
    display: "flex",
    flexDirection: "column",
    padding: " 10px 20px",
  },
  noWrap: {
    width: "95%",
    textOverflow: "ellipsis !important",
    overflow: "hidden;",
    mixBlendMode: "normal;",
  },
  button: {
    position: "absolute",
    right: "40px",
    top: "12px",
  },
  picture_articulo: {
    width: "50px;",
    height: "50px;",
    backgroundSize: "cover;",
    backgroundRepeat: "no-repeat;",
    backgroundPosition: "50% 50%;",
    overflow: "hidden;",
    borderRadius: "7px;",
  },
}));
