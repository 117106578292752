const {
  REACT_APP_API_NAME: apiName = "",
  REACT_APP_SENTRY_DSN:
    sentryDSN = "https://1a73c5c05b4d47a793b5084581f75ca5@o1142962.ingest.sentry.io/6201958",
} = process.env;
const baseEnv = {
  VERSION: process.env.REACT_APP_VERSION,
  __API_NAME__: apiName,
  production: false,
  SENTRY_DSN: sentryDSN,
};

export default baseEnv;
