import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { ValidatorForm } from "react-material-ui-form-validator";
import { Checkbox, CircularProgress, Typography } from "@material-ui/core";
import LogoCard from "./LogoCard";

import SaveIcon from "@material-ui/icons/Save";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useScreenSize } from "../helpers/useScreenSize";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",

    "& > *": {
      width: "100%",
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.primary,
    height: "90%",
  },
  texbox: {
    backgroundColor: theme.palette.primary.texbox,
    width: "30%",
    minWidth: 150,
    margin: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  texboxL: {
    backgroundColor: theme.palette.primary.texbox,
    width: "100%",

    color: "grey !important",
  },
  texboxDiscount: {
    width: "100%",
  },
  containerDIv: {
    paddingBottom: 5,
    paddingInline: "10px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  containerDiscount: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  precioContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",

    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  submit: {
    padding: theme.spacing(2, 2, 2),
    color: "white",
    margin: 5,
    maxWidth: 260,
    //backgroundColor: theme.palette.primary.main
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  tituloDialog: {
    background: theme.palette.primary.main,
    color: "white",
    height: 40,
    padding: "5px 0",
    display: "flex",
    paddingInlineStart: "20px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));

export default function ModalCrearArticulo(props) {
  function Cancelar() {
    props.cancelarArticulo();
  }
  const defaultValueDiscount = "0";
  const classes = useStyles();
  const [values, setValues] = React.useState({
    id: props.articulo.id || null,
    name: props.articulo.name || "",
    description: props.articulo.description || "",
    precio: props.articulo.precio || "",
    url_image: props.articulo.url_image,
    openModal: props.openModal,
    status: props.articulo.status ?? true,
    discount: props.articulo.discount || "",
  });
  const [precioFinal, setPrecioFinal] = React.useState(
    props.articulo.precio || 0
  );
  const [conDescuento, setDiscount] = React.useState(false);
  const { isDownBreakPoint } = useScreenSize({ breakPoint: 400 });

  useEffect(() => {
    setValues({
      ...values,
      id: props.articulo.id,
      name: props.articulo.name,
      description: props.articulo.description,
      precio: props.articulo.precio || "",
      url_image: props.articulo.url_image,
      status: props.articulo.status ? true : false,
      discount: props.articulo.discount || defaultValueDiscount,
    });

    if (
      props.articulo.discount &&
      props.articulo.discount !== "" &&
      props.articulo.discount !== defaultValueDiscount
    ) {
      setDiscount(true);
      changeProcentaje({ target: { value: props.articulo.discount } });
    } else {
      setDiscount(false);
    }
  }, [props.openModal, props.articulo]);

  useEffect(() => {
    if (props.discount === "0") {
      setValues({ ...values, discount: defaultValueDiscount });
      setPrecioFinal(0);
    }
  }, [values.precio]);

  const onChangeIma = (e) => {
    setValues({
      ...values,
      url_image: e.target.files[0],
    });
  };
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };
  const handleSubmit = () => {
    props.guardarArticulo(values);
  };
  const handleChangeSwitch = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };
  const changeProcentaje = (event) => {
    let precioFinal =
      values.precio - (values.precio * event.target.value) / 100;
    setValues({ ...values, discount: event.target.value });
    setPrecioFinal(precioFinal);
  };
  const changeDiscount = () => {
    if (conDescuento) {
      setValues({ ...values, discount: defaultValueDiscount });
      setPrecioFinal(0);
    }
    setDiscount(!conDescuento);
  };

  const handleNotFocusText = (prop) => (event) => {
    let value = event.target.value.trim();

    setValues({
      ...values,
      [prop]: value,
    });
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        open={props.openModal}
        onClose={props.onClose}
        aria-labelledby="form-dialog-title"
        style={{ padding: "0px", margin: "0px" }}
        fullScreen={isDownBreakPoint}
      >
        <DialogTitle className={classes.tituloDialog} id="form-dialog-title">
          Articulo
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            style={{ display: "flex", justifyContent: "center" }}
          >
            Puede editar/crear articulos y luego asignarlos a la categoria que
            prefiera.
          </DialogContentText>

          <ValidatorForm
            onSubmit={handleSubmit}
            id="form_art"
            className={classes.root}
          >
            <Grid
              item
              className={classes.containerDIv}
              style={{ marginTop: "15px" }}
              md={12}
            >
              <LogoCard
                required
                frase="Seleccione la Imagen."
                boton="Subir"
                onChangeIma={onChangeIma}
                image={values.url_image}
                nombreImagen={values.url_image}
              />
            </Grid>

            <Grid item xs={6} md={6} sm={6} style={{ padding: 5 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.status}
                    onChange={handleChangeSwitch}
                    name="status"
                    color="primary"
                  />
                }
                label={values.status ? "Activo" : "Inactivo"}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sm={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControlLabel
                style={{ margin: 2, marginRight: 7 }}
                control={
                  <Checkbox
                    checked={conDescuento}
                    onChange={changeDiscount}
                    name="conDescuento"
                    color="primary"
                    size="small"
                  />
                }
                label={<span style={{ fontSize: 14 }}>Descuento</span>}
              />
            </Grid>
            <Grid container align="center" style={{ paddingRight: "5px" }}>
              <Grid item xs={12} md={6} sm={6} style={{ padding: "5px" }}>
                <TextField
                  required
                  className={classes.texboxL}
                  id="articulo_nombre"
                  placeholder="Hamburguesa Doble"
                  label="Nombre"
                  variant="outlined"
                  color="primary"
                  value={values.name}
                  onChange={handleChange("name")}
                  name="nombre"
                  title="Nombre Articulo"
                  focused
                  onBlur={handleNotFocusText("name")}
                />
              </Grid>
              <Grid
                style={{ padding: "5px" }}
                item
                xs={12}
                md={conDescuento ? 4 : 6}
                sm={conDescuento ? 4 : 6}
                lg={conDescuento ? 4 : 6}
                className={classes.precioContainer}
              >
                <TextField
                  required
                  type="number"
                  className={classes.texboxL}
                  id="articulo_precio"
                  label="Precio base"
                  variant="outlined"
                  color="primary"
                  value={values.precio}
                  onChange={handleChange("precio")}
                  name="precio"
                  inputProps={{ min: "1", pattern: "^[0-9]+", step: 0.01 }}
                  placeholder="7000"
                ></TextField>
              </Grid>

              {conDescuento && (
                <Grid
                  style={{ padding: "5px", paddingTop: "5px" }}
                  item
                  sm={2}
                  md={2}
                  xs={12}
                  className={classes.containerDiscount}
                >
                  <TextField
                    className={classes.texboxDiscount}
                    id="articulo_discount"
                    label=" - %"
                    variant="outlined"
                    color="primary"
                    value={values.discount}
                    onChange={changeProcentaje}
                    name="discount"
                    type="number"
                    inputProps={{
                      min: "1",
                      pattern: "^[1-9]d*$",
                      max: "100",
                      step: "any",
                    }}
                  ></TextField>
                </Grid>
              )}
            </Grid>
            {conDescuento && (
              <Grid container>
                <Grid item xs={7} sm={7} md={6}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "grey" }}
                      id="discountMount"
                    >
                      Monto a descontar:
                    </Typography>
                  </div>
                  <div
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ color: "grey" }}
                      id="discountMount"
                    >
                      Precio final:
                    </Typography>
                  </div>{" "}
                </Grid>
                <Grid item xs={5} sm={5} md={6}>
                  <Grid container align="center" justify="center">
                    <Typography
                      variant="overline"
                      style={{ color: "red", fontWeight: "300px" }}
                      id="discountMount"
                    >
                      -{" "}
                      {values.precio - precioFinal > 0 &&
                        precioFinal > 0 &&
                        (values.precio - precioFinal).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    align="center"
                    justify="center"
                    style={{ padding: "0px 12px", flexDirection: "column" }}
                  >
                    <hr style={{ color: "grey", padding: 0, margin: 2 }}></hr>
                    <Typography
                      variant="subtitle1"
                      style={{ padding: 0, margin: 0, fontWeight: "bold" }}
                      id="discountMount"
                    >
                      {precioFinal > 0 &&
                        precioFinal !== "" &&
                        precioFinal !== "0" &&
                        precioFinal.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              sm={12}
              md={12}
              className={classes.containerDIv}
              style={{ padding: "5px", paddingRight: "10px" }}
            >
              <TextField
                required
                multiline
                rows={4}
                className={clsx(classes.texboxL)}
                id="articulo_descripcion"
                label="Descripcion"
                variant="outlined"
                color="primary"
                value={values.description}
                onChange={handleChange("description")}
                name="description"
                onBlur={handleNotFocusText("description")}
              ></TextField>
            </Grid>

            <Grid className={classes.containerButtons}>
              <Grid
                className={classes.containerDIv}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {!props.cargando && (
                  <Button
                    id="button_cancelar"
                    // type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={Cancelar}
                  >
                    Cancelar
                  </Button>
                )}
                <Button
                  id="button_submit"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  disabled={props.cargando}
                >
                  {props.cargando ? (
                    <CircularProgress color="secondary" size={"1.5rem"} />
                  ) : (
                    <SaveIcon style={{ marginRigth: "10px" }} />
                  )}
                  {!props.cargando ? (
                    "Guardar"
                  ) : (
                    <span style={{ paddingLeft: "10px" }}>
                      Creando articulo...
                    </span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
    </>
  );
}
