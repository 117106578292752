import { Grid } from "@material-ui/core";
import React from "react";

const DisplayPrecio = ({ precio, discount, moneda }) =>
  discount !== "0" ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {" "}
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Grid
          item
          style={{
            textDecoration: "line-through",
            color: "darkgrey",
            padding: "0 0px",
          }}
        >
          {`${moneda} ${precio.toFixed(2)}`}
        </Grid>
        <Grid
          item
          style={{
            fontWeight: "bold",
            padding: "0 5px",
          }}
        >
          {`${moneda} ${(precio - (precio * discount) / 100).toFixed(2)}`}
        </Grid>

        <Grid
          item
          style={{
            height: "17px",
            paddingInline: "3px",
            color: "white",
            backgroundColor: "red",
            borderRadius: "3px",
            fontSize: "11px",
            fontWeight: "bold",
            paddingTop: 1.3,
            justifySelf: "flex-end",
          }}
        >
          {" "}
          - {discount} %
        </Grid>
      </Grid>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {moneda}
      {precio.toFixed(2)}
    </div>
  );

export default DisplayPrecio;
