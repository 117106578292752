import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import DashboardIcon from "@material-ui/icons/Dashboard";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  paperDirectionBar: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,

    margin: theme.spacing(1),
    marginBottom: "15px",
  },
}));

const Ubicacionbar = (props) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.paperDirectionBar}
      style={{ padding: 10 }}
      elevation={3}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/home" className={classes.link}>
          <DashboardIcon className={classes.icon} />
          Tablero
        </Link>
        <Link
          color="inherit"
          href={`/home/${props.modulo.toLowerCase()}`}
          className={classes.link}
        >
          {/* <WhatshotIcon className={classes.icon} /> */}
          {props.modulo}
        </Link>
        {/* <Typography color="textPrimary" className={classes.link}>
        <GrainIcon className={classes.icon} />
        Breadcrumb
      </Typography> */}
      </Breadcrumbs>
    </Paper>
  );
};

export default Ubicacionbar;
