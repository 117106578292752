import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export const SnackBar = (props) => {
  let { textMensaje = "", open, tipoAlert = "success", close } = props;

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={10000}
        onClose={() => {
          close();
        }}
      >
        <MuiAlert
          elevation={4}
          variant="filled"
          onClose={() => {
            close();
          }}
          severity={tipoAlert}
        >
          {textMensaje}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
