import * as Sentry from "@sentry/react";

export const INFO_LEVEL = "info";
export const WARNING_LEVEL = "warning";
export const ERROR_LEVEL = "error";

export const captureMessageOnSentry = (
  message,
  level = INFO_LEVEL,
  extraData,
  tags
) => {
  const scope = new Sentry.Scope();
  scope.setLevel(level);
  if (extraData) {
    scope.setContext("Additional Data", extraData);
  }
  if (tags) {
    scope.setTags(tags);
  }
  Sentry.captureMessage(message, scope);
};

export const captureErrorMessageOnSentry = (message, extraData, tags = {}) =>
  captureMessageOnSentry(message, ERROR_LEVEL, extraData, tags);
export const captureWarningMessageOnSentry = (message, extraData, tags = {}) =>
  captureMessageOnSentry(message, WARNING_LEVEL, extraData, tags);
export const captureInfoMessageOnSentry = (message, extraData, tags = {}) =>
  captureMessageOnSentry(message, INFO_LEVEL, extraData, tags);
