import React, { Component, useEffect, useState } from "react";
import Login from "../components/LogIn";

//redux
import {
  saveToken,
  setUser,
  clearUser,
  clearToken,
} from "../initializers/actions";
import { connect } from "react-redux";
import mApi from "../services/api";
import { errorMessageHandler } from "../helpers/messageHandler";
const IngresarContainer = (props) => {
  useEffect(() => {
    if (props.token) {
      props.history.push("/home");
    }
    const valores = window.location.search;

    //Creamos la instancia
    const urlParams = new URLSearchParams(valores);
    urlParams.has("confirmed") && setState({ ...state, confirmed: true });

    // //Accedemos a los valores
    // var producto = urlParams.get("confirmed");
  }, []);

  const [state, setState] = useState({ confirmed: false });

  const _getToken = (token) => {
    _getDataUserFromServer(token);
    props.saveToken(token);
  };

  const _getDataUserFromServer = (token) => {
    setState({ loading: true });
    let url = "/user";

    mApi
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": token,
          Accept: "*/*",
        },
      })
      .then((response) => {
        var mensaje = response;
        setState({ loading: false });

        if (mensaje !== "") {
          var nombre = response.data.User.name.toUpperCase();
          var apellido = response.data.User.lastname.toUpperCase();
          var NameIni = nombre.charAt(0);
          var ApeIni = apellido.charAt(0);
          var username = nombre + " " + apellido;

          setState({ username: username, initials: NameIni + ApeIni });
          let user = { user: username, negocios: [] };
          props.setUser(JSON.stringify(user));
          props.history.push("/home");
        } else {
          props.clearToken();
          props.history.push("/login");
        }
      })
      .catch((e) => {
        props.clearToken();
        props.clearUser();
        setState({ loading: false });
        errorMessageHandler(e);
        props.history.push("/login");
        console.error(e);
      });
  };

  return <Login getToken={_getToken} confirmed={state.confirmed} />;
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};
const mapDispatchToProps = {
  saveToken: saveToken,
  setUser: setUser,
  clearUser: clearUser,
  clearToken: clearToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(IngresarContainer);
