import React from "react";
import {
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  LinearProgress,
  ThemeProvider,
  Typography,
} from "@material-ui/core";

import { ArrowBack } from "@material-ui/icons";
import Container from "@material-ui/core/Container";

import { useStyles } from "./styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { theme_menu } from "../../styles/classes";
import {} from "../../styles/login.css";
import Copyright from "../Copyright";

const OlvideContraseña = (props) => {
  const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const { loading } = props;
  const _handleSubmit = () => {
    const { submitFunction } = props;

    submitFunction(email);
    setEmail("");
  };

  const _handleChange = (prop) => (event) => {
    setEmail(event.target.value);
  };
  return (
    <>
      <div style={{ height: "100vh", margin: "0 !important", padding: "0" }}>
        <ThemeProvider theme={theme_menu}>
          <CssBaseline />
          {loading && (
            <LinearProgress width="100%" id="loading" color="secondary" />
          )}
          <Grid container style={{ height: "100%" }}>
            <Grid
              container
              className={classes.bannerTop}
              item
              xs={12}
              sm={12}
              md={6}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Typography variant="h3" className="tituloCursivo" gutterBottom>
                Olvidese de los menus fisicos con{" "}
                <b
                  style={{
                    fontFamily: "Audiowide",
                    padding: "0 10px",
                    paddingTop: "5px",
                    color: "#2D9CDB",
                  }}
                >
                  {" "}
                  CATALOGY
                </b>{" "}
              </Typography>
              <Typography
                variant="h5"
                className="subtituloCursivo"
                component="h5"
                style={{ color: "#686868" }}
                gutterBottom
              >
                Use codigos QR para evitar contacto fisico innecesario!
              </Typography>
            </Grid>

            <Grid item md={6} className={classes.reset_password_container}>
              <Container component="main" maxWidth="sm">
                <Grid
                  container
                  justify="center"
                  align="center"
                  className={classes.logoImage_container}
                >
                  <a variant="h4" href="/" className="tituloCursivo">
                    <img
                      src="/logo_completo_white.png"
                      alt="no"
                      style={{ height: "150px" }}
                    ></img>
                  </a>
                </Grid>
                <Grid
                  container
                  justify="center"
                  align="center"
                  style={{ marginBottom: "40px" }}
                >
                  <Typography className={classes.typoTitle} gutterBottom>
                    Recupera tu contraseña
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="center"
                  align="center"
                  style={{ marginBottom: "40px" }}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitleMessage}
                  >
                    Te enviaremos un correo con un link magico para que
                    recuperes tu contraseña.
                  </Typography>
                </Grid>
                <Grid>
                  <ValidatorForm
                    onSubmit={_handleSubmit}
                    onError={(errors) => console.log(errors)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          label="Correo Electronico"
                          name="email"
                          autoComplete="email"
                          onChange={_handleChange("email")}
                          value={email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Este campo es requerido",
                            "Correo no es valido",
                          ]}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          id="button_back"
                          color="primary"
                          href="/login"
                          className={classes.submit}
                        >
                          <ArrowBack fontSize="small" />
                          <Typography
                            variant="button"
                            style={{ paddingLeft: 5 }}
                          >
                            Volver
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          id="button_submit"
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={loading}
                        >
                          {loading ? <CircularProgress size={25} /> : "Enviar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </Grid>
                <Grid style={{ paddingTop: 40 }}>
                  <Copyright />
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    </>
  );
};

export default OlvideContraseña;
