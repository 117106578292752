import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
  body: {
    maxHeight: "100px",
    fontSize: 14,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
}))(TableCell);

export default StyledTableCell;
