import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
// import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./styles";

export const SimpleCard = (props) => {
  const classes = useStyles();

  return (
    <div className="AllCard">
      <Fab
        className={classes.botonAdd}
        disabled={props.clickNew ? false : true}
        color="secondary"
        onClick={props.clickNew}
        aria-label="add"
        style={{ left: "250px", top: "35px" }}
      >
        {props.clickNew ? <AddIcon />:""}
      </Fab>
      <Card className={classes.cards} onClick={props.link}>
        <CardContent
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Typography
              className={classes.title}
              color="textPrimary"
              gutterBottom
            >
              {props.titulo}
            </Typography>
            <Typography
              className={classes.pos}
              variant="caption"
              component="p"
              color="textSecondary"
            >
              {props.descripcion}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "60px",
                alignItems: "flex-end",
              }}
            >
              <Typography
                className={classes.number}
                variant="h2"
                component="h2"
                color="secondary"
              >
                {props.cantidad}
              </Typography>
            </div>
          </div>
          <Typography
            variant="body2"
            component="p"
            style={{ display: "flex", alignItems: "center", height: "100px" }}
          >
            <img
              src={props.icon}
              alt="ICON"
              style={{ height: "60px", paddingTop: "30px" }}
            ></img>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
