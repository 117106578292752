import React, { useEffect } from "react";

import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { ValidatorForm } from "react-material-ui-form-validator";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import WhatsApp from "@material-ui/icons/WhatsApp";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";

import LogoCard from "../LogoCard";
import UbicacionBar from "../UbicacionBar";
import TabPanel from "../TabPanel";
import countries from "../../data/countries.json";
import tipoNegocio from "../../data/tipoNegocio.json";
import rubrosNeg from "../../data/rubrosNeg.json";
import { useStyles } from "./styles";
import { useBeforeUnload } from "../../helpers/useBeforeUnload";
import { Prompt } from "react-router";
import { Divider, FormControlLabel, Switch } from "@material-ui/core";

const Negocio = (props) => {
  const classes = useStyles();
  const [isValidEmail, setValidEmail] = React.useState(true);
  const [isValidTelefono, setValidTelefono] = React.useState(true);
 
  const [values, setValues] = React.useState({
    name: props.negocio.name ?? "",
    pais: props.negocio.pais ?? "",
    provincia: props.negocio.provincia ?? "",
    ciudad: props.negocio.ciudad ?? "",
    provincias: props.provincias ?? [],
    rubro: props.negocio.rubro ?? "",
    tipo: props.negocio.tipo ?? "",
    direccion: props.negocio.direccion ?? "",
    telefono: props.negocio.telefono ?? "",
    telefono2: props.negocio.telefono2 ?? "",
    web: props.negocio.web || "",
    correo: props.negocio.correo ?? "",
    twitter: props.negocio.twitter,
    instagram: props.negocio.instagram,
    facebook: props.negocio.facebook,
    id: props.negocio.id,
    alertOpen: props.alertOpen,
    url_image: props.negocio.url_image ?? "",
    data_image: props.logo_image,
    moneda: props.negocio.moneda === "" ? "USD($)" : props.negocio.moneda,
    url_background: props.negocio.url_background ?? "",
    f_carrito: props.negocio?.f_carrito || false,
    f_pedidos_ws: props.negocio?.f_pedidos_ws || false,
    ws_telefono: props.negocio?.ws_telefono || "",
  });

  useEffect(() => {
    setValues({
      ...values,
      name: props.negocio.name ?? "",
      pais: props.negocio.pais ?? "",
      provincia: props.negocio.provincia ?? "",
      ciudad: props.negocio.ciudad ?? "",
      provincias: props.provincias ?? [],
      rubro: props.negocio.rubro ?? "",
      tipo: props.negocio.tipo ?? "",
      direccion: props.negocio.direccion ?? "",
      telefono: props.negocio.telefono ?? "",
      telefono2: props.negocio.telefono2 ?? "",
      web: props.negocio.web || "",
      correo: props.negocio.correo ?? "",
      twitter: props.negocio.twitter,
      instagram: props.negocio.instagram,
      facebook: props.negocio.facebook,
      id: props.negocio.id,
      alertOpen: props.alertOpen,
      url_image: props.negocio.url_image ?? "",
      data_image: props.logo_image,
      url_background: props.negocio.url_background ?? "",
      moneda: props.negocio.moneda || "USD($)",
      f_carrito: props.negocio?.f_carrito || false,
      f_pedidos_ws: props.negocio?.f_pedidos_ws || false,
      ws_telefono: props.negocio?.ws_telefono || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.negocio]);

  const isValuesChanged = React.useMemo(() => {
    let hasChanges = false;
    Object.entries(props.negocio).forEach(([key, value]) => {
      if (
        typeof values[key] !== "undefined" &&
        values[key] !== value &&
        key !== "provincias"
      ) {
        hasChanges = true;
      }
    });
    return hasChanges;
  }, [props.negocio, values]);

  const handleChange = (prop) => (event) => {
    const value = event.target.value;
  

    if (prop === "f_carrito") {
      setValues({ ...values, f_carrito: !values.f_carrito });
      return;
    }
    if (prop === "f_pedidos_ws") {
      setValues({ ...values, f_pedidos_ws: !values.f_pedidos_ws });
      return;
    }
    if (prop === "correo") {
      setValues({
        ...values,
        [prop]: value.trim(),
      });

      validateEmail(value.trim()) ? setValidEmail(true) : setValidEmail(false);
    } else if (prop === "telefono") {
      setValues({
        ...values,
        [prop]: value.trim(),
      });

      validatePhone(value.trim())
        ? setValidTelefono(true)
        : setValidTelefono(false);
    } else {
      setValues({
        ...values,
        [prop]: value,
      });
    }
  };

  const validateEmail = (correo) =>
    correo.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  const validatePhone = (phone) => phone.match(/^\+[1-9]{1,}[0-9]{3,14}$/);

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ...values,
    };

    if (!validateEmail(values.correo)) {
      setValidEmail(false);
      return alert("Email no valido");
    }

    if (!validatePhone(values.telefono)) {
      return alert("Telefono no valido");
    }
    if (!values.url_background || !values.url_image) {
      alert(
        "Falta imagenes por seleccionar. Dirijase a la Pestaña 'Diseño y Marca'."
      );

      return;
    }
   
    props.handleSubmit(data);
  };

  const onChangeIma = (e) => {
    const imagen = e.target.files[0];

    setValues({
      ...values,
      url_image: imagen,
    });
  };

  const onChangeBackground = (e) => {
    const imagen = e.target.files[0];

    setValues({
      ...values,
      url_background: imagen,
    });
  };
  const handleNotFocusText = (prop) => (event) => {
    let value = event.target.value.trim();

    setValues({
      ...values,
      [prop]: value,
    });
  };

  const [value, setValue] = React.useState(0);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const disabledSave = React.useMemo(
    () => values.disabled || props.loading || !isValuesChanged,
    [isValuesChanged, props.loading, values.disabled]
  );

  const hasFieldsEmpty = React.useMemo(() => {
    return props.negocio.name ? false : true;
  }, [props.negocio]);

  useBeforeUnload({
    when: isValuesChanged && !props.loading,
    message: "Seguro deseas dejar la pagina sin guardar los cambios?",
  });
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Prompt
        when={isValuesChanged && !props.loading}
        message="Tienes cambios sin guardar, ¿Seguro deseas dejar la pagina?"
      />
      <Prompt
        when={hasFieldsEmpty}
        message="Aun no has creado el negocio, sin este paso no podras crear catálogos, ¿Seguro deseas dejar la pagina?"
      />
      <UbicacionBar modulo="Negocio" />

      <Paper elevation={4} >
        <ValidatorForm onSubmit={handleSubmit} id="form">
          <Grid item className={classes.buttons_container_bottom}>
            <Button
              id="button_submit"
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={disabledSave}
            >
              <SaveIcon />
              <div style={{ paddingLeft: "5px" }}>Guardar</div>
            </Button>
          </Grid>
          <Grid container justify="space-between">
            <Tabs
              value={value}
              variant="scrollable"
              onChange={handleChangeTabs}
              aria-label="Tabs con categorias de la informacion del negocio"
              scrollButtons="on"
            >
              <Tab label="Información Básica" {...a11yProps(0)} />
              <Tab label="Redes Sociales" {...a11yProps(1)} />
              <Tab label="Diseño y Marca" {...a11yProps(2)} />
              <Tab label="Funcionalidades" {...a11yProps(3)} />
            </Tabs>

            <Grid item className={classes.buttons_container_top}>
              <Button
                id="button_cancel"
                variant="contained"
                href="/home"
                className={classes.button_previo}
              >
                Cancelar
              </Button>
              <Button
                id="button_submit"
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={disabledSave}
              >
                <SaveIcon />
                <div style={{ paddingLeft: "5px" }}>Guardar</div>
              </Button>
            </Grid>
          </Grid>

          <TabPanel value={value} index={0}>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              className={classes.containerDIv}
              style={{ paddingTop: 30, marginTop: 30 }}
            >
              <TextField
                required
                className={classes.texbox}
                id="negocio_name"
                label="Nombre"
                variant="outlined"
                color="primary"
                value={values.name}
                onChange={handleChange("name")}
                name="name"
                onBlur={handleNotFocusText("name")}
              />

              <TextField
                select
                className={classes.texbox}
                id="negocio_tipos"
                label="Tipo"
                variant="outlined"
                color="primary"
                value={values.tipo}
                onChange={handleChange("tipo")}
                name="tipo"
              >
                {tipoNegocio.tipos.map((elem) => (
                  <MenuItem key={elem.id} value={elem.id}>
                    {" "}
                    {elem.name}{" "}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                className={classes.texbox}
                id="negocio_rubros"
                label="Rubro"
                variant="outlined"
                color="primary"
                value={values.rubro}
                onChange={handleChange("rubro")}
                name="rubro"
              >
                {rubrosNeg.rubros.map((elem, index) => (
                  <MenuItem key={elem.id} value={elem.id}>
                    {" "}
                    {elem.name}{" "}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              className={classes.containerDIv}
            >
              <TextField
                required
                select
                className={classes.texbox}
                id="negocio_pais"
                label="Pais"
                variant="outlined"
                color="primary"
                value={values.pais}
                onChange={(e) => {
                  props.filtrarProvincia(e.target.value);
                  setValues({
                    ...values,
                    pais: e.target.value,
                  });
                }}
              >
                {countries.countries.map((elem, index) => (
                  <MenuItem key={elem.id} value={elem.id}>
                    {" "}
                    {elem.name}{" "}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                select
                className={classes.texbox}
                id="negocio_provincia"
                label="Provincia"
                variant="outlined"
                color="primary"
                value={values.provincia}
                disabled={!values.pais}
                onChange={(e) => {
                  props.filtrarCiudad(e.target.value);
                  setValues({
                    ...values,
                    provincia: e.target.value,
                  });
                }}
              >
                {props.provincias &&
                  props.provincias.map((elem) => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {" "}
                      {elem.name}{" "}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                required
                select
                className={classes.texbox}
                id="negocio_ciudad"
                label="Ciudad"
                variant="outlined"
                color="primary"
                value={values.ciudad}
                onChange={handleChange("ciudad")}
                disabled={!values.provincia}
              >
                {props.ciudades &&
                  props.ciudades.map((elem) => (
                    <MenuItem key={elem.id} value={elem.id}>
                      {" "}
                      {elem.name}{" "}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              className={classes.containerDIv}
            >
              <TextField
                required
                className={classes.texboxDireccion}
                id="negocio_direccion"
                label="Direccion"
                variant="outlined"
                color="primary"
                name="direccion"
                value={values.direccion}
                onChange={handleChange("direccion")}
                onBlur={handleNotFocusText("direccion")}
              />

              <TextField
                required
                select
                className={classes.texboxMoneda}
                id="negocio_moneda"
                label="Moneda"
                variant="outlined"
                color="primary"
                value={values.moneda}
                onChange={handleChange("moneda")}
              >
                {props.monedas &&
                  props.monedas.map((elem) => (
                    <MenuItem key={elem.code} value={elem.code}>
                      {" "}
                      {elem.code}
                      <span
                        style={{
                          paddingLeft: 10,
                          fontSize: "10px",
                          color: "grey",
                        }}
                      >
                        {elem.name}
                      </span>
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              className={classes.containerDIv}
              style={{ paddingTop: 5 }}
            >
              <TextField
                type="text"
                error={!isValidTelefono}
                required
                inputProps={{
                  min: "1",
                  pattern: "[+][0-9]+",
                  className: "textboxTelefono",
                }}
                className={classes.texbox}
                id="negocio_telefono"
                label="Numero Telefono"
                variant="outlined"
                color="primary"
                placeholder="+56912344321"
                name="telefono"
                autoComplete="telefono"
                onChange={handleChange("telefono")}
                value={values.telefono}
                style={{ marginTop: 5, height: "57px" }}
              ></TextField>
              <TextField
                error={!isValidEmail}
                helperText={!isValidEmail && "Email invalido"}
                className={classes.texbox}
                required
                variant="outlined"
                id="correo"
                label="Correo Electronico"
                name="correo"
                autoComplete="correo"
                onChange={handleChange("correo")}
                value={values.correo}
                validators={["required", "isEmail"]}
                erroressages={[
                  "Este campo es requerido",
                  "Correo no es valido",
                ]}
                onBlur={handleNotFocusText("correo")}
              />

              <TextField
                className={classes.texbox}
                id="negocio_pagina"
                label="Pagina Web"
                variant="outlined"
                color="primary"
                value={values.paginaWeb}
                onChange={handleChange("paginaWeb")}
                onBlur={handleNotFocusText("paginaWeb")}
              ></TextField>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid
              container
              direction="row"
              justify="space-around"
              style={{ marginTop: "15px" }}
            >
              <FormControl className={classes.margin}>
                <Input
                  id="negocio_whatsapp"
                  autoComplete="phone"
                  type="text"
                  inputProps={{
                    min: "1",
                    pattern: "[+][0-9]+",
                    className: "textboxTelefono",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <WhatsApp />
                    </InputAdornment>
                  }
                  value={values.telefono2}
                  onChange={handleChange("telefono2")}
                  onBlur={handleNotFocusText("telefono2")}
                  placeholder="+5612345678"
                />
              </FormControl>
              <FormControl className={classes.margin}>
                {/* <InputLabel htmlFor="negocio_twitter"></InputLabel> */}
                <Input
                  id="negocio_twitter"
                  inputProps={{
                    pattern: "[0-9a-zA-z]+",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <TwitterIcon />
                    </InputAdornment>
                  }
                  value={values.twitter}
                  onChange={handleChange("twitter")}
                  onBlur={handleNotFocusText("twitter")}
                  placeholder="tuNegocio (sin @)"
                />
              </FormControl>
              <FormControl className={classes.margin}>
                <Input
                  id="negocio_instagram"
                  inputProps={{
                    pattern: "[0-9a-zA-z]+",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  }
                  value={values.instagram}
                  onChange={handleChange("instagram")}
                  onBlur={handleNotFocusText("instagram")}
                  placeholder="tuNegocio (sin @)"
                />
              </FormControl>
              <FormControl className={classes.margin}>
                <Input
                  id="negocio_facebook"
                  inputProps={{
                    pattern: "[0-9a-zA-z]+",
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  }
                  name="fb"
                  value={values.facebook}
                  onChange={handleChange("facebook")}
                  onBlur={handleNotFocusText("facebook")}
                />
              </FormControl>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container justify="center">
              <Grid
                item
                sm={4}
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                md={4}
              >
                <Typography style={{ marginBottom: 10 }} variant="h5">
                  Logo <sup style={{ color: "red" }}>*</sup>
                </Typography>
                <LogoCard
                  boton="LOGO"
                  frase="Seleccione el logo para su negocio.!"
                  required
                  onChangeIma={onChangeIma}
                  image={values.url_image || props.negocio.url_image}
                  nombreImagen={values.url_image}
                  showButton
                  size={200}
                />
              </Grid>
              <Grid
                item
                sm={12}
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                md={8}
              >
                <Typography style={{ marginBottom: 10 }} variant="h5">
                  Banner de Fondo <sup style={{ color: "red" }}>*</sup>
                </Typography>
                <LogoCard
                  boton="Background"
                  frase="Seleccione el fondo para su negocio.!"
                  required
                  onChangeIma={onChangeBackground}
                  image={values.url_background || props.negocio.url_background}
                  nombreImagen={"Background"}
                  showButton
                  size={300}
                />
              </Grid>
            </Grid>
            <Grid>
              <sup style={{ color: "red" }}> * </sup> Campos obligatorios
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid
              container
              direction="row"
              justify="space-around"
              style={{ marginTop: "15px" }}
            ></Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              className={classes.containerDIv}
            >
              <Grid item style={{ paddingLeft: 20 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.f_carrito}
                      onChange={handleChange("f_carrito")}
                      color="primary"
                    />
                  }
                  label="Lista preseleccion / Carrito"
                />
                <Grid item style={{ paddingLeft: 25 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.f_pedidos_ws}
                        onChange={handleChange("f_pedidos_ws")}
                        color="primary"
                        disabled={!values.f_carrito}
                      />
                    }
                    label="Permitir pedidos por Whatsapp"
                  />
                  <FormControl className={classes.margin}>
                    <Input
                      id="ws_telefono"
                      autoComplete="whatsapp"
                      type="text"
                      inputProps={{
                        min: "1",
                        pattern: "[+][0-9]+",
                        className: "textboxTelefono",
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <WhatsApp />
                        </InputAdornment>
                      }
                      disabled={!values.f_carrito || !values.f_pedidos_ws}
                      value={values.ws_telefono}
                      onChange={handleChange("ws_telefono")}
                      onBlur={handleNotFocusText("ws_telefono")}
                      placeholder="+5612345678"
                      defaultValue={values.telefono2}
                      required
                    />
                  </FormControl>
                 
                </Grid>
                <Typography style={{ marginBottom: 10 }} variant="title">
                Este número debe tener Whatsapp instalado, puede usar el mismo número de contacto o uno distinto.
                </Typography>
              </Grid>
            </Grid>
          </TabPanel>
        </ValidatorForm>
      </Paper>
    </div>
  );
};

export default Negocio;
