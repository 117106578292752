import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
// import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import { useStyles } from "./styles";
import tipoNegocio from "../../data/tipoNegocio.json";
import rubrosNeg from "../../data/rubrosNeg.json";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Telefono from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
export const DataNegocioCard = (props) => {
  const {
    icon = "images/icons/online-shopping.png",
    name,

    phone,
    phone2,
    address,
    rubro,
    tipo,
    url_image,
    callbackEdit,
  } = props;
  const classes = useStyles();

  const _getValueName = (id, array) => {
    let value = array.filter((v) => v.id === id);

    return value[0].name;
  };

  return (
    <>
      <Card className={classes.cards}>
        <div style={{ height: "50%", display: "flex", padding: "0px 20px" }}>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          >
            {url_image ? (
              <div
                className={classes.picture_articulo}
                style={{ backgroundImage: `url(${url_image})` }}
              ></div>
            ) : (
              <img src={icon} alt="ICON" style={{ height: "50px" }}></img>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px 10px",
            }}
          >
            <Fab
              color="secondary"
              size="small"
              onClick={() => callbackEdit()}
              className={classes.button}
            >
              <EditIcon fontSize="small" />
            </Fab>
            <Typography
              variant="body2"
              component="p"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100px",
              }}
              className={classes.title}
            >
              {name}
            </Typography>
            <Typography
              variant="caption"
              component="p"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100px",
                paddingLeft: "2px",
              }}
            >
              {tipo && `${_getValueName(tipo, tipoNegocio.tipos)} `}
              {tipo && rubro && "-"}
              {rubro && `${_getValueName(rubro, rubrosNeg.rubros)}`}
            </Typography>
          </div>
        </div>
        <div className={classes.texto_largo}>
          <div style={{ display: "flex", paddingBottom: "5px" }}>
            <Telefono style={{ fontSize: "18px" }}></Telefono>
            <Typography
              variant="body2"
              component="p"
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
                paddingRight: "10px",
              }}
            >
              {phone}
            </Typography>
            {phone2 && (
              <>
                -
                <WhatsApp
                  style={{ fontSize: "18px", paddingLeft: "10px" }}
                ></WhatsApp>
                <Typography
                  variant="body2"
                  component="p"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  {phone2}
                </Typography>
              </>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <RoomIcon style={{ fontSize: "18px" }} />
            <div
              variant="body2"
              component="p"
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "7px",
                fontSize: "14px",
                width: "100%",
              }}
            >
              <div className={classes.noWrap}>{address}</div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
