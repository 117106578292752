import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import ModalArticuloContainer from "../containers/ModalArticuloContainer";
import DisplayPrecio from "./DisplayPrecio";
import LazyLoadComponent from "./LazyLoadComponent";
import NoImage from "./NoImage";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  picture_articulo: {
    width: "40px;",
    height: "40px;",
    backgroundSize: "cover;",
    backgroundRepeat: "no-repeat;",
    backgroundPosition: "50% 50%;",
    overflow: "hidden;",
    borderRadius: "7px;",
    [theme.breakpoints.down(350)]: {
      display: "none",
    },
  },
  image_container: {
    [theme.breakpoints.down(400)]: {
      display: "none",
    },
  },
}));

export default function CardArticulo(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const moneda = useSelector(
    (state) =>
      JSON.parse(state.user).negocios[0].moneda.split("(")[1].split(")")[0]
  );

  const menuId = "primary-articulo-menu";

  const isMenuOpen = Boolean(anchorEl);

  const [openModal, setOpen] = React.useState(false);
  const [datosArticulo, setDatosArt] = React.useState({
    name: props.name,
    description: props.description,
    precio: props.precio,
    url_image: props.url_image,
    id: props.id,
    status: props.status,
    discount: props.discount,
  });
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEditArticle = () => {
    setOpen(true);
  };

  const handleDeleteArticle = () => {
    props.deleteArticle(datosArticulo.id);
  };

  const agregarArticulo = ({
    id,
    name,
    description,
    url_image,
    precio,
    status,
    discount,
  }) => {
    setDatosArt({
      ...datosArticulo,
      name: name,
      description: description,
      url_image: url_image,
      precio: precio,
      id: id,
      status: status,
      discount: discount,
    });
    handleMenuClose();
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const cancelarArticulo = () => {
    setOpen(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleEditArticle}>Editar</MenuItem>
      {/* <MenuItem onClick={handleDeleteArticle}>Eliminar</MenuItem> */}

      <ModalArticuloContainer
        open={openModal}
        guardarArticulo={agregarArticulo}
        cancelarArticulo={cancelarArticulo}
        articulo={datosArticulo}
      ></ModalArticuloContainer>
    </Menu>
  );

  return (
    <LazyLoadComponent>
      <Card className={classes.root}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CardHeader
            className="iconDrop"
            onClick={handleExpandClick}
            style={{ width: "95%", paddingTop: 2, paddingBottom: 1 }}
            avatar={
              datosArticulo.url_image ? (
                <div
                  className={classes.picture_articulo}
                  style={{ backgroundImage: `url(${datosArticulo.url_image})` }}
                ></div>
              ) : (
                <div className={classes.picture_articulo}>
                  {" "}
                  <NoImage />{" "}
                </div>
              )
            }
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {datosArticulo.name}{" "}
              </div>
            }
            subheader={
              datosArticulo.precio && (
                <DisplayPrecio {...datosArticulo} moneda={moneda} />
              )
            }
          />
          {renderMenu}
          <div>
            <IconButton
              aria-label="settings"
              onClick={handleProfileMenuOpen}
              aria-controls="primary-articulo-menu"
              aria-haspopup="true"
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>

        <Collapse in={expanded} timeout="auto">
          {expanded && datosArticulo.url_image && (
            <CardMedia
              className={classes.media}
              src={datosArticulo.url_image ? datosArticulo.url_image : "/"}
              image={datosArticulo.url_image ? datosArticulo.url_image : ""}
              title=""
            >
              {!datosArticulo.url_image ? "" : null}
            </CardMedia>
          )}
          <CardContent>
            <Typography variant="body1" color="textSecondary" component="p">
              {datosArticulo.description}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </LazyLoadComponent>
  );
}
