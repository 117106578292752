import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2D9CDB",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    type: "light",
  },
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  chk: {
    backgroundColor: theme.palette.primary.main,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ButtonTerms() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Checkbox
          value=""
          required
          color="primary"
          className={styles.chk}
          id="chk"
        />
        <label htmlFor="chk">
          Acepto los{" "}
          <Link onClick={handleClickOpen} color="inherit">
            {" "}
            Terminos y Condiciones
          </Link>{" "}
        </label>

        <Dialog
          maxWidth={"lg"}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Terminos y Condiciones
          </DialogTitle>
          <DialogContent dividers="true">
            <p>
              Gruphus Tech Inc. (en adelante, CATALOGY), de otra parte el
              cliente (en adelante "CLIENTE"), acuerdan los siguientes términos
              del presente contrato de servicio: La contratación de los
              servicios prestados por CATALOGY se llevará a cabo por medios
              electrónicos, a través de las páginas web de CATALOGY.
            </p>
            <h2>Servicios Prestados</h2>
            <p>
              El actual contrato tiene por objeto la prestación de servicios
              telemáticos dentro de la red Internet y los tipos de prestación de
              servicios están clasificados dentro de las siguientes categorías:
            </p>
            <ul>
              <li>Catalogo digital para negocios.</li>
            </ul>
            <h2>Objetivo de la aplicación</h2>
            <p>
              Brindar la oportunidad a los negocios de crear un catalogo o lista
              de precios virtual mediante la generación de un código QR., la
              cual podrá modificarse desde un panel de gestión por el
              propietario del negocio.
            </p>
            <h2>Responsabilidad de los usuarios</h2>
            <p>
              Los usuarios que utilicen esta aplicación deben hacerlo de forma
              responsable. CATALOGY se compromete a vigilar un mal uso de la
              aplicación por parte de los usuarios y se reserva el derecho de
              eliminar las cuentas a aquellos usuarios que estén haciendo un uso
              fraudulento de la misma.
            </p>
            <p>Algunas situaciones que se consideran fraudulentas:</p>
            <ul>
              <li>Creación de multicuentas de negocios</li>
              <li>Creación de negocios ficticios</li>
              <li>
                Subida de productos o información relacionadas con pornografía,
                armas, venta de medicamentos, fraude, estupefacientes,
                manifestaciones políticas o cualquier otra temática que se
                desligue del objetivo de la aplicación o incumplan la ley.
              </li>
              <li>
                Creación de cuentas con el objetivo de crear enlaces externos.
              </li>
              <li>Actividades de spam o envío masivo</li>
            </ul>
            <p>
              CATALOGY se reserva el derecho de denunciar actividades ilegales a
              los cuerpos de policía o a cualquier autoridad administrativa o
              gubernamental.
            </p>
            <h2>Otros comportamientos inadecuados</h2>
            <p>
              Los siguientes ejemplos no son exclusivos. El CLIENTE puede
              ponerse en contacto con CATALOGY si no está seguro sobre si sus
              acciones constituirán una violación de nuestra política.
            </p>
            <h2>Suspensión</h2>
            <p>
              Cuando se den las circunstancias reseñadas anteriormente, se
              procederá a la suspensión provisional del servicio contratado por
              el CLIENTE.
            </p>
            <p>
              A su vez, CATALOGY se reserva el derecho de desactivar las cuentas
              gratuitas en caso de su activación este provocando graves
              trastornos en el funcionamiento de la aplicación por una cuestión
              de tráfico, consumo de recursos, etc.
            </p>
            <h2>Publicidad y datos</h2>
            <p>
              El CLIENTE libera a CATALOGY de cualquier responsabilidad
              relacionada con el contenido de la información almacenada por el
              CLIENTE en los servidores de CATALOGY.
            </p>
            <p>
              El CLIENTE es el único responsable frente a cualquier reclamación
              o acción legal, judicial o extrajudicial, iniciada por terceras
              personas tanto contra el CLIENTE como contra CATALOGY, y basada en
              el contenido de dicha información, asumiendo el CLIENTE cuantos
              gastos, costes e indemnizaciones sean irrogadas a CATALOGY con
              motivo de tales reclamaciones o acciones legales.
            </p>
            <p>
              CATALOGY se reserva el derecho de introducir anuncios o medios
              publicitarios en las cartas virtuales de aquellos planes gratuitos
              sin previo consentimiento.
            </p>
            <p>
              Cualquier usuario que deposite información en los servidores de
              CATALOGY, en forma de páginas web, o de cualquier otra manera, que
              permita el acceso de terceros, no se convertirá, en virtud de tal
              acto, en socio ni colaborador de CATALOGY, sino que será un
              divulgador o editor independiente de contenido. Por el hecho de
              conectar un servidor a la red, CATALOGY no se convierte en editor
              ni distribuidor de la información depositada por los usuarios en
              los servidores de CATALOGY y, por lo tanto, CATALOGY no asume
              responsabilidad alguna por el perjuicio que dichos contenidos
              puedan causar a otros usuarios.
            </p>
            <p>
              Con el fin de prevenir infracciones en las que CATALOGY pueda ser
              declarado responsable civil subsidiario, el CLIENTE autoriza
              expresamente a CATALOGY a efectuar las medidas de control
              necesarias para comprobar la licitud de la información depositada
              en el servidor que se halla bajo su administración. El CLIENTE
              también autoriza a CATALOGY a facilitar los datos que le sean
              requeridos por las fuerzas de seguridad del Estado por orden
              judicial, en el curso de una investigación policial.
            </p>
            <p>
              CATALOGY se compromete a realizar el máximo esfuerzo en mantener
              un nivel aceptable en el cumplimiento de sus obligaciones
              contractuales, pero no puede garantizar, explícita o
              implícitamente, la continuidad del servicio en un momento
              determinado, ni la integridad de la información almacenada o
              transferida a través de su sistema o de Internet. CATALOGY tampoco
              se hace responsable del acceso no autorizado por terceros a
              información del CLIENTE almacenada en el sistema, pérdida o
              corrupción de la misma.
            </p>
            <p>
              CATALOGY no será responsable ante el CLIENTE, ni ante los clientes
              de éste, por cualquier daño derivado de estos casos, incluyendo en
              los mismos: pérdida total o parcial de la información,
              imposibilidad de acceder a Internet, o imposibilidad de transmitir
              o recibir información causada por retrasos o interrupciones de
              servicio, sean o no responsabilidad o negligencia de CATALOGY.
            </p>
            <h2>Confidencialidad</h2>
            <p>
              El CLIENTE reconoce que, en virtud de su relación contractual con
              CATALOGY, podría tener acceso a información y materiales
              relacionados con los planes de negocio, listados de clientes,
              tecnología o estrategias de marketing de gran valor para CATALOGY
              y que no deben ceder bajo ningún pretexto en poder de terceros.
            </p>
            <p>
              El CLIENTE se compromete a no utilizar esta información en su
              propio provecho ni en el de terceros no autorizados por CATALOGY.
            </p>
            <p>
              El CLIENTE se compromete asimismo a tomar las medidas necesarias
              para que esta información no caiga en poder de terceros.
            </p>
            <p>
              El CLIENTE se compromete asimismo a devolver o destruir estos
              materiales una vez terminada su relación contractual con CATALOGY.
              CATALOGY se reserva el derecho de exigir responsabilidades por
              daños y perjuicios al CLIENTE en caso de incumplimiento.
            </p>
            <h2>Relaciones y notificaciones entre las partes</h2>
            <p></p>
            Las relaciones entre las partes son las de un cliente y un proveedor
            de servicios. El CLIENTE no tendrá ningún derecho a obligar a
            CATALOGY ante terceros sin el consentimiento expreso de CATALOGY.
            <p></p>
            <p>
              Salvo si la legislación procesal exigiese lo contrario, las partes
              acuerdan comunicarse por correo electrónico o correo postal,
              utilizando las direcciones más actualizadas en su poder. Las
              partes estarán obligadas a conservar prueba de haber realizado las
              notificaciones a la parte contraria.
            </p>
            <h2>Garantía de Reembolso (planes de pago)</h2>
            <p>
              El cliente tendrá un plazo de 7 días naturales a partir de la
              fecha de abono del coste del servicio para resolver el presente
              contrato sin incurrir en penalización ni gasto alguno. Tras el
              ejercicio de este derecho, CATALOGY reintegrará al CLIENTE la
              cuantía abonada por la prestación del servicio. Los gastos
              financieros de este reembolso, si los hubiere, correrán por cuenta
              del CLIENTE. Fuera del plazo de garantía al que se refiere este
              apartado el cliente no tiene derecho a devolución alguna por el
              cese voluntario y anticipado del servicio contratado.
            </p>
            <h2>Nulidad Parcial</h2>
            <p>
              Si cualquier parte de este contrato fuera contraria a derecho y,
              por tanto, inválida, ello no afectará a las otras disposiciones
              conformes a derecho. Las partes se comprometen a renegociar
              aquellas partes del contrato que resultaran nulas y a
              incorporarlas al resto del contrato.
            </p>
            <h2>Legislación aplicable y jurisdicción competente</h2>
            <p>
              El presente contrato se regirá en todos los aspectos por las Leyes
              de Chile y todo conflicto que surja en virtud de este contrato o
              en relación con el mismo, estará sujeto a la jurisdicción
              exclusiva de los tribunales de Santiago, a los que ambas partes se
              someten con renuncia a su fuero propio, si lo tuviesen, con la
              excepción de que CATALOGY tendrá derecho, según su exclusivo
              arbitrio, a instar procedimiento en los tribunales de cualquier
              otro país en que el CLIENTE resida o en el que estén situados
              cualesquiera de los activos del CLIENTE y/o empresas de su grupo.
            </p>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
