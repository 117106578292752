import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
//redux
import { saveToken, setUser, clearUser } from "../initializers/actions";
import { connect } from "react-redux";
import mApi from "../services/api";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";

import CardContent from "@material-ui/core/CardContent";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useStyles, theme_menu } from "../styles/classes";
import { ThemeProvider } from "@material-ui/styles";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { SnackBar } from "../components/SnackBar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { errorMessageHandler } from "../helpers/messageHandler";

const ResendConfirm = (props) => {
  const [state, setState] = useState({
    email: "",
    loading: false,
    textMensaje: "",
    open: false,
    tipoAlert: "success",
    isInvalidToken: false,
  });

  const classes = useStyles();
  let { invalidToken } = useParams();

  useEffect(() => {
    const valores = window.location.search;

    //Creamos la instancia
    const urlParams = new URLSearchParams(valores);
    setState({ ...state, isInvalidToken: urlParams.has("invalidToken") });
  }, []);
  const _submitReenvio = () => {
    setState({ ...state, loading: true });
    let url = "resend_confirmation";

    mApi
      .post(url, { email: state.email })
      .then((response) => {
        var mensaje = response;
        setState({
          ...state,
          loading: false,
          email: "",
          textMensaje: mensaje.data.message,
          open: true, //
          tipoAlert: "success",
        });

        setTimeout(() => {
          props.history.push("/login");
        }, 4000);
      })
      .catch((e) => {
        setState({ loading: false });
        errorMessageHandler(e);
        props.history.push("/login");
        console.error(e);
      });
  };

  const handleChange = (prop) => (event) => {
    setState({
      ...state,
      [prop]: event.target.value,
    });
  };

  const handleNotFocusText = (prop) => (event) => {
    let value = event.target.value.trim();

    setState({
      ...state,
      [prop]: value,
    });
  };

  return (
    <>
      {" "}
      <ThemeProvider theme={theme_menu}>
        <SnackBar
          textMensaje={state.textMensaje}
          open={state.open}
          tipoAlert={state.tipoAlert}
          close={() => {
            setState({ ...state, open: false });
          }}
        ></SnackBar>
        <AppBar position="fixed" className={clsx(classes.appBar)}>
          <Toolbar>
            {" "}
            <Typography variant="h4" className="tituloCursivo">
              <img
                onClick={() => window.location.replace("/")}
                src="/logo_completo_dark.png"
                alt="no"
                style={{
                  height: "70px",
                  cursor: "pointer",
                  top: -5,
                  position: "absolute",
                  padding: "3px",
                }}
              ></img>
            </Typography>
            <Typography variant="h6" className={classes.title}></Typography>
            <Button
              onClick={() => {
                window.location.replace("/login");
              }}
              className={classes.buttonLogin}
              variant="text"
            >
              {" "}
              Entrar
            </Button>
          </Toolbar>
        </AppBar>

        <Grid
          container
          style={{
            height: "100vh",
            alignItems: "start",
            paddingTop: "150px",
            justifyContent: "center",
          }}
        >
          {state.loading && (
            <LinearProgress
              id="loading"
              color="secondary"
              style={{
                display: "block",
                height: "8px",
                position: "fixed",
                top: "61px",
                width: "110%",
                left: "-5px",
              }}
            />
          )}
          <Card
            style={{
              width: "80%",
              maxWidth: "600px",
              backgroundColor: "rgb(240, 240, 240)",
            }}
            className={classes.cardPrimary}
          >
            <Typography variant="inherit" style={{ padding: 10 }}>
              {" "}
            </Typography>
            <CardContent>
              <div data-wow-duration="1.5s">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {state.isInvalidToken && (
                    <h4 style={{ fontSize: "20px" }}>
                      ¡Token de confirmacion caducado!
                    </h4>
                  )}
                  <p style={{ marginBottom: "40px", paddingLeft: "15px" }}>
                    Ingrese su email para reenviar el correo de confirmacion.
                  </p>
                </div>
              </div>

              <ValidatorForm
                onSubmit={_submitReenvio}
                style={{ display: "flex", justifyContent: "start" }}
                onError={(errors) => console.log(errors)}
              >
                <Grid container justify="center" spacing={2}>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ padding: "0px 20px" }}
                  >
                    <TextValidator
                      className={classes.texbox}
                      required
                      variant="outlined"
                      id="email"
                      label="Correo Electronico"
                      name="email"
                      autoComplete="email"
                      onChange={handleChange("email")}
                      value={state.email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "Este campo es requerido",
                        "Correo no es valido",
                      ]}
                      onBlur={handleNotFocusText("email")}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      disabled={state.loading}
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 20, height: "55px" }}
                      type="submit"
                    >
                      {" "}
                      {state.loading ? "Enviando..." : "Reenviar"}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </CardContent>
          </Card>
        </Grid>
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};
const mapDispatchToProps = {
  saveToken: saveToken,
  setUser: setUser,
  clearUser: clearUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirm);
