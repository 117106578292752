import React, { Component } from "react";
import mApi from "../services/api";
import { Dashboard } from "../components/Dashboard/index";
import { clearToken, setUser, clearUser } from "../initializers/actions";
import { connect } from "react-redux";
import { errorMessageHandler } from "../helpers/messageHandler";

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      username: "",
      token: "",
      cantArt: 0,
      cantCart: 0,
      cantCat: 0,
      tieneNegocio: false,
      showCardNegocio: false,
      showQR: false,
      tieneCartas: false,
      cartas: [],
      negocios: [],
      negocio: {},

      negocio_id: "",
    };
  }

  linkCartas = () => {
    this.props.history.push("/home/cartas");
  };
  linkNewCarta = () => {
    this.props.history.push("/home/cartas/new");
  };
  linkNegocio = () => {
    this.props.history.push("/home/negocio");
  };

  componentDidMount() {
    this.mounted = true;
    this._loadDataDashboard();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  _loadDataDashboard = () => {
    const { user, token } = this.props;
    if (!token || !user || token === null || user === "") {
      this.props.history.push("/login");
      return;
    }

    const negocios = JSON.parse(user) ? JSON.parse(user).negocios : [];
    const userData = JSON.parse(user) && JSON.parse(user).user;
    if (userData) {
      let nombre;
      try {
        nombre = JSON.parse(user).user.split(" ")[0];
      } catch (error) {
        console.error(error);
        errorMessageHandler(error);
        nombre = "";
      }

      this.setState({ username: nombre });
    }
    if (negocios.length > 0) {
      let negocio = negocios[0];

      this.mounted &&
        this.setState({
          tieneNegocio: true,
          negocio: negocios[0],
          tieneCartas: negocios[0].cartas ? true : false,
        });

      var url = `dashboard/negocios/${negocio.id}`;

      this.mounted &&
        mApi({
          method: "get",
          url: url,
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": this.props.token,
          },
        })
          .then((response) => {
            var mensaje = response;

            if (mensaje !== "") {
              const {
                Articulos: cantArt = 0,
                Cartas: cantCart,
                Categorias: cantCat,
              } = mensaje.data.Dashboard;
              this.mounted &&
                this.setState({
                  cantArt: cantArt,
                  cantCart: cantCart,
                  cantCat: cantCat,
                  tieneCartas: cantCart > 0,
                  tieneNegocio: true,
                  negocio: negocio,
                });
            } else {
              this.props.clearToken();
              this.props.clearUser();
            }
          })
          .catch((e) => {
            console.error(e);
            errorMessageHandler(e);
          });
    }
  };

  render() {
    const {
      cantCat,
      cantCart,
      cantArt,
      tieneNegocio,
      tieneCartas,
      username,
      negocio,
    } = this.state;

    return (
      <Dashboard
        cantCat={cantCat}
        cantCart={cantCart}
        cantArt={cantArt}
        linkCartas={this.linkCartas}
        tieneNegocio={tieneNegocio}
        tieneCartas={tieneCartas}
        username={username}
        negocio={negocio}
        linkNewCarta={this.linkNewCarta}
        linkNegocio={this.linkNegocio}
        update_data={this._loadDataDashboard}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
