export const LimpiarCampoTextoEspacios = (values) => {
  let valuesTrim = {};
  for (let item in values) {
    let val = values[item];
    if (typeof values[item] === "string") {
      val = values[item].trim();
    }

    valuesTrim[item] = val;
  }
  return valuesTrim;
};

export function isFunction(obj) {
  return typeof obj == "function";
}

export function isObject(obj) {
  var type = typeof obj;
  return type === "function" || (type === "object" && !!obj);
}

export function hasWindowObject() {
  return typeof window !== "undefined" && window.document;
}

export function disableReactDevTools() {
  if (hasWindowObject()) {
    // Ensure the React Developer Tools global hook exists
    if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      return;
    }

    // Replace all global hook properties with a no-op function or a null value
    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      if (prop === "renderers") {
        // prevents console error when dev tools try to iterate of renderers
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
        continue;
      }
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
      )
        ? Function.prototype
        : null;
    }
  }
}

export const GlobalDebug = (function () {
  var savedConsole = console;
  /**
   * @param {boolean} debugOn
   * @param {boolean} suppressAll
   */
  return function (debugOn, suppressAll) {
    var suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      console = {};
      console.log = function () {};
      // supress all type of consoles
      if (suppress) {
        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();

export const removeObjectFromArrayById = (idToRemove, array) => {
  const cloneArray = array;
  const index = cloneArray.findIndex(
    ({ id }) => parseInt(id) == parseInt(idToRemove)
  );
  if (index !== -1) {
    
    cloneArray.splice(index, 1);
  }
  return cloneArray;
};
