import React from "react";
import { makeStyles } from "@material-ui/core/styles";

//Clases "CSS-SASS"
export const useStyles = makeStyles((theme) => ({
  reset_password_container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
  },

  typoTitle: {
    mozOsxFontSmoothing: "grayscale",
    webkitFontSmoothing: "antialiased",
    margin: "10px",
    fontSize: "24px",
  },
  logoImage_container: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  submit: {
    padding: theme.spacing(2, 0, 2),
  },
  subtitleMessage: {
    fontFamily: "Roboto",
    color: "grey",
  },
  bannerTop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#313131",
    color: " white !important;",
  },
}));
