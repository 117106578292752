import React from "react";
import { useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreIcon from "@material-ui/icons/MoreVert";

import EditIcon from "@material-ui/icons/Edit";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { Menu, MenuItem } from "@material-ui/core";
import ZoomQR from "./ZoomQR";
import QrCodeIcon from "./QrCodeIcon";
import { Link } from "react-router-dom";
import { customerURL } from "../helpers/constants";
import { useScreenSize } from "../helpers/useScreenSize";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
  body: {
    maxHeight: "100px",
    fontSize: 14,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  tools_container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(630)]: {
      display: "none ",
    },
  },

  menu_icon: {
    display: "none",
    [theme.breakpoints.down(630)]: {
      display: "block",
    },
  },
  noWrap: {
    // -ms-text-size-adjust: initial;
    wordWrap: "break-word;",
    flexWrap: "nowrap",
    textOverflow: "ellipsis !important",

    textAlign: "justify",
  },
  linkButton: {
    textDecoration: "none !important;",

    width: "100%",
  },
}));

export default function ListItemCarta(props) {
  const classes = useStyles();

  const idNegocio = useSelector(
    (state) => JSON.parse(state.user).negocios[0].id
  );

  const [values, setValues] = React.useState({
    estado: props.estado,
    nombre: props.nombre,
    descripcion: props.descripcion,
    creacion: props.creacion,
    articulos: props.articulos,
    id: props.id,
    qr_url: props.qr_url,
  });

  const [openPrint, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setOpenMenu] = React.useState(false);
  const handleChangeStatus = (e) => {
    props.edit_estado({ id: values.id, estado: !values.estado });
    setValues({ ...values, estado: !values.estado });
  };

  const editar_carta = () => {
    props.editar(values);
  };
  const abrir_delete = () => {
    props.abrir_modal(values.id);
  };
  const _handleOpenPrint = () => {
    setOpen(true);
  };

  const _handleClose = () => {
    setOpen(false);
  };

  const _ventanaSecundaria = (URL) => {
    window.open(
      URL,
      "catalogo",
      "width=400,height=700,resizable=NO,directories=NO,location=NO,menubar=NO,scrollbars=NO,Toolbar=NO,left=400,top=200"
    );
  };
  const { id } = props;
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="menu-options"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      width="200px"
    >
      <MenuItem
        title={values.estado ? "Desactivar" : "Activar"}
        color="inherit"
      >
        <input id={values.id} hidden></input>
        <Switch
          checked={values.estado}
          onChange={handleChangeStatus}
          color="primary"
          name="checkedB"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        {values.estado ? "Activa" : "Inactiva"}
      </MenuItem>

      <MenuItem
        title="Visualizar Carta"
        onClick={() => {
          _ventanaSecundaria(
            `${customerURL}/#/negocio/${idNegocio}/catalogo/${id}`
          );
        }}
      >
        <VisibilityIcon style={{ marginRight: 10 }} />
        Ver carta
      </MenuItem>

      <MenuItem title="QR de la Carta" onClick={_handleOpenPrint}>
        <QrCodeIcon style={{ marginRight: 10 }} />
        Imprimir QR
      </MenuItem>

      <MenuItem onClick={editar_carta} title="Editar Carta">
        <EditIcon style={{ marginRight: 10 }} />
        Editar
      </MenuItem>

      <MenuItem title="Eliminar Carta" onClick={abrir_delete}>
        <HighlightOffIcon style={{ marginRight: 10 }} />
        Eliminar
      </MenuItem>
    </Menu>
  );
  const { isDownBreakPoint } = useScreenSize({ breakPoint: 300 });
  return (
    <>
      <StyledTableRow key={values.id} id={values.id}>
        <StyledTableCell scope="row">{values.nombre}</StyledTableCell>
        {!isDownBreakPoint && (
          <StyledTableCell align="center">{values.descripcion}</StyledTableCell>
        )}

        {props.showTools && (
          <StyledTableCell align="center">{values.articulos}</StyledTableCell>
        )}
        <StyledTableCell
          align="center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={classes.tools_container}>
            <IconButton
              title={values.estado ? "Desactivar" : "Activar"}
              color="inherit"
            >
              <input id={values.id} hidden></input>
              <Switch
                checked={values.estado}
                onChange={handleChangeStatus}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </IconButton>

            <IconButton
              title="Visualizar Carta"
              onClick={() =>
                _ventanaSecundaria(
                  `${customerURL}/#/negocio/${idNegocio}/catalogo/${id}`
                )
              }
            >
              <VisibilityIcon />
            </IconButton>

            <IconButton title="QR de la Carta" onClick={_handleOpenPrint}>
              <QrCodeIcon />
            </IconButton>
            {/* <Link to={`/home/cartas/edit/${values.id}`} className="link-button"> */}
            <IconButton onClick={editar_carta} title="Editar Carta">
              <EditIcon />
            </IconButton>
            {/* </Link> */}
            <IconButton title="Eliminar Carta" onClick={abrir_delete}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <IconButton
            className={classes.menu_icon}
            aria-label="show more"
            aria-haspopup="true"
            onClick={(e) => {
              setOpenMenu(true);
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreIcon />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      {renderMenu}
      <ZoomQR
        qr={values.qr_url}
        open={openPrint}
        close={_handleClose}
        nameFile={"QR_CARTA"}
      ></ZoomQR>
    </>
  );
}
