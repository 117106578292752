import { makeStyles } from "@material-ui/core";
import { theme_menu } from "../../styles/classes";

export const useStyles = makeStyles((theme_menu) => ({
  container_spiner: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  container_loading_background: {
    backgroundColor: "#333333b5",
    height: "100%",
    width: "100%",
    left: "0",
    top: "64px",
    position: "absolute",
    zIndex: 4,
  },
}));
