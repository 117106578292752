import {
  captureErrorMessageOnSentry,
  captureInfoMessageOnSentry,
} from "./sentryMessage";

export const successMessageHandler = (response, options) => {
  let message;
  try {
    message = response.data.message.join("</br>");
  } catch (_) {
    if (options.message) {
      message = options.message;
    } else {
      message = `${options.record} ${
        options.type || "created"
      } successfully 👍!`;
    }
  }
  return message;
};

export const errorMessageHandler = (error, messageError) => {
  let message;

  console.error(error.message);
  try {
    message = error?.response.data.message;
    captureInfoMessageOnSentry(`[onMessageHandlerGeneric]:${message}`, error);
    return message;
  } catch (_) {
    if (typeof messageError === "string") {
      message = messageError;
    } else {
      message =
        error?.message ?? "Oops! Algo salio mal por favor intente mas tarde.";
    }

    captureErrorMessageOnSentry(`[onMessageHandlerGeneric]:${message}`, error);
    return "Oops! Algo salio mal por favor intente mas tarde.";
  }
};
