import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createMuiTheme } from "@material-ui/core/styles";

import Switch from "@material-ui/core/Switch";
import { ThemeProvider } from "@material-ui/styles";
import clsx from "clsx";
//menu lateral
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import Star from "@material-ui/icons/StarTwoTone";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import DashboardIcon from "@material-ui/icons/Dashboard";
//menu bar

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import Brightness4Icon from "@material-ui/icons/Brightness4";

import RoutesModulos from "../../RoutesModulos";

import "../../styles/home.css";
import CambioPassContainer from "../../containers/CambioPassContainer";
import { NavLink } from "react-router-dom";
import { theme_menu, theme_menu_dark } from "../../styles/classes";

//estilos de los elementos internos.

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    margin: "0 !important",
    padding: "0",
    overflowY: "hidden",
    position: "relative",
  },
  fondo: {
    flexGrow: 5,
    padding: "10px",
    width: "100%",
    margin: 0,
    overflowY: "scroll",
    height: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme_menu.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme_menu.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    height: "100%",
    paddingTop: theme_menu.spacing(5),
  },
  menuButton: {
    marginRight: theme_menu.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  submit: {
    padding: theme_menu.spacing(2, 0, 2),
  },
  avatarUser: {
    color: theme.palette.getContrastText("#2D9CDB"),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: "width 0.5s ease",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: "width 0.5s ease",
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#252525",
    zIndex: 100,
    transition: "width 0.5s ease",

    [theme.breakpoints.down("md")]: {
      position: "absolute",
      backgroundColor: "#252525e0",
    },
  },
  drawerOpen: {
    backgroundColor: "#252525",
    color: "#ffffff",
    width: drawerWidth,
    transition: "all 0.5s ease",
    overflowX: "hidden",

    [theme.breakpoints.down("md")]: {
      backgroundColor: "#252525f0",
    },
  },
  drawerClose: {
    backgroundColor: "#252525",
    color: "#ffffff",
    transition: "all 0.5s ease",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.only("md")]: {
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    maxHeight: 20,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  buttonDashboar: {},
  footer: {
    padding: "8px",
    width: "100%",
    backgroundColor: "rgb(150 150 150)",
    color: "white",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    bottom: 0,
    position: "fixed",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  helpLinkContainer: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    color: "white",
  },
}));
const drawerWidth = 220; //ancho del menu lateral abierto.

function HomeApp(props) {
  const [values, setValues] = React.useState({
    password: "",
    email: "",
    confirm_pass: "",
    showPassword: false,
    mensaje: "",
    disabled: false,
    apertura: false,
    mensajeAlert: "",
    tipoMensaje: "success",
    checked_theme: false,
    theme: theme_menu,
    showDark: false,
  });
  useEffect(() => {
    window.addEventListener("resize", (e) => {
      document.body.clientWidth < 1280
        ? setWindowsMobile(true)
        : setWindowsMobile(false);
    });
    return window.removeEventListener("resize", (e) => {
      document.body.clientWidth < 1280
        ? setWindowsMobile(true)
        : setWindowsMobile(false);
    });
  }, []);
  useEffect(() => {
    document.body.clientWidth < 1280 ? setOpen(false) : setOpen(true);
  }, [props]);
  const [windowsMobile, setWindowsMobile] = useState(false);
  const classes = useStyles();
  var apertura = document.body.clientWidth < 1280 ? false : true;

  const [open, setOpen] = React.useState(apertura);
  //sidemenu acciones

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange_theme = (event) => {
    setValues({
      ...values,
      showDark: !values.showDark,
      [event.target.name]: event.target.checked,
    });
  };

  //menubar
  const mobileMenuId = "primary-search-account-menu-mobile";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [openModal, setOpenModal] = useState(false);

  const _handleOpen = () => {
    handleMenuClose();
    setOpenModal(true);
  };
  const _handleCloseModal = () => {
    setOpenModal(false);
  };

  const menuId = "primary-search-account-menu";
  //menu que se despliega a tocar el cicono del usuario
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={_handleOpen}>Cambiar Contraseña</MenuItem>
      <MenuItem
        onClick={() => {
          props.cerrarSession();
        }}
      >
        Cerrar sesión
      </MenuItem>
      <MenuItem>
        <label htmlFor="checked_theme">
          <Brightness4Icon />
        </label>
        <Switch
          checked={values.checked_theme}
          onChange={handleChange_theme}
          name="checked_theme"
          id="checked_theme"
          color="primary"
        />

        <label htmlFor="checked_theme">
          {" "}
          <Brightness3Icon />
        </label>
      </MenuItem>
    </Menu>
  );
  //menu que se despliega a tocar el cicono del usuario pero en version mobile
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={_handleOpen}>Cambiar Contraseña</MenuItem>
      <MenuItem
        onClick={() => {
          props.cerrarSession();
        }}
      >
        Cerrar sesión
      </MenuItem>
      <MenuItem>
        <label htmlFor="checked_theme">
          <Brightness4Icon />
        </label>
        <Switch
          checked={values.checked_theme}
          onChange={handleChange_theme}
          name="checked_theme"
          id="checked_theme"
          color="primary"
        />

        <label htmlFor="checked_theme">
          {" "}
          <Brightness3Icon />
        </label>
      </MenuItem>
    </Menu>
  );

  return (
    <div
      id="div_todo"
      className={classes.container}
      style={{ flexDirection: "column" }}
    >
      <ThemeProvider theme={values.showDark ? theme_menu_dark : theme_menu}>
        <div id="div_todo" className={classes.container}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              <div style={{ maxHeight: 64, position: "relative" }}>
                <img
                  src="/logo_catalogy_2_trans_dark_hori.png"
                  alt="no"
                  style={{
                    transition: "all 0.5s ease",
                    height: "90px",
                    position: "relative",
                    top: "-15px",
                    display: open ? "none" : "block",
                  }}
                ></img>
              </div>

              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <IconButton
                  id="userInitialsButton"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar className={classes.purple}>{props.user}</Avatar>
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  style={{ color: "white" }}
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>

            <CambioPassContainer
              openModal={openModal}
              closeModal={_handleCloseModal}
            />
          </AppBar>

          {renderMobileMenu}
          {renderMenu}
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <div>
                <img
                  src="/logo_catalogy_2_trans_dark_hori.png"
                  alt="logo catalogy"
                  style={{
                    transition: "all 0.5s ease",
                    height: "80px",
                    left: open ? "10px" : "-100px",
                    display: !open ? "none" : "block",
                  }}
                ></img>
              </div>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeft style={{ color: "white" }} />
              </IconButton>
            </div>
            <Divider />
            <div id="btnDash">
              <List>
                <NavLink
                  exact
                  strict
                  to="/home"
                  className="list-item noactive"
                  style={{ backgroundColor: "transparent !important" }}
                >
                  {open ? (
                    <ListItem button key="Dashboard">
                      <Button
                        id="Dashboard"
                        variant="contained"
                        color="secondary"
                        // onClick={() => {
                        //   props.call_Dashboard();
                        // }}
                      >
                        <ListItemIcon>
                          <DashboardIcon style={{ color: "white" }} />{" "}
                        </ListItemIcon>
                        <ListItemText primary="Tablero" />
                      </Button>
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      key="Dashboard"
                      // onClick={() => {
                      //   props.call_Dashboard();
                      // }}
                    >
                      <Button
                        id="Dashboard"
                        variant="icon"
                        color="secondary"
                        style={{ padding: "10px" }}
                      >
                        <ListItemIcon>
                          <DashboardIcon style={{ color: "white" }} />{" "}
                        </ListItemIcon>
                      </Button>
                    </ListItem>
                  )}
                </NavLink>
              </List>
            </div>
            <Divider />
            <List>
              <NavLink to="/home/negocio" className="list-item">
                <ListItem
                  className="listItem"
                  button
                  key="Negocio"
                  title="Negocio"
                >
                  <ListItemIcon>
                    <img
                      src={"/images/icons/online-shopping.png"}
                      alt="no"
                      style={{ height: "28px" }}
                    ></img>
                  </ListItemIcon>
                  <ListItemText primary="Negocio" />
                </ListItem>
              </NavLink>
              {(props.showCategories || props.negocios.length > 0) && (
                <>
                  <NavLink to="/home/cartas" className="list-item">
                    <ListItem
                      className="listItem"
                      button
                      key="Catálogos"
                      title="Catálogos"
                    >
                      <ListItemIcon>
                        <img
                          src={"/images/icons/menu(1).png"}
                          alt="no"
                          style={{ height: "28px" }}
                        ></img>
                      </ListItemIcon>
                      <ListItemText primary="Catálogos" />
                    </ListItem>
                  </NavLink>
                  <NavLink to="/home/categorias" className="list-item">
                    <ListItem
                      className="listItem"
                      button
                      key="Categorias"
                      title="Solo miembros Prime"
                    >
                      <ListItemIcon color="disabled">
                        <img
                          src={"/images/icons/category.png"}
                          alt="no"
                          style={{ height: "28px" }}
                        ></img>
                      </ListItemIcon>
                      <ListItemText color="disabled" primary="Categorias" />
                      <Star title="Solo miembros Prime" color="primary" />
                    </ListItem>
                  </NavLink>
                  <NavLink
                    to="/home/articulos"
                    className="list-item"
                    color="primary"
                  >
                    <ListItem
                      className="listItem"
                      button
                      key="Articulos"
                      title="Solo miembros Prime"
                    >
                      {" "}
                      <ListItemIcon>
                        <img
                          src={"/images/icons/cat_Ensaladas.png"}
                          alt="no"
                          style={{ height: "28px" }}
                        ></img>
                      </ListItemIcon>
                      <ListItemText primary="Articulos" />
                      <Star title="Solo miembros Prime" color="primary" />
                    </ListItem>
                  </NavLink>
                  <ListItem
                    className="listItem"
                    button
                    key="Ordenes"
                    title="Solo miembros Prime"
                    disabled
                    onClick={() => {
                      alert("Solo miembros Prime");
                    }}
                  >
                    <ListItemIcon color="disabled">
                      <img
                        src={"/images/icons/007-restaurant.png"}
                        alt="no"
                        style={{ height: "28px" }}
                      ></img>
                    </ListItemIcon>
                    <ListItemText color="disabled" primary="Ordenes" />
                    <Star title="Solo miembros Prime" color="primary" />
                  </ListItem>
                </>
              )}
            </List>

            <NavLink
              to="/home/help"
              className="list-item"
              style={{ position: "absolute", bottom: 0, width: "100%" }}
            >
              <ListItem className="listItem" button key="Contact">
                <ListItemIcon>
                  <img
                    src={"/images/icons/036-chef.png"}
                    alt="no"
                    style={{ height: "28px" }}
                  ></img>
                </ListItemIcon>
                <ListItemText primary="Contacto" />
              </ListItem>
            </NavLink>
          </Drawer>
          <div
            className={classes.fondo}
            style={
              values.showDark
                ? { backgroundColor: "#252525" }
                : { backgroundColor: "#ffffff" }
            }
          >
            <div className={classes.toolbar}></div>
            {props.loaging ? (
              <LinearProgress
                id="loading"
                color="secondary"
                style={{
                  display: "block",
                  height: "8px",
                  position: "fixed",
                  top: "61px",
                  width: "110%",
                  left: "-5px",
                }}
              />
            ) : (
              <LinearProgress
                id="loading"
                color="secondary"
                style={{
                  display: "none",
                  height: "8px",
                  position: "fixed",
                  top: "61px",
                  width: "110%",
                  left: "-5px",
                }}
              />
            )}
            {props.hasError ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>Algo salio mal, por favor recargue la pagina.😩</h1>
              </div>
            ) : (
              !props.loading && <RoutesModulos />
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <span style={{ textAlign: "center" }}>
            ¿Alguna duda o problema?{" "}
            <Link
              color="inherit"
              href="/home/help"
              className={classes.linkHelp}
            >
              <b> Contactanos </b>{" "}
            </Link>
            o envia un correo a <b>contact@catalogyapp.com</b>
          </span>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default HomeApp;
