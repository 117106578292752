import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import { useStyles } from "../../styles/classes";
import RowCategoria from "./RowCategoria";
import { useScreenSize } from "../../helpers/useScreenSize";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
      width: 20,
    },
  },
  body: {
    maxHeight: "100px",
    fontSize: 14,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}))(TableRow);
const TableCategorias = ({ categorias, ...props }) => {
  const [showTools, setShowTools] = React.useState(false);
  const classes = useStyles();

  const _editarCategoria = (values) => {
    props.editar(values);
  };
  const { isDownBreakPoint } = useScreenSize(400);
  return (
    <Grid style={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Nombre</StyledTableCell>
              {!isDownBreakPoint && (
                <StyledTableCell align="center">Descripcion</StyledTableCell>
              )}
              <StyledTableCell align="center" width="200px">
                Tools
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categorias.map((row, index) => (
              <RowCategoria
                key={row.id + row.name + index + row.status}
                {...row}
                editar={_editarCategoria}
                openAlert={(id) => {
                  props.openAlert(id);
                }}
                editarEstado={(values) => props.editarEstado(values)}
                showTools={showTools}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TableCategorias;
