import React, { useEffect, useState } from "react";

import mApi from "../services/api";
import { clearToken, setUser, clearUser } from "../initializers/actions";
import { connect } from "react-redux";
import Negocio from "../components/Negocio/index";

import states from "../data/states.json";
import cities from "../data/cities.json";
import monedas from "../data/currency.json";
import tipoNegocio from "../data/tipoNegocio.json";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { errorMessageHandler } from "../helpers/messageHandler";

const NegocioContainer = (props) => {
  const [state, setState] = useState({
    username: "",
    token: "",
    pais: "1",
    provincia: "1",
    ciudad: "1",
    provincias: [],
    ciudades: [],
    tiposRest: tipoNegocio.tipos,
    negocios: [],
    alertOpen: false,
    textMensaje: "",
    tipoAlert: "error",
    image: "",
    url_image: "",
    monedas: [],
    moneda: "",
  });

  const [negocio, setNegocio] = useState({
    name: "",
    rubro: "",
    tipo: "",
    direccion: "",
    telefono: "",
    telefono2: "",
    web: "",
    correo: "",
    twitter: "",
    instagram: "",
    facebook: "",
    id: "",
    pais: "",
    provincia: "",
    ciudad: "",
    url_image: "",
    monedas: [],
    moneda: "",
  });

  const [loading, setLoading] = useState(false);
  // }

  useEffect(() => {
    if (!props.token) {
      props.history.push("/login");
    } else {
      const serializedData = props.user;
      let negocios = JSON.parse(serializedData).negocios;
      if (negocios[0]) {
        let Negocio = negocios[0];

        const { ...rest } = Negocio;
        for (let attr in Negocio) {
          setState({ ...state, [attr]: Negocio[attr] });
        }

        setNegocio({ ...state, ...rest });

        const provinciasFiltradas = states.states.filter(
          (estado) => estado.id_country === Negocio.pais
        );
        const ciudadesFiltradas = cities.cities.filter(
          (c) => c.id_state === Negocio.provincia
        );

        setState({
          ...state,
          provincias: provinciasFiltradas,
          pais: Negocio.pais,
          ciudades: ciudadesFiltradas,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleSubmit = (data) => {
    //  submit logic
    setLoading(true);
    const serializedData = props.user;
    let negocios = JSON.parse(serializedData).negocios;
    let data_form = new FormData(document.getElementById("form"));
    for (const attr in data) {
      if (!data_form.has(attr)) data_form.append(attr, data[attr]);
    }

    let method = "post";
    let url = "negocios";
    if (negocios[0]) {
      if (negocios[0].id) {
        method = "put";
        url = "negocios/" + negocios[0].id;
      }
    }

    let config = {
      method: method,
      url: url,
      withCredentials: false,

      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-tokens": props.token,
        Accept: "*/*",
      },
      data: data_form,
    };

    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          let negocioresp = response.data[0].Negocio;

          let user = { user: props.user.user, negocios: [negocioresp] };
          props.setUser(JSON.stringify(user));

          setState({ ...state, id: negocioresp.id });
          setLoading(false);
          setState({
            ...state,
            alertOpen: true,
            textMensaje: "Actualizado satisfactoriamente!",
            tipoAlert: "success",
          });
          //window.location.replace("/home");
          // for (let attr in negocioresp) {
          //   setNegocio({ ...state, [attr]: negocioresp.attr });
          //   // setState({ ...state, [attr]:negocioresp.attr})
          // }
          // setLoading(false);
        } else {
          if (response.status === 413) {
            setState({
              ...state,
              alertOpen: true,
              textMensaje: "¡Imagen muy grande!, elija una menos pesada.",
              tipoAlert: "error",
            });
            setLoading(false);
          } else
            setState({
              ...state,
              alertOpen: true,
              textMensaje: "¡Error Actualizando!",
              tipoAlert: "error",
            });
          setLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        if (e.response && e.response.code === 413) {
          setState({
            ...state,
            alertOpen: true,
            textMensaje:
              "¡Imagenes demasiado grandes, intente con otra imagen!",
            tipoAlert: "error",
          });
        } else {
          setState({
            ...state,
            alertOpen: true,
            textMensaje: errorMessageHandler(e),
            tipoAlert: "error",
          });
        }

        setLoading(false);
      });
  };

  const filtrarProvincia = React.useCallback(
    (id) => {
      const provinciasFiltradas = states.states.filter(
        (estado) => estado.id_country === id
      );

      setState({ ...state, provincias: provinciasFiltradas, pais: id });
    },
    [state]
  );
  const filtrarCiudad = (id) => {
    const ciudadesFiltradas = cities.cities.filter((c) => c.id_state === id);
    setState({ ...state, provincia: id, ciudades: ciudadesFiltradas });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ alertOpen: false });
  };
  const {
    pais,
    provincia,
    ciudad,
    ciudades,
    tiposRest,
    backAction,
    url_image,
    provincias,
  } = state;

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: "#333333b5",
            height: "100vh",
            width: "110%",
            left: "-10%",
            top: "64px",
            position: "absolute",
            zIndex: 2,
          }}
        >
          <LinearProgress id="loading" color="secondary" />
        </div>
      ) : (
        ""
      )}
      <Negocio
        pais={pais}
        provincia={provincia}
        ciudad={ciudad}
        filtrarProvincia={filtrarProvincia}
        filtrarCiudad={filtrarCiudad}
        provincias={provincias}
        ciudades={ciudades}
        tiposNeg={tiposRest}
        handleSubmit={handleSubmit}
        negocio={negocio}
        loading={loading}
        // alertOpen={alertOpen}
        backButton={backAction}
        url_image={url_image}
        monedas={monedas.monedas}
      />

      <Snackbar
        open={state.alertOpen}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={state.tipoAlert}
        >
          {state.textMensaje}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(NegocioContainer);
