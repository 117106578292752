import React from "react";
import OlvideContraseña from "../components/OlvideContraseña";
import { SnackBar } from "../components/SnackBar";
import { errorMessageHandler } from "../helpers/messageHandler";
import mApi from "../services/api";

const ResetPassword = () => {
  const [values, setValues] = React.useState({
    textMensaje: "",
    tipoAlert: "success",

    openSnack: false,
  });

  const [loading, setLoading] = React.useState(false);

  const closeSnack = () => {
    setValues({
      ...values,
      openSnack: false,
    });
  };
  const _submit = (email) => {
    setLoading(true);
    const url = "/reset";

    let data = JSON.stringify({
      email: email,
    });
    mApi({
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    })
      .then((respuesta) => {
        setLoading(false);

        var mensaje = respuesta;

        if (mensaje !== "") {
          setValues({
            ...values,
            textMensaje: mensaje.data.message,
            tipoAlert: mensaje.data.type,
            openSnack: true,
          });
        } else {
          setValues({
            ...values,
            textMensaje: "Upss, ocurrio un error inesperado, intente luego.",
            tipoAlert: "success",
            openSnack: true,
          });
        }
      })
      .catch((e) => {
        console.error("error catch:" + e);
        setLoading(false);
        setValues({
          ...values,
          textMensaje: errorMessageHandler(e),
          tipoAlert: "error",

          openSnack: true,
        });
      });
  };

  return (
    <>
      <SnackBar
        open={values.openSnack}
        textMensaje={values.textMensaje}
        tipoAlert={values.tipoAlert}
        close={closeSnack}
      />
      <OlvideContraseña submitFunction={_submit} loading={loading} />
    </>
  );
};

export default ResetPassword;
