import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { SimpleCard } from "../SimpleCard/index";
import QRious from "qrious";
import "../../styles/dashboard.css";
import { CardMessageDashboard } from "../CardMessageDashboard";
import { DataNegocioCard } from "../DataNegocioCard";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useStyles } from "./styles";
import ModalArticuloContainer from "../../containers/ModalArticuloContainer";

import paises from "../../data/countries.json";
import states from "../../data/states.json";
import ZoomQR from "../ZoomQR";
import { SaveAlt } from "@material-ui/icons";
import { customerURL } from "../../helpers/constants";

export const Dashboard = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [openPrint, setOpenQR] = React.useState(false);
  const [qrNegocio, setQR] = React.useState(null);
  useEffect(() => {
    _createQRNegocio();
  }, [props.negocio]);
  const _createQRNegocio = () => {
    const { negocio } = props;
    let qr = new QRious({
      element: document.getElementById("qrcode"),
      value: `${customerURL}/#/negocio/${negocio.id}`,
      size: 200,
      mime: "image/png",
    });

    setQR(qr);
  };
  const _handleOpen = () => {
    setOpenQR(true);
  };
  const _handleClose = () => {
    setOpenQR(false);
  };
  const {
    tieneNegocio,
    tieneCartas,
    username,
    negocio,
    linkNewCarta,
    linkNegocio,
  } = props;
  const linkToCartas = () => {
    props.linkCartas();
  };
  const linkToArticulos = () => {
    history.push("/home/articulos/");
  };
  const _renderCardMessage = () => {
    let titulo = `Hola ${username}!`,
      subtitulo = "Bienvenid@ a Catalogy.",
      parrafo_1 = `Aqui estara tu tablero de control, desde aqui podras gestionar
      todo, tus catálogos, articulos, y mucho mas.`,
      parrafo_2 = `Lo primero que debes hacer es registrar los datos de tu negocio para
      poder configurar tus catálogos.`,
      textoBtn = "Registrar Negocio",
      callbackClickBtn = () => {
        linkNegocio();
      };

    if (tieneNegocio) {
      titulo = `¡Crea tu primer Catálogo!`;
      subtitulo = "";
      parrafo_1 = `¡Perfecto!, ya que registraste tu negocio y todos los datos que tus clientes necesitan para encontrarte, esta list@ para crear tu primer catalogo.`;
      parrafo_2 = `Dirigite al modulo de Catálogos, o sigue el siguiente boton para empezar a crear catalogos geniales.`;
      textoBtn = "Crear Catalogo";
      callbackClickBtn = () => {
        _handleNewCarta();
      };
    }
    if (tieneCartas) {
      titulo = `¡Visita tu Pagina!`;
      subtitulo = "";
      parrafo_1 = `¡Listo!, ya tienes presencia en la web, y puedes compartirla en todas las Redes Sociales..`;
      parrafo_2 = `${customerURL}/#/negocio/${negocio.id}`;
      textoBtn = "Ir a pagina";
      callbackClickBtn = () => {
        window.open(`${customerURL}/#/negocio/${negocio.id}`, "_blank");
      };
    }
    const obj = {
      titulo,
      subtitulo,
      parrafo_1,
      parrafo_2,
      textoBtn,
      callbackClickBtn,
    };

    return <CardMessageDashboard {...obj} />;
  };

  const _handleNewCarta = () => {
    linkNewCarta();
  };

  const [openNewArticulo, setOpen] = React.useState(false);

  const _getFullDirection = () => {
    const pais = paises.countries.filter((pais) => pais.id === negocio.pais);
    const provincia = states.states.filter(
      (state) => state.id === negocio.provincia
    );

    return `${pais[0] && pais[0].name}, ${provincia[0].name}, ${
      negocio.direccion
    }`;
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: tieneNegocio ? 30 : 15,
      }}
    >
      {tieneNegocio && <Grid container className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={tieneNegocio ? 6 : 12}
          className={classes.header}
        >
          <Typography className="tituloDash"> Tablero</Typography>
          <Typography className="subtituloDash">
            {" "}
            Crea, edita, elimina y controla todo, en un solo lugar.
          </Typography>
        </Grid>

        <Grid item md={6} className={classes.dataNegocio}>
            <DataNegocioCard
              name={negocio.name}
              rubro={negocio.rubro}
              tipo={negocio.tipo}
              phone={negocio.telefono}
              phone2={negocio.telefono2}
              address={_getFullDirection()}
              url_image={negocio.url_image}
              callbackEdit={() => {
                linkNegocio();
              }}
            ></DataNegocioCard>
          </Grid>
      </Grid>}
      {tieneNegocio && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          <SimpleCard
            titulo="Catálogos"
            icon="images/icons/006-suggestion.png"
            descripcion="Gestione catálogos a su gusto."
            cantidad={props.cantCart}
            link={linkToCartas}
            clickNew={_handleNewCarta}
          />
          <SimpleCard
            titulo="Categorias"
            icon="images/icons/category.png"
            descripcion="Use categorias para agrupar."
            cantidad={props.cantCat}
            link={() => history.push("/home/categorias/")}
            clickNew={() => history.push("/home/categorias/new")}
          />
          <SimpleCard
            titulo="Articulos"
            icon="images/icons/cat_Ensaladas.png"
            descripcion="Agregue y elimine articulos."
            link={linkToArticulos}
            cantidad={props.cantArt}
            clickNew={() => setOpen(true)}
          />
        </div>
      )}

      <div
        className={classes.divInfomarcion}
        style={{ paddingTop: tieneNegocio ? 30 : 8 }}
      >
        {_renderCardMessage()}

        {tieneCartas && (
          <div className={classes.divQR}>
            <div className={classes.cardQR}>
              <IconButton
                onClick={_handleOpen}
                color="secondary"
                style={{
                  margin: "0",
                  padding: 0,
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                {" "}
                <SaveAlt style={{ color: "white" }} />{" "}
              </IconButton>

              <Typography className={classes.tituloScan}>
                QR del negocio
              </Typography>
              <IconButton
                onClick={_handleOpen}
                style={{ margin: "0", padding: 0 }}
              >
                <canvas id="qrcode"></canvas>
              </IconButton>
            </div>
          </div>
        )}
      </div>

      {openNewArticulo && (
        <ModalArticuloContainer
          open={openNewArticulo}
          guardarArticulo={() => {
            props.update_data();
            setOpen(false);
          }}
          cancelarArticulo={() => {
            setOpen(false);
          }}
          articulo={{}}
        ></ModalArticuloContainer>
      )}
      {openPrint && (
        <ZoomQR
          qr={qrNegocio.toDataURL()}
          open={openPrint}
          close={_handleClose}
          nameFile={"QR_CARTA"}
        ></ZoomQR>
      )}
    </div>
  );
};
