import React, { useState } from "react";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import { SnackBar } from "../SnackBar";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  texbox: {
    backgroundColor: theme.palette.primary.texbox,
    width: "30%",
    minWidth: 150,
    margin: 1,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  containerDIv: {
    paddingBottom: 5,
    display: "flex",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    minWidth: "250px",
  },
  submit: {
    padding: "5px",
    margin: "1px",
    minWidth: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export const FormChangePass = (props) => {
  const classes = useStyles();
  // const { submit, closeModal } = props;

  const [values, setValues] = useState({
    password: "",
    newpassword: "",
    confirmPass: "",
    valid: false,
    textMensaje: props.textMensaje,
    open: props.open,
    tipoAlert: props.tipoAlert,
    close: props.close,
  });

  const { password, newpassword, confirmPass } = values;
  const _handleSubmit = () => {
    if (_validarIguales()) {
      setValues({
        ...values,
        textMensaje: "¡Elija una nueva contraseña.!",
        open: true,
        tipoAlert: "warning",
      });
      return;
    }
    props.submit(values);
    setValues({ ...values, password: "", newpassword: "", confirmPass: "" });
  };

  useEffect(() => {
    //actualzia el snackbar con datos del padre
    setValues({
      ...values,
      textMensaje: props.textMensaje,
      open: props.open,
      tipoAlert: props.tipoAlert,
      close: props.close,
    });
  }, [props.open]);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const _handleClose = () => {
    props.closeModal();
    setValues({ ...values, password: "", newpassword: "", confirmPass: "" });
  };
  const _validarIguales = () => values.password === values.newpassword;

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  //crear validacion personalizada para comparar los password. se usa ValidatorForm
  ValidatorForm.addValidationRule("isPassMatch", (value) => {
    if (value !== values.newpassword) {
      return false;
    }
    return true;
  });
  return (
    <>
      <ValidatorForm onSubmit={_handleSubmit} id="form_art">
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              required
              fullWidth
              type={values.showPassword ? "text" : "password"}
              // className={classes.texbox}
              id="password"
              label="Contraseña Actual"
              variant="outlined"
              color="primary"
              value={values.password}
              onChange={handleChange("password")}
              name="password"
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.textField}
            >
              <InputLabel
                htmlFor="newpassword"
                style={{
                  backgroundColor: "white",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                Nueva Contraseña *
              </InputLabel>
              <OutlinedInput
                fullWidth
                variant="outlined"
                required
                name="newpassword"
                id="newpassword"
                type={values.showPassword ? "text" : "password"}
                value={values.newpassword}
                onChange={handleChange("newpassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={(e) => e.stopPropagation()}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              className={classes.textField}
              variant="outlined"
              type={values.showPassword ? "text" : "password"}
              required
              fullWidth
              id="confirmPass"
              label="Confirmar Contraseña"
              name="confirmPass"
              onChange={handleChange("confirmPass")}
              validators={["isPassMatch", "required"]}
              errorMessages={["Contraseñas no coinciden", "Campo requerido"]}
              value={values.confirmPass}
            />
          </Grid>
          <div className={classes.containerDIv}>
            {!props.cargando && (
              <Grid md={6} className={classes.submit} item>
                <Button
                  id="button_cancelar"
                  // type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ padding: "10px" }}
                  onClick={_handleClose}
                >
                  Cancelar
                </Button>
              </Grid>
            )}

            <Grid md={6} className={classes.submit} item>
              <Button
                id="button_submit"
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                style={{ padding: "10px" }}
                disabled={
                  props.cargando ||
                  password === "" ||
                  newpassword === "" ||
                  confirmPass === ""
                }
              >
                {props.cargando ? (
                  <CircularProgress color="secondary" size={"1.5rem"} />
                ) : (
                  <SaveIcon style={{ marginRigth: "10px" }} />
                )}
                {!props.cargando && "Guardar"}
              </Button>
            </Grid>
          </div>
        </Grid>
        <SnackBar
          textMensaje={values.textMensaje}
          open={values.open}
          tipoAlert={values.tipoAlert}
          close={props.close}
        ></SnackBar>
      </ValidatorForm>
    </>
  );
};
