import { makeStyles } from "@material-ui/core";
import { theme_menu as theme } from "../../styles/classes";
const useStyles = makeStyles(() => {
  return {
    tools_container: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(880)]: {
        display: "none ",
      },
    },

    menu_icon: {
      display: "none",
      width: "100%",
      justifyContent: "center",
      [theme.breakpoints.down(880)]: {
        display: "flex",
      },
    },
    hide: {
      display: "none",
      [theme.breakpoints.up(880)]: {
        display: "block",
      },
    },
    noWrap: {
      // -ms-text-size-adjust: initial;
      wordWrap: "break-word;",
      flexWrap: "nowrap",
      textOverflow: "ellipsis !important",

      textAlign: "justify",
    },
    linkButton: {
      textDecoration: "none !important;",

      width: "100%",
    },
    row: {
      "&:hover": {
        backgroundColor: theme.palette.primary.light + "!important",
        cursor: "pointer",
        animation: "",
      },
    },
  };
});
export default useStyles;
