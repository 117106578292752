import axios from "axios";
import config from "./env";

const mApi = axios.create({
  baseURL: config.APIURL,
  timeout: 60000,
  withCredentials: false,
});

if (process.env.REACT_APP_ENV === "local") {
  window.api = mApi;
}

export default mApi;
