import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10px",
    height: "100%",
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10px",
    marginBottom: "16px",
    height: "80px",
    flexDirection: "column",
    height: "100%",
    position: "relative",
  },
  div_steps: {},
  divInfomarcion: {
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "baseline",
    paddingTop: "80px",
    paddingBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: "10px",
    },
  },
  divMensaje: {
    display: "flex",
    height: "100%",
    minHeight: "500px",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px",
    minWidth: "400px",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "80px",
      padding: "15px 0px",
    },
  },
  dataNegocio: {
    position: "relative",
    top: 0,
    right: 0,

    paddingRight: "20px",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "20px",
    },
  },
  divQR: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  cardQR: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "18px",
    width: "230px",
    height: "270px",

    color: "white",
  },
  tituloScan: {
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    mixBlendMode: "normal;",
    padding: "10px 0px 10px 0px",
    textShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25);",
  },
  imagenQR: {
    margin: "5px",
    width: "190px",
    height: "190px",
    backgroundColor: "#56CCF2",
    // borderRadius: "15px",
    boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.24);",
    // padding: "5px",
  },
}));
