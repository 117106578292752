import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },

  card_message_dashboard: {
    padding: '16px',
    margin: "16px 10px",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "500px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "25px",
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
      minWidth: "100px !important",
      maxWidth: "600px",
      margin: "10px 10px",
    },
  },
  card_content: {
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 25,
    color: "white",
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
    },
  },
  subtitle: {
    marginTop: 0,
    paddingTop: 0,

    marginBottom: 15,
    fontSize: 25,
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
    },
  },
  parrafo_1: {
    fontSize: 20,
    margin: 10,
    color: "rgba(255, 255, 255, 0.7);",
    mixBlendBode: "normal",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    alignItems: "center;",
    textAlign: "center;",
    letterSpacing: "0.75px;",
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px !important",
    },
  },
  action_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  button_action: {
    padding: 15,
    background: "#56CCF2 !important",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px;",
    fontWeight: "bold",
    margin: 10,
    color: "white",
  },
}));
