import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

export const CardMessageDashboard = (props) => {
  const {
    titulo = "Titulo Sensacional",
    subtitulo,
    parrafo_1,
    parrafo_2,
    textoBtn,
    callbackClickBtn,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Card
        elevation={5}
        // style={{ minWidth: "100px" }}
        className={classes.card_message_dashboard}
      >
        <CardContent className={classes.card_content}>
          <Typography className={classes.title} color="textPrimary">
            {titulo}
          </Typography>

          <Typography className={classes.subtitle} color="textPrimary">
            {subtitulo}
          </Typography>
          <Typography
            variant="inherit"
            className={classes.parrafo_1}
            component="p"
          >
            {parrafo_1}
          </Typography>
          <Typography
            variant="inherit"
            className={classes.parrafo_1}
            component="p"
          >
            {parrafo_2}
          </Typography>
        </CardContent>
        <CardActions className={classes.action_container}>
          <Button className={classes.button_action} onClick={callbackClickBtn}>
            {textoBtn}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
