import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";

const SearchInput = ({ value, onChange }) => {
  return (
    <div
      style={{
        paddingBottom: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <TextField
        size="small"
        color="primary"
        placeholder="Filtrar"
        type="text"
        // value={value}
        onChange={onChange}
        name="articuloFilter"
        style={{
          width: "70%",
          maxWidth: "400px",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search color="primary" style={{ color: "grey" }} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchInput;
