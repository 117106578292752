import { makeStyles } from "@material-ui/core/styles";
import { theme_menu } from "../../styles/classes";
export const useStyles = makeStyles((theme) => ({
  paper_log: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    margin: theme_menu.spacing(1),
    backgroundColor: theme_menu.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    height: "100%",
    paddingTop: theme_menu.spacing(3),
  },
  menuButton: {
    marginRight: theme_menu.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  submit: {
    padding: theme_menu.spacing(2, 0, 2),
    //backgroundColor: theme.palette.primary.main
  },
  menubar: {
    // backgroundColor: theme.palette.primary.main
  },
  logo: {
    paddingTop: theme_menu.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
