import React from "react";

import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import { MensajeTypografico } from "../MensajeTypografico";
import { useStyles } from "../../styles/classes";
import RowTable from "./RowTable";
import { Add } from "@material-ui/icons";
import SearchInput from "./SearchInput";
import { useScreenSize } from "../../helpers/useScreenSize";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
      width: 20,
    },
  },
  body: {
    maxHeight: "100px",
    fontSize: 14,
    [theme.breakpoints.down(400)]: {
      padding: "2px !important",
    },
  },
}))(TableCell);

export const TableArticulos = ({
  articulos = [],
  loading,
  filterArticulos,
  articulosAll,
  ...props
}) => {
  const [showTools, setShowTools] = React.useState(false);

  const classes = useStyles();
  const [inputSearch, setInputSearch] = React.useState("");
  const _handlefilter = (e) => {
    const valueInput = e.target.value;
    filterArticulos(valueInput);
    setInputSearch(valueInput);
  };
  const [Alignbutton, setAlign] = React.useState({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  });
  React.useEffect(() => {
    if (articulos && articulos.length) {
      setAlign({
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
      });
    } else {
      setAlign({
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      });
    }
  }, [articulos]);

  React.useEffect(() => {
    if (document.body.clientWidth < 880) setShowTools(false);
    else setShowTools(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.body.clientWidth, props]);

  const _editarArticulo = (values) => {
    props.editar(values);
  };

  const { isDownBreakPoint } = useScreenSize();
  const _renderTable = (articulos) => {
    if (!articulosAll.length) return "";
    return (
      <Grid item>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {!isDownBreakPoint && (
                  <StyledTableCell align="center">Imagen</StyledTableCell>
                )}

                <StyledTableCell align="center">Nombre</StyledTableCell>
                {showTools && (
                  <StyledTableCell align="center">Precio</StyledTableCell>
                )}
                {showTools && (
                  <StyledTableCell align="center" style={{ maxWidth: "200px" }}>
                    Descripcion
                  </StyledTableCell>
                )}
                <StyledTableCell align="center" width="200px">
                  Tools
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articulos.map((row, index) => (
                <RowTable
                  key={row.id + row.name + index}
                  {...row}
                  editar={_editarArticulo}
                  openAlert={(id) => {
                    props.openAlert(id);
                  }}
                  editarEstado={(value) => props.editarEstado(value)}
                  showTools={showTools}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const _renderNewMessage = (articulos) => {
    if (articulosAll.length) return "";
    return (
      <Grid item>
        <MensajeTypografico
          texto={
            <span>
              ¡Aqui puedes gestionar tus articulos. <b>¡Vamos! Crea uno!</b>{" "}
            </span>
          }
        />
      </Grid>
    );
  };
  return (
    <div className={classes.section}>
      <Paper className={classes.paper} elevation={4} style={{ padding: 10 }}>
        <Grid
          container
          spacing={2}
          direction="column"
          justify="space-around"
          className={classes.containerDIv}
        >
          <div className={classes.containerDIv} style={Alignbutton}>
            <Link to="/home/articulos/new" className="list-item">
              <Button
                id="button_new_carta"
                type="button"
                variant="contained"
                className={classes.buttonNew}
                color="primary"
              >
                <Add />
                Nuevo Articulo
              </Button>
            </Link>
          </div>

          {!loading && articulosAll.length ? (
            <SearchInput value={inputSearch} onChange={_handlefilter} />
          ) : (
            ""
          )}

          {!loading ? _renderTable(articulos) : ""}
          {!loading ? _renderNewMessage(articulos) : ""}
        </Grid>

        {loading ? (
          <div
            style={{
              padding: " 30px",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={40} />
          </div>
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
};
