import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";

import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import ImagenIcono from "@material-ui/icons/ImageSearch";

const useStyles = makeStyles({
  root: {
    marginBottom: "30px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "column",
    // maxWidth: "300px",
    minWidth: "245px",
  },
});

export default function LogoCard(props) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    imagenCargada: props.image,
    nombreImagen: props.nombreImagen,
    mostrarImagen: true,
    default: true,
    excedeTamaño: false,
    tamañoMaximo: !props.tamañoMaximo && 20,
  });
  useEffect(() => {
    setValues({ ...values, imagenCargada: props.image, mostrarImagen: true });
  }, [props.image]);
  const _handleChangeImage = (e) => {
    const reader = new FileReader();

    if (e.target.files[0].size >= values.tamañoMaximo * 1000000) {
      // props.onChangeIma(e);
      setValues({ ...values, excedeTamaño: true, imagenCargada: "" });
      props.onChangeIma({ target: { files: [""] } });
      return;
    }
    reader.onload = () => {
      if (reader.readyState === 2) {
        setValues({
          ...values,
          imagenCargada: reader.result,
          mostrarImagen: true,
          default: true,
          excedeTamaño: false,
        });
      }
    };

    setValues({ ...values, nombreImagen: e.target.files[0].name });
    reader.readAsDataURL(e.target.files[0]);

    props.onChangeIma(e);
  };
  const { showButton, size = 150 } = props;
  return (
    <div className={classes.root}>
      {values.imagenCargada ? (
        <Card elevation={4}>
          <CardActionArea>
            <CardMedia
              id="imagen_src"
              component="img"
              alt={props.nombreImagen}
              width={size}
              height={size}
              image={values.imagenCargada}
              title={props.nombreImagen}
              onClick={() => {
                values.fileInput.click();
              }}
            />
          </CardActionArea>
        </Card>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            htmlFor={`button-file-${props.nombreImagen}`}
            style={{ margin: "20px" }}
          >
            <Button
              color="primary"
              variant="contained"
              component="span"
              className={classes.button}
            >
              {" "}
              <ImagenIcono />
            </Button>
          </label>
          {values.excedeTamaño ? (
            "Imagen supera el tamaño maximo permitido."
          ) : (
            <span> Seleccione la imagen</span>
          )}
        </div>
      )}

      <input
        ref={(fileInput) => {
          values.fileInput = fileInput;
        }}
        accept="image/*"
        className={classes.input}
        style={{ display: "none" }}
        id={`button-file-${props.nombreImagen}`}
        multiple
        type="file"
        onChange={_handleChangeImage}
      />
      <span style={{ color: "grey", fontSize: "12px" }}>
        Tamaño maximo de imagen de {values.tamañoMaximo} Mb
      </span>
      {showButton && values.imagenCargada && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            htmlFor={`button-file-${props.nombreImagen}`}
            style={{ margin: "20px" }}
          >
            <Button
              color="primary"
              variant="contained"
              component="span"
              className={classes.button}
            >
              {" "}
              <ImagenIcono />
            </Button>
          </label>
          {values.excedeTamaño ? (
            "Imagen supera el tamaño maximo permitido."
          ) : (
            <span> Seleccione la imagen</span>
          )}
        </div>
      )}
    </div>
  );
}
