import React, { useState, useRef } from "react";
import { useIntersection } from "../../helpers/useIntersectionObserver";

const LazyLoadComponent = ({ children, loadingComponent, ...rest }) => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  return (
    <div {...rest} ref={imgRef}>
      {isInView ? children : loadingComponent}
    </div>
  );
};

export default LazyLoadComponent;
