import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";

import SaveIcon from "@material-ui/icons/Save";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useStyles } from "./styles";

export const CategoriasForm = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: props.name,
    id: props.id,
    description: props.description,
    status: props.status ?? true,
  });
  const handleChangeSwitch = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };
  const handleNotFocusText = (prop) => (event) => {
    let value = event.target.value.trim();

    setValues({
      ...values,
      [prop]: value,
    });
  };
  const handleSubmit = () => {
    props.submit(values);
  };
  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      id="form_cat"
      className={classes.form}
    >
      <Grid item xs={6} md={6} sm={6} style={{ padding: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={values.status}
              onChange={handleChangeSwitch}
              name="status"
              color="primary"
            />
          }
          label={values.status ? "Activa" : "Inactiva"}
        />
      </Grid>
      <Grid grid>
        <Grid item></Grid>
      </Grid>
      <Grid container align="center" style={{ paddingRight: "5px" }}>
        <Grid item xs={12} md={12} sm={12} style={{ padding: "5px" }}>
          <TextField
            required
            className={classes.texbox}
            id="cat_name"
            placeholder="Pizzas"
            label="Nombre"
            variant="outlined"
            color="primary"
            value={values.name}
            onChange={handleChange("name")}
            name="cat_name"
            title="Nombre Articulo"
            focused
            onBlur={handleNotFocusText("name")}
          />
        </Grid>
        <Grid item xs={12} md={12} sm={12} style={{ padding: "5px" }}>
          <TextField
            className={classes.texbox}
            id="cat_description"
            multiline
            rows={4}
            placeholder="Contiene las pizzas tradicionales hechas a piedra"
            label="Descripcion"
            variant="outlined"
            color="primary"
            value={values.description}
            onChange={handleChange("description")}
            name="description"
            title="Descripcion"
            onBlur={handleNotFocusText("description")}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} md={6} sm={12} className={classes.containerButtons}>
          <Button
            id="button_cancelar"
            fullWidth
            variant="contained"
            className={classes.buttonBigSecondary}
            onClick={props.closeModal}
            disabled={props.loading}
            color="primary"
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} md={6} sm={12} className={classes.containerButtons}>
          <Button
            id="button_submit"
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.buttonBig}
            disabled={props.loading}
          >
            {props.loading ? (
              <CircularProgress color="secondary" size={"1.5rem"} />
            ) : (
              <SaveIcon style={{ marginRigth: "10px" }} />
            )}
            {!props.loading ? (
              "Guardar"
            ) : (
              <span style={{ paddingLeft: "10px" }}>Guardando...</span>
            )}
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};
