import React, { Component } from "react";
import HomeApp from "../components/HomeApp/index";
import LogRocket from "logrocket";
import mApi from "../services/api";
//redux
import { clearToken, setUser, clearUser } from "../initializers/actions";
import { connect } from "react-redux";
import { errorMessageHandler } from "../helpers/messageHandler";

class HomeAppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      token: "",
      content: "",
      initials: "AL",
      loading: false,
      newCartas: false,
      showCategories: false,
      hasError: false,
    };
  }
  _clickDashboardButton = () => {
    this.setState({ content: this.rendercontent, token: this.props.token });
    this.props.history.push("/home");
  };

  _clickNegocioButton = () => {
    this.setState({ content: this.renderNegocio });
    this.setState({ loading: false });
    this.props.history.push("/home/negocio");
  };

  _clickCartasButton = () => {
    this.setState({ loading: false, newCartas: false });
    this.setState({ loading: false });

    this.props.history.push("/home/cartas");
  };

  _getDataUserFromServer() {
    this.setState({ loading: true });
    let url = "user";

    mApi({
      method: "get",
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": this.props.token,
      },
    })
      .then((response) => {
        let mensaje = response;

        if (mensaje !== "") {
          const nombre = response.data.User.name.toUpperCase();
          const apellido = response.data.User.lastname.toUpperCase();
          const NameIni = nombre.charAt(0);
          const ApeIni = apellido.charAt(0);
          const username = nombre + " " + apellido;

          LogRocket.identify(response.data.User.public_id, {
            name: username,
            email: response.data.User.email,

            // Add your own custom user variables here, ie:
            subscriptionType: "basic",
          });

          this.setState({ username: username, initials: NameIni + ApeIni });

          this.request_data_negocio();
        } else {
          this.props.clearToken();
          this.props.clearUser();
          this.props.history.push("/login");
        }
      })
      .catch((e) => {
        this.props.clearToken();
        this.props.clearUser();
        this.setState({ loading: false });
        console.error(e);
        errorMessageHandler(e);
        this.props.history.push("/login");
      });
  }

  request_data_negocio() {
    var config = {
      method: "get",
      url: "negocios",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": this.props.token,
        Accept: "*/*",
      },
    };
    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.Negocios.length > 0) {
            let Negocios = response.data.Negocios;

            let user = { user: this.state.username, negocios: Negocios };

            this.props.setUser(JSON.stringify(user));
            Negocios.length > 0 && this.setState({ showCategories: true });
          } else {
            let user = { user: this.state.username, negocios: [] };
            this.props.setUser(JSON.stringify(user));
          }
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.error(e);
        errorMessageHandler(e);
        this.props.history.push("/login");
      });
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // También puedes registrar el error en un servicio de reporte de errores
    console.error(error, errorInfo);
    errorMessageHandler(error);
  }

  componentDidMount() {
    this.setState({ content: this.rendercontent, token: this.props.token });

    if (this.props.token) {
      this._getDataUserFromServer();
    } else {
      this.props.history.push("/login");
    }
  }

  _cerrarSession = () => {
    this.props.clearToken();
    this.props.clearUser();
    this.props.history.push("/login");
  };

  render() {
    const { initials, content, loading, showCategories } = this.state;

    return (
      <HomeApp
        hasError={this.state.hasError}
        user={initials}
        contenido={content}
        _changeContent={this._handleClickMenu}
        call_Dashboard={this._clickDashboardButton}
        call_Negocio={this._clickNegocioButton}
        call_Cartas={this._clickCartasButton}
        loaging={loading}
        cerrarSession={this._cerrarSession}
        showCategories={showCategories}
        loading={this.state.loading}
        negocios={
          JSON.parse(this.props.user)
            ? JSON.parse(this.props.user).negocios
            : []
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeAppContainer);
