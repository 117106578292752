import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import NegocioContainer from "./containers/NegocioContainer";

import dashboardContainer from "./containers/dashboardContainer";

import ArticulosContainer from "./containers/ArticulosContainer";
import CartasContainer from "./containers/CartasContainer";
import CategoriasContainer from "./containers/CategoriasContainer";

const RoutesModulos = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/home/negocio" component={NegocioContainer}></Route>
        <Route path="/home/cartas">
          <CartasContainer />
        </Route>
        <Route path="/home/categorias">
          <CategoriasContainer />
        </Route>

        <Route path="/home/articulos">
          <ArticulosContainer />
        </Route>
        <Route
          path="/home/help"
          component={() => (
            <div style={{ padding: "10px" }}>
              <iframe
                src="https://forms.gle/7cqumiMz5batGJtDA"
                width="100%"
                height="1161"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >
                Loading…
              </iframe>
            </div>
          )}
        />
        <Route exact path="/home" component={dashboardContainer} />

        <Route path="*">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </div>
  );
};

export default RoutesModulos;
