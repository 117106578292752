import React from "react";
import ImageIcon from "@material-ui/icons/Image";

const NoImage = () => (
  <div
    style={{
      width: "40px",
      height: "40px",

      backgroundSize: "cover",

      overflow: "hidden;",
      borderRadius: "7px;",
    }}
  >
    <ImageIcon fontSize="large" color="disabled" />
  </div>
);

export default NoImage;
