import React, { useEffect } from "react";
import SingUp from "../components/SingUp";
import { connect } from "react-redux";
import mApi from "../services/api";
import { SnackBar } from "../components/SnackBar";
import { errorMessageHandler } from "../helpers/messageHandler";
const RegistrarContainer = (props) => {
  useEffect(() => {
    if (props.token) {
      props.history.push("/home");
    }
  });

  const [values, setValues] = React.useState({
    textMensaje: "",
    tipoAlert: "success",
    cargando: false,
    openSnack: false,
  });

  const _handleSubmit = ({
    name: nombre,
    lastname: apellido,
    email,
    password,
  }) => {
    setValues({ ...values, loading: true });
    mApi
      .post("register", {
        name: nombre,
        lastname: apellido,
        email: email,
        password: password,
      })
      .then((respuesta) => {
        setValues({ ...values, loading: false });

        let mensaje =
          "Cuenta creada satisfactoriamente, ha sido enviado un correo de confirmacion!";
        const { status } = respuesta;
        switch (status) {
          case 201:
            setValues({
              ...values,
              openSnack: true,
              tipoAlert: "success",
              textMensaje: mensaje,
            });
            setTimeout(() => {
              props.history.push("/login");
            }, 3000);
            break;
          case 202:
            mensaje =
              "Cuenta creada satisfactoriamente, ya puede acceder desde el login.";
            setValues({
              ...values,
              openSnack: true,
              tipoAlert: "success",
              textMensaje: mensaje,
            });
            setTimeout(() => {
              props.history.push("/login");
            }, 3000);
            break;
          default:
            setValues({
              ...values,
              openSnack: true,
              tipoAlert: "error",
              textMensaje: respuesta.data.message,
              loading: false,
            });
            break;
        }
      })
      .catch((e) => {
        console.error("error:" + e);

        setValues({
          ...values,
          loading: false,
          openSnack: true,
          tipoAlert: "error",
          textMensaje: errorMessageHandler(e),
        });
      });
  };

  const closeSnack = () => {
    setValues({
      ...values,
      openSnack: false,
    });
  };

  return (
    <div>
      <SnackBar
        open={values.openSnack}
        textMensaje={values.textMensaje}
        tipoAlert={values.tipoAlert}
        close={closeSnack}
      />
      <SingUp submit={_handleSubmit} loading={values.loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps)(RegistrarContainer);
