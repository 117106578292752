import React, { useRef } from "react";

import UbicacionBar from "../components/UbicacionBar";
import { Route, Switch, withRouter } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { clearToken, setUser, clearUser } from "../initializers/actions";

import { TableArticulos } from "../components/TableArticulos";

import DialogAlert from "../components/DialogAlert";
import mApi from "../services/api";
import { SnackBar } from "../components/SnackBar";

import ModalArticuloContainer from "./ModalArticuloContainer";
import { errorMessageHandler } from "../helpers/messageHandler";
const ArticulosContainer = (props) => {
  const [articulos, setArticulos] = React.useState([]);
  const [articulosAll, setArticulosAll] = React.useState([]);
  const [articulo, setArticulo] = React.useState({});
  const [open_alert, toogleAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackBar] = React.useState({
    message: "",
    open: false,
    type: "error",
  });

  const { articulo_id } = useParams();
  let mounted = useRef();
  let history = useHistory();
  React.useEffect(() => {
    const { user, token } = props;
    if (!token || !user || token === null || user === "") {
      props.history.push("/login");
      return;
    }

    const negocios = JSON.parse(user)?.negocios;

    if (negocios.length === 0) {
      props.history.push("/home");
      return;
    }
  });
  React.useEffect(() => {
    mounted.current = true;
    _getArticulos();
    return () => {
      mounted.current = false;
    };
  }, []);
  const _editarArticulo = (values) => {
    setArticulo(values);

    props.history.push("/home/articulos/" + values.id);
  };
  const _eliminarArticulo = () => {
    const id = articulo.id;
    const { user, token } = props;
    const negocios = JSON.parse(user).negocios;
    const listaArticulos = articulos.filter((item) => {
      return item.id !== id;
    });
    setArticulos(listaArticulos);
    setArticulosAll(listaArticulos);
    let method = "delete";
    let url = `negocios/${negocios[0].id}/articulos/${id}`;

    const config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "x-access-tokens": token,
        Accept: "*/*",
      },
    };
    setArticulo({});
    toogleAlert(false);
    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          setSnackBar({
            ...snackbar,
            message: response.data.message,
            open: true,
            type: "success",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setSnackBar({
          ...snackbar,
          message: errorMessageHandler(e),
          open: true,
          type: "error",
        });
        setArticulo({});
        toogleAlert(false);
      });
  };
  const _cancelEdit = () => {
    history.push("/home/articulos/");
    setArticulo({});
  };
  const _getArticulos = () => {
    const { user, token } = props;
    if (!token || !user || token === null || user === "") {
      props.history.push("/login");
      return;
    }

    const negocios = JSON.parse(user)?.negocios;
    let method = "get";
    let url = `negocios/${negocios[0].id}/articulos`;
    setLoading(true);
    const config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "x-access-tokens": token,
        Accept: "*/*",
      },
    };
    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          const {
            data: { Articulos },
          } = response;

          mounted.current && setArticulos(Articulos);
          mounted.current && setArticulosAll(Articulos);
          if (articulo_id) {
            const listaArticulos = Articulos.filter((item) => {
              return item.id === articulo_id;
            });
            mounted.current && setArticulo(listaArticulos[0]);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        errorMessageHandler(e);
      });
  };
  const _submitArticulo = (value) => {
    if (!articulos.find((item) => item.id === value.id)) {
      const newArray = articulos;
      newArray.unshift(value);
      setArticulos(newArray);
    } else {
      let articulosfiltrados = articulosAll;

      const indexArticulo = articulos.findIndex((item) => item.id === value.id);

      articulosfiltrados.splice(indexArticulo, 1);

      articulosfiltrados.unshift(value);

      setArticulos(articulosfiltrados);
    }
  };
  const _openAlert = (id) => {
    setArticulo({ ...articulo, id });
    toogleAlert(true);
  };
  const _handlefilter = (inputSearch) => {
    const filterArticles = articulosAll.filter((item) =>
      item.name.toLowerCase().includes(inputSearch.toLowerCase())
    );
    setArticulos(filterArticles);
  };
  const _editarEstado = (values) => {
    let id = values.id;
    const serializedData = props.user;
    let negocios = JSON.parse(serializedData).negocios;

    let negocio_id = null;
    if (typeof negocios[0].id != "undefined") {
      let Negocio = negocios[0];

      negocio_id = Negocio.id;
    }
    let data_form = new FormData();
    data_form.append("estado", values.estado);

    let method = "put";

    let url = `negocios/${negocio_id}/articulos/${id}`;

    let config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": props.token,
        Accept: "*/*",
      },
      data: data_form,
    };

    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          let mensaje = "¡Articulo editado satisfactoriamente!";
          setSnackBar({
            open: true,
            type: "success",
            message: mensaje,
          });
          mounted.current && setLoading(false);
        } else {
          setSnackBar({
            open: true,
            type: "error",
            message: "¡Error Editando.Favor intente de nuevo mas tarde!",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setSnackBar({
          open: true,
          type: "error",
          message: errorMessageHandler(e),
        });
        setSnackBar(false);
      });
  };
  return (
    <>
      {" "}
      <UbicacionBar modulo="Articulos" />
      <SnackBar
        textMensaje={snackbar.message}
        open={snackbar.open}
        tipoAlert={snackbar.tipoAlert}
        close={() => setSnackBar({ ...snackbar, open: false })}
      ></SnackBar>{" "}
      <TableArticulos
        filterArticulos={_handlefilter}
        loading={loading}
        articulos={articulos}
        editar={_editarArticulo}
        openAlert={_openAlert}
        editarEstado={_editarEstado}
        articulosAll={articulosAll}
      />
      <>
        <Switch>
          <Route path="/home/articulos/new">
            <ModalArticuloContainer
              articulo={{}}
              open={true}
              guardarArticulo={_submitArticulo}
              cancelarArticulo={_cancelEdit}
            />
          </Route>
          <Route path="/home/articulos/:articulo_id">
            {" "}
            <ModalArticuloContainer
              articulo={articulo}
              open={true}
              cancelarArticulo={_cancelEdit}
              guardarArticulo={_submitArticulo}
            />
          </Route>
        </Switch>

        <DialogAlert
          open={open_alert}
          aceptar={_eliminarArticulo}
          cerrar_modal={() => toogleAlert(false)}
          titulo="¡Cuidado!"
          texto="Tenga presente que esta accion es irreversible y afectara todas las cartas que contengan el articulo, ¿Desea eliminar?"
        />
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticulosContainer)
);
