import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";

import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import SaveIcon from "@material-ui/icons/Save";
import NavigateBeforeIcon from "@material-ui/icons/Cancel";

import TextField from "@material-ui/core/TextField";
import CardCategoria from "../CardCategoria";

import Switch from "@material-ui/core/Switch";
import currency from "../../data/currency.json";
import Sortable from "sortablejs";

import CircularProgress from "@material-ui/core/CircularProgress";
import { LimpiarCampoTextoEspacios } from "../../utils";
import { ValidatorForm } from "react-material-ui-form-validator";

import { useStyles } from "./styles";
import { Link, Prompt } from "react-router-dom";
import { Fab, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ModalCategoriaContainer from "../../containers/ModalCategoriaContainer";
import { theme_menu } from "../../styles/classes";
import { useBeforeUnload } from "../../helpers/useBeforeUnload";
import useTour from "../../helpers/useTour";
import { Help } from "@material-ui/icons";

function NewCarta(props) {
  const [values, setValues] = useState({
    estado: props.estado ?? true,
    name: props.name,
    description: props.description,
    categorias_selecionadas: [{}],
    items: [],
    articulosSelectionados: [],
    articulosdisponibles: props.articulos_disponibles,
    alertOpen: false,
    textMensaje: "",
    tipoAlert: "error",
    cargando: props.cargando,
    articulos: [],
  });

  const [steps, setSteps] = useState([
    {
      target: "#cartaName",
      content:
        "¡Ingresa el nombre que deseas para tu catálogo! Ejemplo: 'Menu Bluebar'",
      locale: { next: "Siguiente" },
    },
    {
      target: "#inputDescription",
      content:
        "Agrega una descripción de tu catálogo para ayudar a sus clientes con dificultades visuales. Ejemplo: 'Nuestra carta es una fusion de comida japonesa y peruana'",

      locale: { back: "Atras", next: "Siguiente" },
    },
    {
      target: "#addCategory",
      content:
        "Presione para crear una nueva categoria y agregarla al catálogo.",
      locale: { back: "Atras", next: "Siguiente" },
    },

    {
      target: ".checkboxCategory",
      content:
        "Aqui se activa o desactiva la categoria, para que sea visible en el catálogo.",
      locale: { back: "Atras", next: "Siguiente" },
    },
    {
      target: ".addArticulo",
      content: "Presione para crear un nuevo artículo.",
      locale: { back: "Atras", next: "Siguiente" },
    },
    {
      target: "#card_categories",
      content:
        "Agrega los articulos que necesite a las diferentes categorías en esta lista, aquí es donde se forma el orden con que se mostrará el catálogo a tus clientes.",
      locale: { back: "Atras", next: "Siguiente" },
    },
    {
      target: "#button_save_carta",
      content:
        "Guarda los cambios y podrás ir a la pantalla con la lista de catálogos creados.",
      locale: { back: "Atras", last: "Terminar" },
    },
  ]);
  const [symbol, setSymbol] = useState("$");
  useEffect(() => {
    ListaCategoria();
  }, []);
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador

    setValues({
      ...values,
      estado: props.estado ?? true,
      name: props.name,
      description: props.description,
    });
    let inputname = document.getElementById("cartaName");
    inputname.value = props.name;
    inputname.focus();
    let inputDescription = document.getElementById("inputDescription");
    inputDescription.value = props.description;
  }, [props.name]);

  useEffect(() => {
    const { user } = props;
    const negocios = JSON.parse(user) ? JSON.parse(user).negocios : [];

    const symbol = currency.monedas.filter(
      (moneda) => moneda.code === negocios[0]?.moneda
    )[0]?.symbol;

    setSymbol(symbol);
  }, [props.user]);
  //funcion para hacer la lista dragable
  const ListaCategoria = () => {
    const lista = document.getElementById("lista_categorias");

    Sortable.create(lista, {
      group: "lista_Categorias",
      animation: 500,
      easing: "cubic-bezier(0.22, 1, 0.36, 1)",
      handle: ".iconDrop",
      direction: "vertical",
      removeCloneOnHide: true,
      onUpdate: function (/**Event*/ evt) {
        // same properties as onEnd
      },
      dragClass: ".dragItem",
    });
  };

  function enviar_datos(params) {
    LimpiarCampoTextoEspacios(values);
    onSubmit({
      name: values.name.trim(),
      description: values.description.trim(),
      estado: values.estado,
    });
  }

  const { onSubmit } = props;
  const classes = useStyles(theme_menu);
  const [openNewCategoria, setOpenNewCat] = useState(false);
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    setCategorias(props.categorias);
  }, [props.categorias]);

  const handleChangeStatus = () => {
    setValues({ ...values, estado: !values.estado });
  };
  const handleClickNewCategoria = () => {
    setOpenNewCat(true);
  };
  const _submitCategoria = (value) => {
    const categoriasNews = categorias;
    categoriasNews.unshift({ ...value, enabled: true });
    setCategorias(categoriasNews);
    setOpenNewCat(false);
  };

  const _cancelEdit = () => {
    setOpenNewCat(false);
  };
  const handleChange = (prop) => (event) => {
    let value = event.target.value;

    setValues({
      ...values,
      [prop]: value,
    });
  };

  const handleNotFocusText = (prop) => (event) => {
    let value = event.target.value.trim();

    setValues({
      ...values,
      [prop]: value,
    });
  };

  const isValuesChanged = React.useMemo(() => {
    let hasChanges = false;
    Object.entries(props).forEach(([key, value]) => {
      if (values[key] && values[key] !== value) {
        hasChanges = true;
      }
    });
    return hasChanges;
  }, [props, values]);

  useBeforeUnload({
    when: isValuesChanged && !props.cargando,
    message: "Are you sure you want to leave?",
  });
  const { tour, handleRunTour } = useTour({
    steps,
    localStorageKey: "TOUR_CATALOG",
  });
  return (
    <div className={classes.root}>
      {props.cargando ? (
        <div className={classes.div_loading}>
          <CircularProgress />
        </div>
      ) : (
        ""
      )}

      <CssBaseline />
      {tour}
      <Prompt
        when={isValuesChanged && !props.cargando}
        message="Tienes cambios sin guardar, ¿Seguro deseas dejar la pagina?"
      />
      <ValidatorForm href="#" onSubmit={enviar_datos}>
        <Paper className={classes.paper} style={{ padding: 5 }}>
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <IconButton
                title="Activa el tour explicativo"
                onClick={handleRunTour}
                style={{ padding: "0 2px " }}
              >
                <Help />
              </IconButton>
              <div style={{ marginLeft: 20 }}>
                <Switch
                  checked={values.estado}
                  onChange={handleChangeStatus}
                  name="sw_estatus"
                  id="sw_estatus"
                  color="primary"
                />
                <label htmlFor="sw_estatus">
                  {values.estado ? "Activo" : "Inactivo"}
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: 10,
                marginRight: 10,
                position: "relative",
              }}
            >
              {/* <IconButton id="button_cancel_carta" href="/home/cartas">
                <CancelIcon style={{ color: "red" }} />
              </IconButton> */}
              <Link to="/home/cartas" style={{ textDecoration: "none" }}>
                <Button
                  id="button_cancel_carta"
                  variant="contained"
                  onClick={props.clickBack}
                  className={classes.button_previo}
                  style={{ marginLeft: 5 }}
                >
                  <NavigateBeforeIcon />
                  {document.body.clientWidth > 400 && "Cancelar"}
                </Button>
              </Link>
              <Button
                id="button_save_carta"
                variant="contained"
                type="submit"
                color="secondary"
                //
                // onClick={enviar_datos}
                className={classes.button_next}
              >
                <SaveIcon />
                {document.body.clientWidth > 400 && "Guardar"}
              </Button>
            </div>
          </Grid>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            className={classes.containerDIv}
            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
          >
            <Grid item xs={12} md={6} style={{ padding: 10 }}>
              <TextField
                label="Nombre del catálogo"
                type="text"
                // value={name}
                // onChange={(e) => handleChangeName(e)}
                name="cartaName"
                className=".cartaName"
                style={{ width: "100%" }}
                variant="outlined"
                id="cartaName"
                onBlur={handleNotFocusText("name")}
                required
                autoFocus
                defaultValue={props.value}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 10 }}>
              <TextField
                className=".inputDescription"
                variant="outlined"
                style={{ width: "100%" }}
                label="Descripción"
                type="text"
                onBlur={handleNotFocusText("description")}
                name="inputDescription"
                id="inputDescription"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </ValidatorForm>
      <Paper style={{ padding: 5, minheight: "70vh", maxHeight: "90vh" }}>
        <div className={classes.container_list}>
          <Paper
            style={{
              marginBottom: 10,
              backgroundColor: "#ffffc1",
              color: "grey",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4 style={{ marginLeft: 5, padding: 0, margin: 4 }}>
              Cree una nueva categoria (presionando el botón +) o active las que
              necesite, luego agregue nuevos artículos o arrastre entre las
              listas si tiene artículos disponibles creados previamente.
            </h4>
          </Paper>
          <Grid container direction="row" justify="space-around" spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Card id="card_categories">
                <CardHeader
                  color="secondary"
                  className={classes.CardHeader}
                  title={
                    <h5
                      style={{
                        color: "white",
                      }}
                    >
                      Categorias
                    </h5>
                  }
                  action={
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <Fab
                        absolute
                        color="secondary"
                        onClick={handleClickNewCategoria}
                        size="small"
                        title="Crear nueva categoria"
                        id="addCategory"
                      >
                        <AddIcon />
                      </Fab>
                      <ModalCategoriaContainer
                        submitCat={_submitCategoria}
                        categoria={{}}
                        openModal={openNewCategoria}
                        closeModal={_cancelEdit}
                        articulos={[]}
                      />
                    </div>
                  }
                ></CardHeader>

                <ul id="lista_categorias" className={classes.lista_scroll}>
                  {categorias.map((value) => {
                    let estado = false;
                    if (value.articulos?.length > 0 || value.enabled) {
                      estado = true;
                    }
                    let icon = "/images/icons/cat_" + value.name + ".png";
                    return (
                      <li
                        className={"dragItem"}
                        style={{ margin: 10 }}
                        color="lightgrey"
                        key={value.id}
                        role={undefined}
                        name={`cat_${value.name}`}
                        id={value.id}
                        cat_nombre={value.name}
                        imagen={value.icon}
                      >
                        <CardCategoria
                          token={props.token}
                          negocio_id={props.negocio_id}
                          estado={estado}
                          titulo={value.name}
                          imagen={document.body.clientWidth > 400 && icon}
                          articulos={value.articulos}
                          seleccionable={true}
                          isNew={typeof props.id === "undefined"}
                        />
                      </li>
                    );
                  })}
                </ul>

                {categorias.length < 1 && (
                  <ul className={classes.lista_scroll}>
                    <li
                      style={{ margin: 10 }}
                      color="lightgrey"
                      role={undefined}
                    >
                      <CardCategoria
                        token={props.token}
                        negocio_id={props.negocio_id}
                        estado={false}
                        titulo={
                          "Categoria de ejemplo, no se incluira en el catálogo"
                        }
                        articulos={[]}
                        seleccionable={true}
                        isNew={true}
                      />
                    </li>
                  </ul>
                )}
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className={classes.articulos_disponibles}
            >
              <CardCategoria
                token={props.token}
                negocio_id={props.negocio_id}
                estado={true}
                titulo="Articulos Disponibles"
                imagen=""
                articulos={props.articulos_disponibles}
                seleccionable={false}
                symbol={symbol}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(NewCarta);
