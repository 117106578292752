import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

export const theme_menu_dark = createMuiTheme({
  palette: {
    primary: {
      main: "#2d2d2d",
      dark: "#5e5e5e",
      backgroundCard: "#484848",
      label: "white",
    },
    secondary: {
      main: "#5e5e5e",
      dark: "#5e5e5e",
    },
    type: "dark",
  },
});
export const theme_menu = createMuiTheme({
  grey: "#bfbfbf",
  palette: {
    primary: {
      main: "#2d2d2d",
      dark: "#252525",
      cards: "#D1E7FF",
      textbox: "#E3F8FF",
      backgroundCard: "#dadada",
      label: "black",
      light: "#5050505c",
    },
    secondary: {
      main: "#0083AD",
      dark: "#35C0ED",
      backgroundCard: "#dadada",
    },
    type: "light",
  },
});
export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    margin: "0 !important",
    padding: "0",
    overflowY: "hidden",
    posirtion: "relative",
  },
  fondo: {
    flexGrow: 5,
    padding: "10px",
    width: "100%",
    margin: 0,
    overflowY: "scroll",
    height: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: theme_menu.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme_menu.spacing(10),
    marginBottom: theme_menu.spacing(10),
  },
  avatar: {
    margin: theme_menu.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    height: "100%",
    paddingTop: theme_menu.spacing(5),
  },
  menuButton: {
    marginRight: theme_menu.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  submit: {
    padding: theme_menu.spacing(2, 0, 2),
  },
  buttonLogin: {
    color: "white",
  },
  avatarUser: {
    color: theme.palette.getContrastText("#2D9CDB"),
  },
  appBar: {
    backgroundColor: theme_menu.palette.main,
  },
  cardPrimary: {
    backgroundColor: theme_menu.palette.main + "!important",
  },

  hide: {
    display: "none",
  },
  texbox: {
    marginBottom: theme_menu.spacing(2),
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme_menu.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paperDirectionBar: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.primary,

    margin: theme.spacing(1),
    marginBottom: "15px",
  },
  buttonNew: {
    padding: theme.spacing(1, 1, 1),

    margin: 5,
    marginTop: "10px",
  },
  containerDIv: {
    paddingBottom: 10,
    // [theme.breakpoints.down('md')]: {
    //     display: 'flex',
    // },
  },
  section: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));
