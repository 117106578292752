import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { clearToken, setUser, clearUser } from "../initializers/actions";
import { connect } from "react-redux";
import mApi from "../services/api";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import NewCarta from "../components/Carta";
import { errorMessageHandler } from "../helpers/messageHandler";

class CartaNewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      categorias: [],
      contentRender: "",
      articulos_disponibles: [],
      new: false,
      cargando: false,
      categorias_seleccionadas: [],
      name: "",
      description: "",
      id: this.props.match && this.props.match.params.carta_id,
      negocio_id: null,
      estado: null,
      alertOpen: false,
      tipoAlert: "error",
      textMensaje: "",
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // También puedes registrar el error en un servicio de reporte de errores
    console.error(error, errorInfo);
    errorMessageHandler(error);
  }

  _clickBack = () => {
    this.setState({ new: false });
    this.props.history.push("/home/cartas");
  };

  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.alertOpen = false;
    this.setState({ alertOpen: false });
  };
  DeterminarCatSeleccionadas = () => {
    const lista = document.getElementById("lista_categorias");
    let elementos = lista.querySelectorAll("li.dragItem");

    let cats = [];
    let position = 0;
    for (let item of elementos) {
      position += 1;
      let checks = item.getElementsByTagName("input");

      let seleccionada = checks[0].checked;

      let ids_Articulos = [];
      if (seleccionada === "true" || seleccionada === true) {
        let articulos = item.getElementsByClassName("dragItem_articulos");

        let positionArt = 0;
        for (let art of articulos) {
          positionArt += 1;
          ids_Articulos.push({ id: art.id, position: positionArt });
        }
        cats = this.state.categorias_seleccionadas;
        if (articulos.length > 0) {
          cats.push({
            id: item.id,
            articulos: ids_Articulos,
            position: position,
          });
          this.setState({ categorias_seleccionadas: cats });
        }
      }
    }
  };
  onSubmit = (values) => {
    const serializedData = this.props.user;
    let negocios = JSON.parse(serializedData).negocios;

    let negocio_id = 0;
    if (typeof negocios[0].id != "undefined") {
      let Negocio = negocios[0];

      negocio_id = Negocio.id;
    }
    this.DeterminarCatSeleccionadas();

    let objData = {
      name: values.name,
      description: values.description,
      negocio_id: negocio_id,
      categorias: this.state.categorias_seleccionadas,
      estado: values.estado,
    };

    if (objData.name === "") {
      // alert("debe elegir almenos un articulo y categoria.");
      this.setState({
        alertOpen: true,
        tipoAlert: "warning",
        textMensaje: "Debe llenar los campos obligaroios. (*)",
        categorias_seleccionadas: [],
      });
      return false;
    }
    if (objData.description === "") {
      objData.description = "N/A";
    }
    if (this.state.categorias_seleccionadas.length === 0) {
      // alert("debe elegir almenos un articulo y categoria.");
      this.setState({
        alertOpen: true,
        tipoAlert: "warning",
        textMensaje: "Debe elegir almenos un articulo y categoria.",
      });
      return;
    }

    this.setState({ cargando: true });
    let method = "post";
    let url = `negocios/${negocio_id}/cartas`;
    if (this.state.id != null) {
      method = "put";
      url = `negocios/${negocio_id}/cartas/${this.state.id}`;
    }

    let config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": this.props.token,
        Accept: "*/*",
      },
      data: objData,
    };

    mApi(config)
      .then((response) => {
        if (response.status === 201) {
          let mensaje = "¡Carta creada satisfactoriamente!";
          if (method === "put") {
            mensaje = "¡Carta editada satisfactoriamente!";
          }
          this.setState({
            alertOpen: true,
            tipoAlert: "success",
            textMensaje: mensaje,
          });
          this.props.history.push("/home/cartas");
        } else {
          this.setState({
            alertOpen: true,
            tipoAlert: "error",
            textMensaje: `¡Error Guardando!${response.data.Carta}`,
          });
        }
      })
      .catch((e) => {
        console.error(e);

        this.setState({
          cargando: false,
          alertOpen: true,
          tipoAlert: "error",
          textMensaje: errorMessageHandler(e),
        });
        setTimeout(() => this.props.history.push("/home/cartas"), 3000);
      });
    this.setState({ categorias_seleccionadas: [] });
  };
  getArticulos = (url) =>
    new Promise((resolve) => {
      mApi({
        method: "get",
        url: url,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": this.props.token,
        },
      })
        .then((response) => {
          let mensaje = response;

          if (mensaje !== "") {
            let lista = mensaje.data.Articulos;
            this.setState({ articulos_disponibles: lista });
            resolve(lista);
          }
          // this.setState({ cargando: false });
        })
        .catch((e) => {
          // this.setState({ cargando: false });

          console.error(e);
          errorMessageHandler(e);
        });
    });
  getCategorias = (url) =>
    new Promise((resolve) => {
      mApi({
        method: "get",
        url: url,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": this.props.token,
        },
      })
        .then((response) => {
          let mensaje = response;

          if (mensaje !== "") {
            const listCats = mensaje.data.Categorias?.filter(
              (c) => c.status === true
            );
            resolve(listCats);
            this.setState({ categorias: listCats });
          }

          // this.setState({ cargando: false });
        })
        .catch((e) => {
          // this.setState({ cargando: false });
          console.error(e);
          errorMessageHandler(e);
        });
    });
  getDatos = async (negocio_id) => {
    this.setState({ cargando: true });
    let url = `negocios/${negocio_id}/categorias`;

    await this.getCategorias(url);

    url = `negocios/${negocio_id}/articulos`;
    await this.getArticulos(url);

    this.setState({ cargando: false });
  };
  getDetalleCarta = (negocio_id, id_carta) => {
    let url = `negocios/${negocio_id}/cartas/` + id_carta;
    let Carta = {};
    mApi({
      method: "get",
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": this.props.token,
      },
    })
      .then((response) => {
        let mensaje = response;

        Carta = mensaje.data.Carta;
        let cats = Carta.categorias;
        let lista = Carta.arts_disponibles;
        this.setState({
          id: Carta.id,
          articulos_disponibles: lista,
          categorias: cats,
          name: Carta.name,
          description: Carta.description,
          estado: Carta.estado,
        });
        this.setState({ cargando: false });
        // return Carta;
      })
      .catch((e) => {
        this.setState({ cargando: false });
        console.error(e);
        errorMessageHandler(e);
      });
    return Carta;
  };
  componentDidMount() {
    if (!this.props.token) {
      this.props.history.push("/login");
      return;
    }
    this.setState({ cargando: true });

    const serializedData = this.props.user;
    let negocios = JSON.parse(serializedData).negocios;

    this.setState({ negocio_id: negocios[0].id });
    let id_carta = this.props.match && this.props.match.params.carta_id;
    if (typeof id_carta == "undefined") {
      this.setState({ new: true });

      if (typeof negocios[0].id != "undefined") {
        this.getDatos(negocios[0].id);
      }
    } else {
      this.setState({ new: false });
      this.getDetalleCarta(negocios[0].id, id_carta);
    }
  }
  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz de repuesto
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>Algo salio mal, por favor recargue la pagina.😩</h1>
        </div>
      );
    }
    return (
      <>
        {this.state.cargando ? (
          <LinearProgress id="loading" color="secondary" />
        ) : (
          ""
        )}

        <NewCarta
          clickBack={this._clickBack}
          onSubmit={this.onSubmit}
          token={this.props.token}
          negocio_id={this.state.negocio_id}
          name={this.state.name}
          description={this.state.description}
          categorias={this.state.categorias}
          articulos_disponibles={this.state.articulos_disponibles}
          estado={this.state.estado}
          id={this.state.id}
          cargando={this.state.cargando}
          new={this.state.new}
        />
        <Snackbar
          open={this.state.alertOpen}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({ alertOpen: false });
          }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => {
              this.setState({ alertOpen: false });
            }}
            severity={this.state.tipoAlert}
          >
            {this.state.textMensaje}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartaNewContainer)
);
