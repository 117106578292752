import React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Redirect, Route, Switch, withRouter } from "react-router";
import { clearToken, setUser, clearUser } from "../initializers/actions";
import { SnackBar } from "../components/SnackBar";
import Ubicacionbar from "../components/UbicacionBar";

import DialogAlert from "../components/DialogAlert";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";

import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import SearchInput from "../components/TableArticulos/SearchInput";
import TableCategorias from "../components/TableCategorias";
import { useStyles } from "../styles/classes";
import mApi from "../services/api";
import ModalCategoriaContainer from "./ModalCategoriaContainer";
import { MensajeTypografico } from "../components/MensajeTypografico";
import { errorMessageHandler } from "../helpers/messageHandler";

const CatergoriaContainer = (props) => {
  const classes = useStyles();
  const { categoria_id } = useParams();
  let history = useHistory();
  const [snackbar, setSnackBar] = React.useState({
    message: "",
    open: false,
    type: "error",
  });
  const [Alignbutton, setAlign] = React.useState({
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  });

  const [inputSearch, setInputSearch] = React.useState("");
  const [open_alert, toogleAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [categorias, setCategorias] = React.useState([]);
  const [categoriasAll, setCatAll] = React.useState([]);
  const [categoria, setCategoria] = React.useState({
    name: "",
    description: "",
    id: null,
    status: true,
  });
  React.useEffect(() => {
    if (categoriasAll?.length) {
      setAlign({
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
      });
    } else {
      setAlign({
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      });
    }
  }, [categoriasAll]);

  React.useEffect(() => {
    const { user, token } = props;
    if (!token || !user || token === null || user === "") {
      props.history.push("/login");
      return;
    }

    const negocios = JSON.parse(user).negocios;

    if (negocios.length === 0) {
      props.history.push("/home");
      return;
    }
    _getCategorias();
  }, []);

  const _getCategorias = () => {
    const { user, token } = props;
    if (!token || !user || token === null || user === "") {
      props.history.push("/login");
      return;
    }
    const negocios = JSON.parse(user).negocios;
    let method = "get";
    let url = `negocios/${negocios[0].id}/categorias`;
    setLoading(true);
    const config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "x-access-tokens": token,
        Accept: "*/*",
      },
    };
    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          const {
            data: { Categorias },
          } = response;

          setCategorias(Categorias);
          setCatAll(Categorias);

          if (categoria_id) {
            const listaCategorias = categorias.filter((item) => {
              return item.id === categoria_id;
            });
            setCategoria(listaCategorias[0]);
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setSnackBar({
          ...snackbar,
          message: errorMessageHandler(e),
          open: true,
          type: "error",
        });
        setLoading(false);
      });
  };
  const _deleteCategoria = () => {
    const id = categoria.id;
    const { user, token } = props;
    const negocios = JSON.parse(user).negocios;
    const listanueva = categorias.filter((item) => {
      return item.id !== id;
    });
    setCategorias(listanueva);
    setCatAll(listanueva);
    // setArticulosAll(listaArticulos);
    let method = "delete";
    let url = `negocios/${negocios[0].id}/categorias/${id}`;

    const config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "x-access-tokens": token,
        Accept: "*/*",
      },
    };
    setCategoria({
      name: "",
      description: "",
      id: null,
      status: true,
    });
    toogleAlert(false);
    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          setSnackBar({
            ...snackbar,
            message: response.data.message,
            open: true,
            type: "success",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setSnackBar({
          ...snackbar,
          message: errorMessageHandler(e),
          open: true,
          type: "error",
        });
        setCategoria({
          name: "",
          description: "",
          id: null,
          status: true,
        });
        toogleAlert(false);
      });
  };

  const _handlefilter = (e) => {
    const valueInput = e.target.value;

    // setInputSearch(valueInput);
    const filteItems = categoriasAll.filter((item) =>
      item.name.toLowerCase().includes(valueInput.toLowerCase())
    );
    setCategorias(filteItems);
  };

  const _editarEstado = (values) => {
    let id = values.id;
    const serializedData = props.user;
    let negocios = JSON.parse(serializedData).negocios;

    let negocio_id = null;
    if (typeof negocios[0].id != "undefined") {
      let Negocio = negocios[0];

      negocio_id = Negocio.id;
    }
    let data_form = new FormData();
    data_form.append("estado", values.status);

    let method = "put";

    let url = `negocios/${negocio_id}/categorias/${id}/status`;

    let config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": props.token,
        Accept: "*/*",
      },
      data: data_form,
    };

    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          let mensaje = "Categoria editada satisfactoriamente!";
          setSnackBar({
            open: true,
            type: "success",
            message: mensaje,
          });
          setLoading(false);
          setCategoria(response.data.Categoria);
          // _submitCategoria(response.data.Categoria);
          // _getCategorias();
        } else {
          setSnackBar({
            open: true,
            type: "error",
            message:
              response.data?.message || "Error editando, intente mas tarde.",
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setSnackBar({
          open: true,
          type: "error",
          message: errorMessageHandler(e),
        });
      });
  };
  const _submitCategoria = (value) => {
    if (!categorias.find((item) => item.id === value.id)) {
      const newArray = categorias;
      newArray.unshift(value);
      setCategorias(newArray);
      setCatAll(newArray);
    } else {
      let categoriasFiltradas = categorias;

      const index = categorias.findIndex((item) => item.id === value.id);

      categoriasFiltradas.splice(index, 1);

      categoriasFiltradas.unshift(value);

      setCategorias(categoriasFiltradas);
      setCatAll(categoriasFiltradas);
    }
    history.push("/home/categorias/");
    setCategoria({
      name: "",
      description: "",
      id: null,
      status: true,
    });
  };
  const _editarCategoria = (values) => {
    setCategoria(values);

    props.history.push("/home/categorias/" + values.id);
  };

  const _openAlert = (id) => {
    setCategoria({ ...categoria, id });
    toogleAlert(true);
  };

  const _cancelEdit = () => {
    history.push("/home/categorias/");
    setCategoria({
      name: "",
      description: "",
      id: null,
      status: true,
    });
  };

  return (
    <>
      <Ubicacionbar modulo="Categorias" />
      <SnackBar
        textMensaje={snackbar.message}
        open={snackbar.open}
        tipoAlert={snackbar.type}
        close={() => setSnackBar({ ...snackbar, open: false })}
      ></SnackBar>
      <div className={classes.section}>
        <Paper className={classes.paper} elevation={4} style={{ padding: 10 }}>
          <Grid
            container
            className={classes.containerDIv}
            spacing={2}
            direction="column"
            justify="space-around"
          >
            <div className={classes.containerDIv} style={Alignbutton}>
              <Link to="/home/categorias/new" className="list-item">
                <Button
                  id="button_new_carta"
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.buttonNew}
                  onClick={() =>
                    setCategoria({
                      name: "",
                      description: "",
                      id: null,
                      status: true,
                    })
                  }
                >
                  <Add />
                  Nueva Categoria
                </Button>
              </Link>
            </div>

            {!loading && categoriasAll?.length ? (
              <SearchInput value={inputSearch} onChange={_handlefilter} />
            ) : (
              ""
            )}
          </Grid>
          {categoriasAll?.length > 0 ? (
            <TableCategorias
              categorias={categorias}
              filterArticulos={_handlefilter}
              loading={loading}
              editar={_editarCategoria}
              openAlert={_openAlert}
              editarEstado={_editarEstado}
            />
          ) : (
            <Grid item>
              <MensajeTypografico
                texto={
                  <span>
                    ¡Aqui puedes gestionar tus categorias.{" "}
                    <b>¡Vamos! Crea una!</b>{" "}
                  </span>
                }
              />
            </Grid>
          )}
          <Switch>
            <Route path="/home/categorias/new">
              <ModalCategoriaContainer
                submitCat={_submitCategoria}
                categoria={categoria}
                openModal={true}
                closeModal={_cancelEdit}
              />
            </Route>
            <Route path="/home/categorias/:categoria_id">
              {" "}
              <ModalCategoriaContainer
                submitCat={_submitCategoria}
                categoria={categoria}
                openModal={true}
                closeModal={_cancelEdit}
              />
            </Route>
          </Switch>

          {loading ? (
            <div
              style={{
                padding: " 30px",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={40} />
            </div>
          ) : (
            ""
          )}
        </Paper>
      </div>

      <DialogAlert
        open={open_alert}
        aceptar={_deleteCategoria}
        cerrar_modal={() => toogleAlert(false)}
        titulo="¡Cuidado!"
        texto="Tenga presente que esta accion es irreversible y afectara todas las cartas que contengan esta categoria, ¿Desea eliminarla?"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CatergoriaContainer)
);
