import React from "react";
import Joyride, { STATUS } from "react-joyride";

const joyOverrides = {
  options: {
    zIndex: 1,
  },
};
export default function useTour(props) {
  const { steps, localStorageKey, storageValue = 1, ...rest } = props;
  const [run, setRun] = React.useState(false);
  const handleRunTour = (value) => {
    setRun(value);
  };
  React.useEffect(() => {
    if (!localStorageKey) {
      setRun(true);
      return;
    }
    const tourStorage = window.localStorage.getItem(localStorageKey);

    if (tourStorage === "finished") {
      return;
    }
    window.localStorage.setItem(localStorageKey, storageValue);
    setRun(true);
  }, []);
  const callbackJoy = React.useCallback(
    (data) => {
      const { status } = data;

      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

      if (finishedStatuses.includes(status)) {
        window.localStorage.setItem(localStorageKey, "finished");
      }
    },
    [window]
  );
  const tour = React.useMemo(
    () => (
      <Joyride
        callback={callbackJoy}
        steps={steps}
        run={run}
        scrollToFirstStep={true}
        disableScrolling
        showProgress={true}
        continuous={true}
        hideCloseButton={true}
        showSkipButton={false}
        styles={joyOverrides}
        disableOverlayClose={false}
        {...rest}
      />
    ),
    [steps, callbackJoy, run, handleRunTour]
  );
  return { handleRunTour, tour };
}
