import React from "react";
import { makeStyles } from "@material-ui/core/styles";

//Clases "CSS-SASS"
export const useStyles = makeStyles((theme) => ({
  div_typografia: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },

  span_tipografia: {
    mozOsxFontSmoothing: "grayscale",
    webkitFontSmoothing: "antialiased",
    fontFamily: "Dancing Script, cursive !important",
  },
}));
