import React from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";

import ButtonTerms from "./ButtonTerms";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import {} from "../styles/register.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Copyright from "./Copyright";
import { theme_menu } from "../styles/classes";

//estilos de los elementos internos.

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme_menu.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme_menu.spacing(1),
    backgroundColor: theme_menu.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    height: "100%",
    paddingTop: theme_menu.spacing(5),
  },
  menuButton: {
    marginRight: theme_menu.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  submit: {
    padding: theme_menu.spacing(2, 0, 2),
  },
  menubar: {
    // backgroundColor: theme.palette.primary.main
  },
  logoImage_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

//componente funcional
function SignUp(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",
    email: "",
    confirm_pass: "",
    showPassword: false,
    mensaje: "",
    disabled: false,
    apertura: false,
    mensajeAlert: "",
    tipoMensaje: "success",
    checked_theme: false,
    theme: theme_menu,
    showDark: false,
    name: "",
    lastname: "",
  });

  const handleChange = (prop) => (event) => {
    let value = event.target.value;
    if (prop === "email") {
      value = value.toLowerCase();
    }
    setValues({
      ...values,
      [prop]: value,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const _handleSubmit = () => {
    setValues({ ...values, disabled: true });
    props.submit(values);
  };
  //crear validacion personalizada para comparar los password. se usa ValidatorForm
  ValidatorForm.addValidationRule("isPassMatch", (value) => {
    if (value !== values.password) {
      return false;
    }
    return true;
  });
  // style={{backgroundColor:'#2D9CDB'}}
  return (
    <div>
      <ThemeProvider theme={theme_menu}>
        {props.loading && <LinearProgress id="loading" color="secondary" />}
        <Grid container spacing={0}>
          <Grid
            container
            className="divInfoApp"
            item
            xs={12}
            sm={12}
            md={6}
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Typography variant="h3" className="tituloCursivo" gutterBottom>
              Cree su carta virtual con{" "}
              <b
                style={{
                  fontFamily: "Audiowide",
                  padding: "0 10px",
                  paddingTop: "10px",
                  color: "#2D9CDB",
                }}
              >
                {" "}
                CATALOGY
              </b>{" "}
            </Typography>
            <Typography
              variant="h5"
              className="subtituloCursivo"
              component="h5"
              style={{ color: "#686868" }}
              gutterBottom
            >
              Intuitiva, rápida y desde cualquier lugar.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Grid
                  container
                  justify="center"
                  align="center"
                  className={classes.logoImage_container}
                >
                  <Typography variant="h2" className="tituloCursivo">
                    <img
                      src="/logo_completo_white.png"
                      alt="no"
                      style={{ height: "150px" }}
                    ></img>
                  </Typography>
                </Grid>

                <div className={classes.form} noValidate>
                  <ValidatorForm
                    onSubmit={_handleSubmit}
                    onError={(errors) => console.log(errors)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth={true}>
                          <TextField
                            variant="outlined"
                            autoComplete="fname"
                            name="firstName"
                            required
                            fullWidth
                            id="firstName"
                            label="Nombre"
                            autoFocus
                            onChange={handleChange("name")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="lastName"
                          label="Apellido"
                          name="lastName"
                          autoComplete="lname"
                          onChange={handleChange("lastname")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          label="Correo Electronico"
                          name="email"
                          autoComplete="email"
                          onChange={handleChange("email")}
                          value={values.email}
                          validators={["required", "isEmail"]}
                          errorMessages={[
                            "Este campo es requerido",
                            "Correo no es valido",
                          ]}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth={true}>
                          <InputLabel
                            style={{
                              backgroundColor: "white",
                              padding: "0px 5px",
                            }}
                            htmlFor="password"
                          >
                            Contraseña
                          </InputLabel>
                          <OutlinedInput
                            fullWidth={true}
                            variant="outlined"
                            required
                            name="password"
                            id="password"
                            autoComplete="current-password"
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          type={values.showPassword ? "text" : "password"}
                          required
                          fullWidth
                          id="pass_confirm"
                          label="Confirmar Contraseña"
                          name="pass_confirm"
                          onChange={handleChange("confirm_pass")}
                          validators={["isPassMatch", "required"]}
                          errorMessages={[
                            "contraseñas no coinciden",
                            "Campo requerido",
                          ]}
                          value={values.confirm_pass}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ButtonTerms />
                      </Grid>
                    </Grid>
                    <Button
                      id="button_submit"
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={props.loading}
                      loading={props.loading}
                    >
                      Registrarme
                    </Button>

                    <Grid container justify="flex-end">
                      <Grid item>
                        <Link href="/login" variant="body2">
                          ¿Ya tienes cuenta?
                        </Link>
                      </Grid>
                    </Grid>
                  </ValidatorForm>
                </div>
              </div>

              <Box mt={5}>
                <Copyright />
              </Box>
            </Container>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default SignUp;
