import React from "react";
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {},
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 18,
    color: theme.palette.primary.label,
    fontWeight: "bold",
  },
  pos: {
    marginBottom: 12,
    fontSize: 13,
    color: "grey",
    fontWeight: "bold",
  },
  number: {
    paddingLeft: "80px",
    paddingBottom: "10px",
    fontSize: "2rem",
    fontVariant: "full-width",
    fontWeight: "bold",
    color:theme.palette.primary.main,
  },
  cards: {
    backgroundColor: theme.palette.primary.backgroundCard,
    borderRadius: "30px",
    minWidth: "300px ",
    maxWidth: "380px",
    height: "140px",
    margin: "2px",
    marginLeft: "1px",
    // -webkit-box-shadow: '8px 9px 8px 1px rgba(115,115,115,0.51)' ,
    // -moz-box-shadow: '8px 9px 8px 1px rgba(115,115,115,0.51)' ,
    boxShadow: "3px 8px 5px 1px rgba(24, 15, 15, 0.3) ",
    color: "black",
    cursor: "pointer",
  },
  botonAdd: {
    "&:hover": {
      backgroundColor: " #085f7c !important",
    },

    "&:disabled": {
      
      backgroundColor: "transparent"
      
    },
  },
}));
