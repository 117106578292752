import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles";

export const LoadingPage = ({ spineSize = "40px" }) => {
  const classes = useStyles();
  return (
    <div
      classes={classes.container_loading_background}
      style={{
        backgroundColor: "#333333b5",
        height: "100%",
        width: "100%",
        left: "0",
        top: "0",
        position: "absolute",
        zIndex: 2,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={classes.container_spiner}
      >
        <CircularProgress size={spineSize} />
      </div>
    </div>
  );
};
