import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import mApi from "../services/api";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import { red } from "@material-ui/core/colors";

import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CardArticulo from "./CardArticulo";
import Checkbox from "@material-ui/core/Checkbox";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import ModalArticuloContainer from "../containers/ModalArticuloContainer";
import { MensajeTypografico } from "./MensajeTypografico";
import DialogAlert from "./DialogAlert";

import { errorMessageHandler } from "../helpers/messageHandler";
import { ReactSortable } from "react-sortablejs";

//Clases "CSS-SASS"
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  buttonAddArticulo: {
    padding: 0,
  },
  header: {
    padding: 10,
    color: "lightgrey",
  },
  botonAdd: { marginTop: 2 },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: 10,
  },
  ul: {
    listStyle: "outside none none",
    minHeight: " 100px",
    background: "#e4f8ffb3",

    maxHeight: "400px",
    overflowY: "scroll",
    margin: "2px",
    borderRadius: "7px",

    border: "2px solid rgba(0,0,0,0.1)",
    " ::-webkit-scrollbar": {
      display: " none;",
    },
  },
  tituloDialog: {
    background: theme.palette.primary.main,
    color: "white",
  },
  CardHeader: {
    height: "60px",
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },
  disable: {
    display: "none",
  },
}));

export default function CardCategoria(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(props.expanded);

  /* Extraer campos del state de la app  */
  const token = useSelector((store) => store.token);
  const user = useSelector((store) => store.user);
  const serializedData = user;
  let negocios = JSON.parse(serializedData).negocios;

  let negocio_id = 0;
  if (typeof negocios[0].id != "undefined") {
    let Negocio = negocios[0];

    negocio_id = Negocio.id;
  }
  /*++++++++++++++++++++++++++++++++++  */
  const [articulosArrastrados, setArtArr] = React.useState([]);
  const [articulos, setArticulos] = React.useState(props.articulos || []);

  const [values, setValues] = React.useState({
    selected: props.estado,
    articuloSeleccionado: {
      name: "",
      description: "",
      precio: "",
      image: "",
      id: null,
      status: false,
      discount: "",
    },
    creandonuevo: true,
    alertOpen: false,
    textMensaje: "¡Negocio Actualizado!",
    tipoAlert: "success",
    openModal: false,
    cargando: false,
  });
  const [open_alert, setOpen] = React.useState(false);
  const [idArticulo, setIdArticulo] = React.useState("");
  const [showMessageNew, setShowMessage] = React.useState(true);
  const [cantArticulos, setCantArticulos] = React.useState(0);

  const refList = React.useRef();

  useEffect(() => {
    if (articulos.length === 0 && articulosArrastrados?.length === 0) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
    setCantArticulos(articulos.length);
  });
  useEffect(() => {
    if (props.articulos && props.articulos?.length > 0) {
      setArticulos(props.articulos);
    } else {
      if (props.isNew && props.articulos?.length > 0) setExpanded(false);
      if (!props.seleccionable || (props.estado && articulos?.length === 0))
        setExpanded(true);
      if (!articulosArrastrados.length) {
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!articulosArrastrados.length && !articulos?.length) {
      setShowMessage(true);
    }
  }, [articulosArrastrados.length, articulos.length]);

  useEffect(() => {
    if (props.articulos && props.articulos?.length > 0) {
      setArticulos(props.articulos);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.articulos]);

  const handleExpandClick = () => {
    if (values.selected) {
      setExpanded(!expanded);
    }
  };
  const handleChange = (event) => {
    setValues({ ...values, selected: event.target.checked });
    if (values.selected) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  function cancelarArticulo() {
    setValues({
      ...values,
      openModal: false,
      articuloSeleccionado: {
        name: "",
        description: "",
        precio: "",
        image: "",
        id: null,
        status: false,
        dicount: "",
      },
    });
  }

  const handleClickOpen = () => {
    cancelarArticulo();
    setValues({
      ...values,
      crearArticulo: true,
      articuloSeleccionado: {
        status: true,
        name: "",
        description: "",
        precio: "",
        image: "",
        id: null,
        discount: "",
      },
      openModal: true,
      articuloFilter: "",
    });
  };

  const agregarArticulo = (data) => {
    let articulosnuevos = articulos;
    articulosnuevos.unshift(data);
    setValues({
      ...values,

      articuloSeleccionado: {
        status: true,
        name: "",
        description: "",
        precio: "",
        image: "",
        id: null,
        discount: "",
      },
    });
    setArticulos(articulosnuevos);
    setCantArticulos(articulos.length + articulosArrastrados.length);
  };
  const _validarDelete = (id) => {
    setIdArticulo(id);
    setOpen(true);
  };
  const aceptar_delete = () => {
    setOpen(false);
    eliminarArt(idArticulo);
  };
  const cerrar_modal = () => {
    setOpen(false);
    setIdArticulo("");
  };

  const eliminarArt = (id) => {
    const listaMadre = refList.current.getElementsByTagName("li");
    let findElement = false;
    for (let index = 0; index < listaMadre.length; index++) {
      const element = listaMadre[index];

      if (element.id === id) findElement = true;
    }
    if (!findElement) {
      alert(
        `No puede eliminar este articulo desde esta categoria, devuelvalo a ${props.titulo} e intente de nuevo. `
      );
      return;
    }

    let method = "delete";
    let url = `negocios/${negocio_id}/articulos/${id}`;

    var config = {
      method: method,
      url: url,
      withCredentials: false,
      headers: {
        "x-access-tokens": token,
        Accept: "*/*",
      },
    };
    mApi(config)
      .then((response) => {
        if (response.status === 200) {
          const listaArticulos = articulos.filter((item) => item.id !== id);
          setArticulos(listaArticulos);
        }
      })
      .catch((e) => {
        console.error(e);
        alert(errorMessageHandler(e));
      });
  };

  const RenderHeader = ({ cantArticulos }) => {
    return props.seleccionable ? (
      <CardHeader
        style={{ width: "95%", paddingLeft: 5 }}
        avatar={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              className="checkboxCategory"
              style={{ padding: 0, marginRigth: "20px" }}
              checked={values.selected}
              onChange={handleChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        }
        className={values.selected ? classes.selected : classes.header}
        action={
          <div style={{ height: "100%", paddingTop: 5, paddingRight: 5 }}>
            <Fab
              color="secondary"
              className={clsx(classes.botonAdd, "addArticulo")}
              onClick={handleClickOpen}
              disabled={!values.selected}
              size="small"
              id="addArticulo"
            >
              <AddIcon />
            </Fab>
            <ModalArticuloContainer
              selected={values.selected}
              handleClickOpen={handleClickOpen}
              open={values.openModal}
              guardarArticulo={agregarArticulo}
              cancelarArticulo={cancelarArticulo}
              articulo={values.articuloSeleccionado}
            ></ModalArticuloContainer>
          </div>
        }
        title={
          <div
            onClick={handleExpandClick}
            className="iconDrop"
            style={{ cursor: "move" }}
          >
            {props.titulo} ({cantArticulos})
          </div>
        }
        subheader={props.precio}
      />
    ) : (
      <CardHeader
        className={classes.CardHeader}
        title={
          <h5 style={{ color: "white" }}>
            Articulos Disponibles (
            {searchText ? cantidadArticulosFilter : cantArticulos})
          </h5>
        }
        action={
          <div
            style={{
              color: "white",
              paddingBottom: "15px",
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Fab
              className={clsx(classes.botonAdd, "addArticulo")}
              color="secondary"
              onClick={handleClickOpen}
              disabled={!values.selected}
              size="small"
            >
              <AddIcon />
            </Fab>
            <ModalArticuloContainer
              selected={values.selected}
              handleClickOpen={handleClickOpen}
              open={values.openModal}
              guardarArticulo={agregarArticulo}
              cancelarArticulo={cancelarArticulo}
              articulo={values.articuloSeleccionado}
            ></ModalArticuloContainer>
          </div>
        }
      ></CardHeader>
    );
  };
  const [searchText, setSearchText] = React.useState("");

  const filtrarListaDisponibles = (semilla) => {
    const lista = document.getElementById("lista_articulos_" + props.titulo);
    if (!lista) return 0;
    let articulosDisp = lista?.getElementsByTagName("li");
    let listActivos = 0;
    for (let item of articulosDisp) {
      let title = item.getAttribute("titulo").toUpperCase();
      if (!title.includes(semilla.toUpperCase())) item.style.display = "none";
      else {
        item.style.display = "block";

        listActivos += 1;
      }
    }
    return listActivos;
  };

  const _handlefilter = (e) => {
    let semilla = e.target.value;
    // setValues({ ...values, articuloFilter: semilla });
    setSearchText(semilla);
  };
  const cantidadArticulosFilter = useMemo(
    () => filtrarListaDisponibles(searchText),
    [searchText]
  );
  return (
    <article>
      <Card>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {props.seleccionable && (
            <div
              className="iconDrop"
              style={{
                cursor: "move",

                height: "50px",
                width: "5%",
                display: "flex",
                alignItems: "center",
                marginRight: 4,
              }}
            >
              <DragIndicatorIcon />
            </div>
          )}
          <RenderHeader cantArticulos={cantArticulos} />
        </div>
        <DialogAlert
          open={open_alert}
          aceptar={aceptar_delete}
          cerrar_modal={cerrar_modal}
          titulo="¡Cuidado!"
          texto="Tenga presente que esta accion es irreversible y afectara las demas cartas que lo contengan ¿Desea eliminar permanentemente el articulo?"
        />

        <Collapse in={expanded} timeout="auto">
          <CardContent>
            {!props.seleccionable && (
              <div
                style={{
                  paddingBottom: "15px",
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                  // boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)",
                }}
              >
                <TextField
                  size="small"
                  color="primary"
                  placeholder="Filtrar"
                  // label={<span style={{color:"white"}}>Filtrar</span>}
                  type="text"
                  // value={values.articuloFilter}
                  onChange={_handlefilter}
                  name="articuloFilter"
                  style={{
                    width: "70%",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search color="primary" style={{ color: "grey" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}

            <ReactSortable
              onAdd={(e) => {
                console.log("ADD", e);
              }}
              onRemove={(e) => {
                console.log("remove", e);
              }}
              list={articulos}
              setList={setArticulos}
              group={{
                name: "lista_articulos",
                pull: props.alwaysItemsAvailables ? "clone" : undefined,
              }}
              animation={300}
              delayOnTouchStart={true}
              handle=".iconDrop"
              direction="vertical"
              className={classes.ul}
              id={"lista_articulos_" + props.titulo}
              fallbackOnBody={true}
              swapThreshold={0.65}
            >
              {!showMessageNew ? (
                articulos.map((value, index) => (
                  <li
                    key={value.id + value.name + index}
                    className={"dragItem_articulos"}
                    titulo={value.name}
                    url_image={value.url_image}
                    descripcion={value.description}
                    precio={value.precio}
                    id={value.id}
                  >
                    <CardArticulo
                      id={value.id}
                      name={value.name}
                      url_image={value.url_image}
                      description={value.description}
                      precio={value.precio}
                      status={value.status}
                      discount={value.discount}
                      deleteArticle={_validarDelete}
                      symbol={props.symbol}
                    />
                  </li>
                ))
              ) : (
                <div>
                  <MensajeTypografico
                    fontSize="1rem"
                    texto={
                      <span>
                        Arrastre o Cree un nuevo <b>Articulo</b>
                      </span>
                    }
                  />
                </div>
              )}
            </ReactSortable>
          </CardContent>
        </Collapse>
      </Card>
    </article>
  );
}
