import React, { useEffect } from "react";

export const useScreenSize = (props) => {
  const breakPoint = props?.breakPoint ?? 500;
  const [isDownBreakPoint, setIsDownBreakPoint] = React.useState(false);

  useEffect(() => {
    document.body.clientWidth < breakPoint
      ? setIsDownBreakPoint(true)
      : setIsDownBreakPoint(false);
    window.addEventListener("resize", () => {
      document.body.clientWidth < breakPoint
        ? setIsDownBreakPoint(true)
        : setIsDownBreakPoint(false);
    });
    return () => {
      window.removeEventListener("resize", () => {
        document.body.clientWidth < breakPoint
          ? setIsDownBreakPoint(true)
          : setIsDownBreakPoint(false);
      });
    };
  }, [breakPoint]);

  return { isDownBreakPoint, clientWidth: document.body.clientWidth };
};
