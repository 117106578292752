import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "../services/env";
const env = process.env.REACT_APP_ENV || "local";

export const initSentry = () => {
  //initial config to start sentry logging
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    environment: env,
    release: process.env.REACT_APP_RELEASE,
    normalizeDepth: 10,
    autoSessionTracking: true,
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb.category === "console" ? null : breadcrumb; //this is for ignore the console logs in the messages
    },
  });
  //create log for the state and the info for the user
  return Sentry.createReduxEnhancer({
    stateTransformer: (state) => {
      return state;
    },
    configureScopeWithState: (scope, state) => {
      const user = JSON.parse(state.user);

      user && scope.setUser({ ...user });
    },
  });
};
