import React, { useState } from "react";
import { useHistory } from "react-router";
import { ModalGenerico } from "../components/ModalGenerico";
import { FormChangePass } from "../components/FormChangePass";
import { clearToken, setUser, clearUser } from "../initializers/actions";
import { connect } from "react-redux";
import mApi from "../services/api";
import { errorMessageHandler } from "../helpers/messageHandler";

const CambioPassContainer = (props) => {
  const [values, setValues] = useState({
    textMensaje: "",
    cargando: false,
    openMensaje: false,
    tipoAlert: "success",
  });
  let history = useHistory();

  const handleClose = () => {
    props.closeModal();
  };
  const cerrarSession = () => {
    props.clearToken();
    this.props.clearUser();
    history.push("/login");
  };
  const submit = ({ password, newpassword }) => {
    const url = `change_password`;

    let objData = {
      current_password: password,
      new_password: newpassword,
    };

    let config = {
      method: "post",
      url: url,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": props.token,
        Accept: "*/*",
      },
      data: objData,
    };
    mApi(config)
      .then((response) => {
        let mensaje = response.data.message;
        if (response.status === 200) {
          setValues({
            ...values,
            openMensaje: true,
            tipoAlert: "success",
            textMensaje: mensaje,
            cargando: false,
          });

          cerrarSession();
        } else {
          setValues({
            ...values,
            openMensaje: true,
            tipoAlert: "error",
            textMensaje: mensaje,
            cargando: false,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setValues({
          ...values,
          openMensaje: true,
          tipoAlert: "error",
          textMensaje: errorMessageHandler(e),
          cargando: false,
        });
      });
  };
  return (
    <section>
      <ModalGenerico
        openModal={props.openModal}
        title="Cambiar Contraseña"
        closeModal={handleClose}
      >
        <FormChangePass
          cargando={values.cargando}
          submit={submit}
          closeModal={handleClose}
          textMensaje={values.textMensaje}
          open={values.openMensaje}
          tipoAlert={values.tipoAlert}
          close={() => setValues({ ...values, openMensaje: false })}
        ></FormChangePass>
      </ModalGenerico>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  };
};

const mapDispatchToProps = {
  clearToken: clearToken,
  setUser: setUser,
  clearUser: clearUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CambioPassContainer);
