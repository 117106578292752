import { makeStyles } from "@material-ui/core/styles";
import { theme_menu } from "../../styles/classes";

export const useStyles = makeStyles((theme) => ({
  texbox: {
    width: "100%",
    paddingBlock: 10,
  },
  form: {
    maxWidth: "400px",
  },
  buttonBig: {
    padding: theme_menu.spacing(2, 2, 2),
    color: "white",

    maxWidth: 250,
  },
  buttonBigSecondary: {
    padding: theme_menu.spacing(2, 2, 2),

    maxWidth: 250,
  },
  containerButtons: {
    padding: theme_menu.spacing(1, 1),
  },
}));
