import React from "react";

import { ValidatorComponent } from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import { FormHelperText } from "@material-ui/core";
class PasswordInput extends ValidatorComponent {
  //   constructor(props) {
  //     super(props);
  //     // this.state = {
  //     //   showPassword: false,

  //     // };
  //   }
  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText error variant="outlined">
        {this.getErrorMessage()}
      </FormHelperText>
    );
  }

  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      value,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    const handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
      <>
        <FormControl variant="outlined" fullWidth={true} error={!isValid}>
          <InputLabel
            style={{
              backgroundColor: "white",
              padding: "0px 5px",
            }}
            htmlFor="password"
          >
            Contraseña
          </InputLabel>
          <OutlinedInput
            {...rest}
            type={this.state.showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>{" "}
        {this.errorText()}
      </>
    );
  }
}

export default PasswordInput;
